import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@spoiler-alert/ui-library';
import { Route, Redirect } from 'react-router-dom';
import { Layout, InternalLayout } from './layouts';
import { SupportService } from './services';
import routePaths from './route-paths';

const UnAuthedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    )}
  />
);

UnAuthedRoute.propTypes = {
  component: PropTypes.any,
};

const NoTemplateRoute = ({ component: Component, ...rest }) => <Route {...rest} render={(props) => <Component {...props} />} />;

NoTemplateRoute.propTypes = {
  component: PropTypes.any,
};

const SupportRoute = ({ ...rest }) => (
  <Route
    {...rest}
    render={async () => {
      if (localStorage.getItem('token')) {
        SupportService.supportSSOUrl()
          .then((response) => response.json())
          .then((data) => {
            window.location = data.url;
          })
          .catch((err) => AlertService.error(err));

        return;
      }
      window.location = `/sign-in`;
    }}
  />
);

SupportRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
};

class AuthedRoute extends React.Component {
  render() {
    const { component, guard, redirectPath, user, ...rest } = this.props;
    const Component = component;
    return (
      <Route
        {...rest}
        render={(props) => {
          const signInPage = localStorage.getItem('isSSOUser') === 'yes' ? routePaths.ssoSignIn : routePaths.signIn;
          if (!localStorage.getItem('token')) return <Redirect to={`${signInPage}?redirect=${props.location.pathname}`} />;
          const guardCheck = guard(user);
          if (!guardCheck.isValid) return <Redirect to={redirectPath || guardCheck.redirect || routePaths.home} />;
          return (
            <Suspense fallback={<Loading loading={true} />}>
              <InternalLayout user={user} {...props} {...rest}>
                <Component user={user} {...props} {...rest} />
              </InternalLayout>
            </Suspense>
          );
        }}
      />
    );
  }
}

const RedirectRoute = ({ ...rest }) => <Redirect to={rest.redirectPath} />;

AuthedRoute.propTypes = {
  user: PropTypes.object,
  component: PropTypes.any,
  guard: PropTypes.func,
  redirectPath: PropTypes.string,
  isOn: PropTypes.any,
  isOff: PropTypes.any,
  featureFlag: PropTypes.string,
};

AuthedRoute.defaultProps = {
  guard: () => ({ isValid: true }),
};

export { UnAuthedRoute, AuthedRoute, SupportRoute, RedirectRoute, NoTemplateRoute };
