import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { DataTableNaked, Search, Timing, Button, ExportIcon, RowAction } from '@spoiler-alert/ui-library';
import { useQuery } from '@apollo/client';
import { TitleService } from '../../services';
import { GetBuyerProfilesQuery } from '../../graphql/queries';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import { Breadcrumbs } from '../../store';
import DownloadSheetService from '../../services/download-sheet-service';

const useStyles = createUseStyles({
  tableWrap: {
    border: '1px #D1D6DA solid',
    borderRadius: 2,
    marginTop: 1,
    marginBottom: '25px',
  },
  search: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '17px',
    width: 400,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const CustomerProfiles = ({ user, history }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [exportingRows, setExportingRows] = useState([]);
  const [exportingAll, setExportingAll] = useState(false);
  const { loading, data } = useQuery(GetBuyerProfilesQuery);

  useEffect(() => {
    TitleService.setTitles('Customer profiles');
    Breadcrumbs.set([
      {
        url: '/customers',
        title: 'Customer profiles',
      },
    ]);
  }, []);

  const handleRowClick = (row) => {
    history.push(`/customers/${row.buyerName}`);
  };

  const handleSearch = Timing.debounce((text) => setSearchText(text), 300);

  const handleExportAll = async () => {
    setExportingAll(true);
    await DownloadSheetService.downloadCustomerProfilesSheet(
      data?.buyerProfiles.map((buyerProfile) => buyerProfile._id),
      user.site.siteName
    );
    setExportingAll(false);
  };

  const handleExport = async (buyerSiteId) => {
    setExportingRows(buyerSiteId);
    await DownloadSheetService.downloadCustomerProfilesSheet(buyerSiteId, user.site.siteName);
    setExportingRows([]);
  };

  return (
    <div>
      <div className={classes.header}>
        <Search onChange={handleSearch} className={classes.search} placeholder="Search by customer" />
        <Button onClick={handleExportAll} loading={exportingAll} disabled={exportingRows.length || exportingAll} loadingText="Exporting">
          Export All Profiles (xlsx)
        </Button>
      </div>
      <div className={classes.tableWrap}>
        <DataTableNaked
          data={data?.buyerProfiles || []}
          loading={loading}
          noDataMessage={loading ? 'Loading...' : 'No customer profiles matched your search.'}
          userId={user._id}
          transition
          searchText={searchText}
          onRowClick={handleRowClick}
          columns={getColumnsFromDataTableProfile('Buyer Profiles', user.site.dataTableProfiles)}
          sticky
          rowActions={[
            <RowAction
              key={1}
              tooltipText="Export Profile"
              icon={ExportIcon}
              onClick={(row) => handleExport([row._id])}
              secondary
              loadingRows={exportingRows}
              disabled={exportingRows.length || exportingAll}
            />,
          ]}
        />
      </div>
    </div>
  );
};

CustomerProfiles.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
};

export default CustomerProfiles;
