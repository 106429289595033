import React from 'react';
import { Modal, ModalContent, ModalFooter, Button, AlertWarningIcon } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import postAwardUpdatesModalStyles from './post-award-updates-modal-styles';

const useStyles = createUseStyles(postAwardUpdatesModalStyles);

const PostAwardUpdatesModal = ({ cancel, displayAwardedOffersModal, navigateToOfferCompScreen, modalInnerText }) => {
  const classes = useStyles();
  return (
    <Modal open={displayAwardedOffersModal} closeOnEsc closeOnOutsideClick onHide={cancel}>
      <ModalContent>
        <div data-testid="modal-body">
          <h3 className={classes.header}>Awarded offer updates</h3>
          <div className={classes.warningContent}>
            <div>
              <AlertWarningIcon className={classes.infoIcon} />
            </div>
            <div className={classes.warningText}>{`${modalInnerText}`}</div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button secondary onClick={cancel}>
          Cancel
        </Button>
        <Button primary onClick={navigateToOfferCompScreen}>
          Review Updated Offers
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PostAwardUpdatesModal.propTypes = {
  cancel: PropTypes.func,
  displayAwardedOffersModal: PropTypes.func,
  navigateToOfferCompScreen: PropTypes.func,
  modalInnerText: PropTypes.string,
};

export default PostAwardUpdatesModal;
