import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import pluralize from 'pluralize';
import { createUseStyles } from 'react-jss';
import {
  DataTableNaked,
  AlertService,
  RowAction,
  ClearListIcon,
  PublishListIcon,
  Timing,
  Search,
  Button,
  FilterIcon,
  OverlayService,
  Flyout,
  Select,
  SelectOption,
  publishHistoryStatus,
  AlertWarningYellowIcon,
  MiniTooltip,
  Column,
  SpoilerAlertAppleIcon,
} from '@spoiler-alert/ui-library';
import dataExportService from '../../services/data-export-service';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import { featureFlags } from '../../enums';
import destinationType from '../../enums/staged-inventory-destination-type';
import { ArchiveStatus } from './archive-status-enum';
import PublishListModal from './publish-list-modal';
import checkFeature from '../../helpers/check-feature-flag';

const useStyles = createUseStyles({
  table_wrap: {
    border: '1px #D1D6DA solid',
    borderRadius: 2,
    marginTop: 1,
  },
  stagingGridHeader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '20px',
  },
  stagingGridSearchInput: {
    width: 386,
    marginRight: 8,
  },
  toggleWrap: {
    marginRight: 24,
  },
  filterButton: {
    marginRight: 24,
  },
  filtersHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  clearFilters: {
    cursor: 'pointer',
  },
  listNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
  },
  spoilerAlertApple: {
    width: 16,
    height: 18,
  },
});

const GenericStaging = ({
  user,
  history,
  dataQuery,
  progressBarsQuery,
  pollQuery,
  refetchQueries,
  dataTableProfileName,
  publishEndpoint,
  dataGetter,
  pollDataGetter,
  clearListingsMutation,
  clearListingsResponseGetter,
  detailPagePath,
}) => {
  const classes = useStyles();
  const [actionOnRows, setActionOnRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);
  const [showPublishListModal, setShowPublishListModal] = useState(false);
  const [row, setRow] = useState();
  const actionRowsRef = useRef();

  const { data, loading } = useQuery(dataQuery, {});
  const pollingCatalogIds =
    dataGetter(data)
      .filter((x) => x.status === publishHistoryStatus.created || x.status === publishHistoryStatus.running)
      .map((y) => y.catalogId) || [];
  const {
    data: pollResults,
    startPolling,
    stopPolling,
  } = useQuery(pollQuery, {
    variables: {
      catalogIds: pollingCatalogIds,
    },
    skip: pollingCatalogIds.length === 0,
  });
  const { refetch: refetchProgressBars } = useQuery(progressBarsQuery);
  const [clearListings] = useMutation(clearListingsMutation);

  useEffect(() => {
    actionRowsRef.current = actionOnRows;
  }, [actionOnRows]);

  useEffect(() => {
    const summary = pollDataGetter(pollResults);
    if (summary?.length > 0) {
      const rowIds = summary.map((x) => x._id);
      const newRows = [...actionRowsRef.current];
      const filteredRows = newRows.filter((x) => !rowIds.includes(x.rowId));
      setActionOnRows(filteredRows);
    }
  }, [pollResults]);

  useEffect(() => {
    if (pollingCatalogIds.length > 0) {
      startPolling(5000);
    }
    return stopPolling;
  }, [pollingCatalogIds]);

  const filteredData = useMemo(() => {
    let fData = dataGetter(data);
    if (statusFilter.length > 0) {
      fData = fData.filter((x) => statusFilter.some((filterStatus) => filterStatus.value === x.status));
    }
    return fData;
  }, [statusFilter, data]);

  const handleSearch = Timing.debounce((text) => setSearchText(text), 300);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
    if (showFilters) {
      OverlayService.hide();
    } else {
      OverlayService.show();
    }
  };

  const publishStatus = {
    completed: { text: publishHistoryStatus.publishedCapitalized, status: publishHistoryStatus.completed },
    inProgress: { text: publishHistoryStatus.inProgressCapitalized, status: publishHistoryStatus.running },
    created: { text: publishHistoryStatus.unpublishedCapitalized, status: publishHistoryStatus.created },
  };

  const handleFilterChange = () => {
    setStatusFilter([...statusFilter]);
  };

  const statusFilterOptions = () => {
    return [
      <Select key="status" multiple label="Status" onChange={handleFilterChange} selectedItems={statusFilter}>
        <SelectOption key={publishStatus.created.status} checkbox value={publishStatus.created.status}>
          {publishStatus.created.text}
        </SelectOption>
        <SelectOption key={publishStatus.inProgress.status} checkbox value={publishStatus.inProgress.status}>
          {publishStatus.inProgress.text}
        </SelectOption>
        <SelectOption key={publishStatus.completed.status} checkbox value={publishStatus.completed.status}>
          {publishStatus.completed.text}
        </SelectOption>
      </Select>,
    ];
  };

  const addToActionOnRowIds = (rowId, action) => {
    const newRows = [...actionRowsRef.current];
    newRows.push({ rowId, action });
    setActionOnRows(newRows);
  };

  const rowIsInAction = (rowId) => {
    const newRows = [...actionRowsRef.current];
    const idx = newRows.findIndex((o) => o.rowId === rowId);
    return idx !== -1;
  };

  const showMessage = (message) => {
    AlertService.alert({
      type: 'info',
      message: <span>{message}</span>,
    });
  };

  const showFailure = (message) => {
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  };

  const showSuccess = (message) => {
    AlertService.alert({
      type: 'success',
      message: <span>{message}</span>,
      autoDismiss: true,
      dismissDelay: 3000,
    });
  };

  const publish = async (clickedRow, publishMessage, dueDate) => {
    const params = { distributionChannelId: clickedRow.distributionChannelId, catalogId: clickedRow.catalogId, publishMessage, dueDate };

    const inProgressMessage = () =>
      showMessage(
        `We're still publishing the ${clickedRow.channel.name} list. When publishing is complete, you'll receive the offer sheet in your inbox. This could take several minutes.`,
        clickedRow._id
      );
    const timeoutMessage = setTimeout(inProgressMessage, 30000);
    try {
      const response = await dataExportService.export(publishEndpoint, params);
      const body = await response.json();
      setShowPublishListModal(false);
      clearTimeout(timeoutMessage);
      refetchProgressBars();
      if (body.errors.length > 0) {
        if (body.exportStatus === 'BLOCKED' && rowIsInAction(clickedRow._id)) {
          showMessage(
            <span>
              The {row.channel.name} list is still publishing. When publishing is complete, you&rsquo;ll receive the offer sheet in your inbox. This
              could take several minutes.
              <br></br>
              Please wait until publishing is complete to try and publish again.
            </span>,
            clickedRow._id
          );
        } else {
          throw new Error(body.errors[0]);
        }
      } else if (rowIsInAction(clickedRow._id)) {
        showMessage(
          `We've started publishing the ${clickedRow.channel.name} list. When publishing is complete, you'll receive the offer sheet in your inbox. This could take several minutes.`,
          clickedRow._id
        );
      }
    } catch {
      setShowPublishListModal(false);
      clearTimeout(timeoutMessage);
      showFailure(`A problem occurred while publishing listings for ${clickedRow.channel.name}.`, clickedRow._id);
      const newRows = [...actionRowsRef.current];
      const filteredRows = newRows.filter((x) => x.rowId !== clickedRow._id);
      setActionOnRows(filteredRows);
    }
  };

  const handlePublishOfferDueDateEnabled = async (e, publishMessage, dueDate) => {
    e.preventDefault();
    addToActionOnRowIds(row._id, 'inProgress');
    await publish(row, publishMessage, dueDate);
  };

  const handlePublishOfferDueDateDisabled = async (clickedRow, e) => {
    e.stopPropagation();
    addToActionOnRowIds(clickedRow._id, 'inProgress');
    await publish(clickedRow);
  };

  const handleClearAll = (clickedRow, e) => {
    e.stopPropagation();
    addToActionOnRowIds(clickedRow._id, 'reset');
    clearListings({
      variables: { distributionChannelId: clickedRow.distributionChannelId, catalogId: clickedRow.catalogId },
      refetchQueries,
    })
      .then((response) => {
        const result = clearListingsResponseGetter(response);
        if (result.errors.length > 0) throw new Error(result.errors.map((err) => err.message).join(', '));
        if (result.listingCount === 0 && result.errors.length === 0) {
          showSuccess(`You have cleared all listings from ${clickedRow.channel.name}.`, clickedRow._id);
        } else {
          showSuccess(
            `You have cleared ${result.listingCount} ${pluralize('listing', result.listingCount)} from ${clickedRow.channel.name}.`,
            clickedRow._id
          );
        }
      })
      .catch(() =>
        showFailure(
          'We were unable to clear the listings due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.',
          clickedRow._id
        )
      );
  };

  const handleRowClick = (clickedRow) => {
    if (rowIsInAction(clickedRow._id) && clickedRow.status === publishHistoryStatus.created) return;
    history.push(`${detailPagePath}/${clickedRow.catalogId}`);
  };

  const clearFilters = () => {
    setStatusFilter([]);
  };

  const rowActions = (rows) => {
    const resettingRows = [];
    const publishingRows = [];
    const disabledPublishRows = [];
    const disabledClearRows = [];
    const offerDueDateEnabled = checkFeature(featureFlags.offerDueDate);
    rows.forEach((r) => {
      if (r.status && r.status !== publishHistoryStatus.created) {
        disabledPublishRows.push(r._id);
        disabledClearRows.push(r._id);
      }
      if (r?.archiveStatus === 'ALL_ARCHIVED' && !disabledPublishRows.find((currentRow) => currentRow._id === r._id)) disabledPublishRows.push(r._id);
    });
    actionOnRows.forEach((r) => {
      if (r.action === 'reset') {
        resettingRows.push(r.rowId);
      } else if (r.action === 'inProgress') {
        publishingRows.push(r.rowId);
      }
      disabledPublishRows.push(r._id);
      disabledClearRows.push(r._id);
    });
    return [
      <RowAction
        key={1}
        tooltipText="Clear List"
        loadingTooltipText="Clearing List"
        icon={ClearListIcon}
        onClick={(clickedRow) => handleClearAll.bind(this, clickedRow)}
        warning
        loadingRows={resettingRows}
        disabledRows={disabledClearRows}
      />,
      <RowAction
        key={2}
        tooltipText="Publish List"
        loadingTooltipText="Publishing List"
        icon={PublishListIcon}
        onClick={
          offerDueDateEnabled
            ? (clickedRow) => () => {
                setRow(clickedRow);
                setShowPublishListModal(true);
              }
            : (clickedRow) => {
                setRow(clickedRow);
                return handlePublishOfferDueDateDisabled.bind(this, clickedRow);
              }
        }
        loadingRows={publishingRows}
        disabledRows={disabledPublishRows}
      />,
    ];
  };

  const columns = () => {
    const columnsFromProfile = getColumnsFromDataTableProfile(dataTableProfileName, user.site.dataTableProfiles, {
      'channel.name': {
        formatter: (_, row) => (
          <div className={classes.listNameContainer}>
            <span>{row.channel.name}</span>
            {row.channel.isVendorOfRecord && <SpoilerAlertAppleIcon className={classes.spoilerAlertApple} />}
          </div>
        ),
      },
    });
    const archiveStatusColumn = new Column({
      field: 'archiveStatus',
      formatter: (_, currentRow) =>
        currentRow?.archiveStatus === ArchiveStatus.SOME_ARCHIVED || currentRow?.archiveStatus === ArchiveStatus.ALL_ARCHIVED ? (
          <MiniTooltip
            text={
              <div style={{ width: 158, height: 36, display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
                <span style={{ margin: 'auto' }}>Some or all inventory in</span>
                <span style={{ margin: 'auto' }}>this list has been archived</span>
              </div>
            }
            style={{ width: 158 }}
          >
            <AlertWarningYellowIcon style={{ marginLeft: 6 }} />
          </MiniTooltip>
        ) : null,
      style: {
        width: 10,
      },
      visible:
        data?.currentUserQuery?.stagedListingSummary?.filter(
          (summary) => summary?.archiveStatus === ArchiveStatus.SOME_ARCHIVED || summary.archiveStatus === ArchiveStatus.ALL_ARCHIVED
        ).length > 0,
    });
    if (columnsFromProfile[0].field !== 'archiveStatus') columnsFromProfile.unshift(archiveStatusColumn);
    else columnsFromProfile.splice(0, 1, archiveStatusColumn);
    return columnsFromProfile;
  };

  return (
    <div className={classes.stagingPage}>
      <div className={classes.stagingGridHeader}>
        <Search onChange={handleSearch} className={classes.stagingGridSearchInput} />
        <Button secondary className={classes.filterButton} icon={FilterIcon} onClick={toggleFilters} filter={statusFilter.length !== 0}>
          {statusFilter.length > 0 ? `${statusFilter.length} ${pluralize('Filter', statusFilter.length)}` : 'Filter'}
        </Button>
      </div>
      <div className={classes.table_wrap}>
        <DataTableNaked
          data={filteredData || []}
          loading={loading}
          noDataMessage={loading ? 'Loading...' : 'No Staged Listings'}
          userId={user._id}
          transition
          searchText={searchText}
          onRowClick={handleRowClick}
          columns={columns()}
          rowActions={rowActions(filteredData || [])}
          sticky
        />
      </div>
      <Flyout position="right" open={showFilters} onHide={toggleFilters}>
        <div data-element="filters-header" className={classes.filtersHeader}>
          <h4>Filters</h4>
          <a tabIndex="1" onClick={clearFilters} data-element="clear-filters" className={classes.clearFilters}>
            Clear Filters
          </a>
        </div>
        {statusFilterOptions()}
      </Flyout>
      <PublishListModal
        open={showPublishListModal}
        onHide={() => setShowPublishListModal(false)}
        donation={row?.channel?.destinationType === destinationType.donation}
        onSubmit={handlePublishOfferDueDateEnabled}
        isVendorOfRecord={row?.channel?.isVendorOfRecord}
      />
    </div>
  );
};

GenericStaging.propTypes = {
  user: PropTypes.object,
  dataQuery: PropTypes.object,
  progressBarsQuery: PropTypes.object,
  pollQuery: PropTypes.object,
  refetchQueries: PropTypes.array,
  history: PropTypes.object,
  dataTableProfileName: PropTypes.string,
  publishEndpoint: PropTypes.string,
  dataGetter: PropTypes.func,
  pollDataGetter: PropTypes.func,
  clearListingsMutation: PropTypes.object,
  clearListingsResponseGetter: PropTypes.func,
  detailPagePath: PropTypes.string,
};

export default GenericStaging;
