import React from 'react';
import { createUseStyles } from 'react-jss';
import SuggestionsBanner from './suggestion-banner';

const useStyles = createUseStyles({
  block: {
    display: 'flex',
  },
  header: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '-0.198px',
    marginBottom: 0,
  },
  list: {
    listStyleType: 'disc',
    fontSize: '20px',
    marginLeft: '18px',
    '& li': {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '-0.084px',
    },
  },
});

const RecommendedNegotiations = () => {
  const classes = useStyles();
  const kustomerLink = 'https://spoileralert.kustomer.help/en_us/suggested-negotiation-HkS8lv9cJg';
  const bannerText = (
    <div>
      <div className={classes.header}>Increase revenue by negotiating offers below reserve and highest historical transaction price</div>
      <ul className={classes.list}>
        <li>Suggestions are limited to the single best negotiation per inventory line to ensure customers that meet a counter are awarded</li>
        <li>Suggestions focus on bids for active or recommended truck lanes</li>
        <li>Highest historical price suggestions are prioritized over reserve price to maximize revenue</li>
      </ul>
    </div>
  );
  return <SuggestionsBanner learnMoreLink={kustomerLink}>{bannerText}</SuggestionsBanner>;
};

RecommendedNegotiations.propTypes = {};

export default RecommendedNegotiations;
