import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import injectSheet from 'react-jss';
import accounting from 'accounting';
import {
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  Theme as theme,
  Table,
  Row,
  Col,
  TextBox,
  Card,
  CalculatorIcon,
  AlertService,
  compose,
} from '@spoiler-alert/ui-library';
import { createNegotiationStagedListings } from '../../graphql/mutations';
import { weightWithUnit } from '../../utilities/unit-helper';

const CYPRESS_TAGS = {
  FORM: 'negotiation-change-quantity-modal-form',
  INPUT: 'negotiation-change-quantity-modal-input',
  SUBMIT: 'negotiation-change-quantity-modal-submit',
};
const styles = {
  table__container: {
    overflow: 'auto',
    maxHeight: 500,
  },
  table: {
    width: '100%',
    '& td': {
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  table__totalrow: {
    fontWeight: 600,
    borderTop: '1px solid',
    borderTopColor: theme.tableHeaderTextColor,
  },
  table__headerrow: {
    color: '#292f34',
    fontWeight: 'normal',
    fontSize: '16px',
  },
  table__numeric: {
    textAlign: 'right',
  },
  table__link: {
    backgroundColor: `${theme.errorColor} !important`,
    color: '#fff !important',
    display: 'inline-block !important',
  },
  modal__title: {
    fontSize: '1.5rem',
    padding: 0,
    marginTop: 0,
    marginBottom: '6px',
    color: '#292e34',
    fontWeight: 'normal',
  },
  col__inputs: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '48px',
    alignItems: 'center',
    marginLeft: '5px',
    marginRight: '50px',
  },
  counter__card: {
    marginBottom: '13px',
    marginTop: '10px',
    height: '48px',
    paddingTop: '0px !important',
    PaddingBottom: '0px !important',
  },
  card__col: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '48px',
    alignItems: 'center',
    marginLeft: '5px',
    marginRight: '0px',
    fontSize: '14px',
  },
  item__description: {
    fontSize: '16px',
    marginBottom: '10px',
  },
  content__icon: {
    height: 24,
    width: 24,
    margin: 10,
    fill: theme.grey50,
  },
  form__container: {
    width: '100%',
  },
  col__containers: {
    height: '48px',
    paddingTop: '0px !important',
    PaddingBottom: '0px !important',
  },
  input__field: {
    height: '32px !important',
    textAlign: 'right !important',
    width: '81px',
    paddingRight: '10px',
  },
  input__wrapper: {
    height: '32px !important',
    textAlign: 'right !important',
  },
  price__textbox_failure: {
    borderColor: `${theme.downArrowColor} !important`,
  },
  hide__Row: {
    display: 'none !important',
  },
};

@injectSheet(styles)
export class NegotiationQuantityModal extends Component {
  state = {
    counterQuantity: this.props.negotiationDetail.negotiationListing?.suggestedQuantity || '',
    loading: false,
    quantityIsValid: true,
  };

  static propTypes = {
    classes: PropTypes.object,
    onHide: PropTypes.func,
    open: PropTypes.bool,
    refetchQueries: PropTypes.array,
    data: PropTypes.object,
    filters: PropTypes.object,
    createNegotiationStagedListings: PropTypes.func,
    negotiationDetail: PropTypes.object,
    user: PropTypes.object,
  };

  setCounterQuantity = (counterQuantity) => {
    const { negotiationDetail } = this.props;
    if (counterQuantity > negotiationDetail.onHandQuantity) {
      this.setState({ quantityIsValid: false });
    } else {
      this.setState({ counterQuantity, quantityIsValid: true });
    }
  };

  changeQuantity = (e) => {
    e.stopPropagation();
    const { counterQuantity } = this.state;
    const { negotiationDetail, refetchQueries } = this.props;
    this.setState({ loading: true });
    this.props
      .createNegotiationStagedListings({
        variables: {
          counterOffers: [
            {
              offerListingId: this.props.negotiationDetail.offerListing?._id,
              counterOfferQuantity: parseInt(counterQuantity, 10),
              counterOfferPrice: this.props.negotiationDetail.negotiationListing.suggestedUnitPrice,
              // Not passing in anchor or percentage since this is just a quantity change. The NSL creator will keep the existing anchor/percentage.
            },
          ],
        },
        refetchQueries,
      })
      .then((response) => {
        const result = response.data.createNegotiationStagedListings;
        if (result.errors?.length > 0) {
          const message =
            result.errors[0].code === 'NEGOTIATION_PUBLISHED' ? result.errors[0].message : 'A problem occurred attempting to update the quantity.';
          throw new Error(message);
        }
        this.showSuccess(`Quantity change of ${counterQuantity} for ${negotiationDetail.description} was successful.`);
      })
      .catch((err) => this.showFailure(err.message));
  };

  showFailure = (message) => {
    this.setState({ loading: false });
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  };

  showSuccess = (message) => {
    this.setState({ loading: false, counterQuantity: '' });
    AlertService.alert({
      type: 'success',
      message: <span>{message}</span>,
      autoDismiss: true,
      dismissDelay: 3000,
    });
    this.props.onHide();
  };

  render() {
    const { open, classes, negotiationDetail, onHide } = this.props;
    const { counterQuantity, loading, quantityIsValid } = this.state;
    let textboxClass = '';
    if (!quantityIsValid) textboxClass = classes.price__textbox_failure;
    let showCasesPerPallet = '';
    if (!negotiationDetail.casesPerPallet && !negotiationDetail.casesPerPallet?.length) showCasesPerPallet = classes.hide__Row;
    return (
      <Modal className={classes.modal__wrapper} onHide={onHide} open={open} closeOnEsc closeOnOutsideClick>
        <form className={classes.form__container} data-cy={CYPRESS_TAGS.FORM}>
          <ModalContent>
            <h2 className={classes.modal__title}>Quantity Change</h2>
            <div className={classes.item__description}>{negotiationDetail.description}</div>
            <div className={classes.table__container}>
              <Card className={classes.counter__card}>
                <div className={classes.card__col}>
                  <div>Counter Quantity</div>
                  <div>
                    <TextBox
                      inputClassName={classes.input__field}
                      className={`${classes.input__wrapper} ${textboxClass}`}
                      onChange={this.setCounterQuantity}
                      type="string"
                      min={0}
                      value={this.state.counterQuantity}
                      required
                      cypressTag={CYPRESS_TAGS.INPUT}
                    />
                  </div>
                </div>
              </Card>
              <Table className={classes.table}>
                <Row className={classes.col__containers}>
                  <Col>
                    <div className={classes.col__inputs}>
                      <div>Available Quantity</div> <div>{accounting.formatNumber(negotiationDetail.onHandQuantity)}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className={classes.col__inputs}>
                      <div>Listed Quantity</div> <div>{accounting.formatNumber(negotiationDetail.offerListing?.listing?.availableQuantity)}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className={classes.col__inputs}>
                      <div>Offer Quantity</div> <div>{accounting.formatNumber(negotiationDetail.negotiationListing?.offerDetails?.quantity)}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className={classes.col__inputs}>
                      <div>Original Weight</div>{' '}
                      <div>
                        {weightWithUnit(
                          negotiationDetail.unitNetWeight * negotiationDetail.negotiationListing?.offerDetails?.quantity,
                          this.props.user
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className={classes.col__inputs}>
                      <div> Counter Weight</div>{' '}
                      <div>
                        <CalculatorIcon className={classes.content__icon} />
                        {counterQuantity > 0 ? accounting.formatNumber(negotiationDetail.unitNetWeight * counterQuantity) : '--'}{' '}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className={showCasesPerPallet}>
                  <Col>
                    <div className={`${classes.col__inputs}`}>
                      <div>Original Pallets</div>
                      <div>
                        {accounting.formatNumber(negotiationDetail.negotiationListing?.offerDetails?.quantity * negotiationDetail.casesPerPallet)}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className={showCasesPerPallet}>
                  <Col>
                    <div className={classes.col__inputs}>
                      <div>Counter Pallets</div>{' '}
                      <div>
                        <CalculatorIcon className={classes.content__icon} />
                        {counterQuantity > 0 ? accounting.formatNumber(negotiationDetail.casesPerPallet * counterQuantity) : '--'}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Table>
            </div>
          </ModalContent>
          <ModalFooter>
            <Button onClick={onHide} link type="button">
              Cancel
            </Button>
            <Button
              type="button"
              loading={loading}
              disabled={!quantityIsValid || counterQuantity <= 0}
              primary
              loadingText="Applying Changes"
              onClick={this.changeQuantity}
              cypressTag={CYPRESS_TAGS.SUBMIT}
            >
              Apply Changes
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

export default compose(graphql(createNegotiationStagedListings, { name: 'createNegotiationStagedListings' }))(NegotiationQuantityModal);
