import { Theme as theme } from '@spoiler-alert/ui-library';

const postAwardUpdatesModalStyles = {
  header: {
    fontWeight: '400',
    fontSize: '23px',
  },
  warningContent: {
    display: 'flex',
    alignItems: 'baseline',
    backgroundColor: theme.red5,
    padding: '12px',
    borderRadius: '4px',
    fontSize: '16px',
  },
  warningText: {
    marginLeft: '12px',
  },
  infoIcon: {
    fill: theme.red,
    width: '16px',
  },
};

export default postAwardUpdatesModalStyles;
