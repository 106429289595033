import { lazy } from 'react';
export default class BatchComponentLoader {
    constructor(importFunction) {
        this.getPages = async () => {
            if (this.loadedPages !== undefined)
                return this.loadedPages;
            this.loadedPages = await this.importFunction();
            return this.loadedPages;
        };
        this.getModule = async (moduleName) => {
            const pages = await this.getPages();
            return { default: pages[moduleName] || pages.default };
        };
        this.importFunction = importFunction;
    }
    lazyLoad(moduleName) {
        return lazy(() => this.getModule(moduleName));
    }
}
