import React from 'react';
import PropTypes from 'prop-types';
import SendLossEmailsMarketplaceModal from './send-loss-emails-marketplace-modal';
import SendLossEmailsEnterpriseModal from './send-loss-emails-enterprise-modal';
import { checkFeature, featureFlags } from '../../helpers';
import { Modal } from '@spoiler-alert/ui-library';

const SendLossEmailsModal = ({ open, onHide, ...rest }) => {
  return (
    <Modal onHide={() => onHide} open={open} closeOnEsc closeOnOutsideClick>
      {open &&
        (checkFeature(featureFlags.marketplace) ? (
          <SendLossEmailsMarketplaceModal onHide={onHide} {...rest} />
        ) : (
          <SendLossEmailsEnterpriseModal onHide={onHide} {...rest} />
        ))}
    </Modal>
  );
};

SendLossEmailsModal.propTypes = {
  user: PropTypes.object,
  onHide: PropTypes.func,
  open: PropTypes.bool,
};

export default SendLossEmailsModal;
