import {
  AlertService,
  BarChartIncreaseIcon,
  Checkbox,
  ChevronIcon,
  Column,
  DataTableNaked,
  Flyout,
  NegotiateIcon,
  OverlayService,
  RowAction,
  Theme,
  Timing,
} from '@spoiler-alert/ui-library';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import accounting from 'accounting';
import NegotiationMessage from './negotiation-message';
import { createNegotiationStagedListings } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { negotiationOrigin } from '../../enums';
import SuggestedNegotiationsDetails from './suggested-negotiations-details';
import { StagedNegotiationSummaryQuery } from '../../graphql/queries';

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  spacedRow: {
    extend: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '-0.18px',
    marginRight: 12,
  },
  arrow: {
    height: 8,
    width: 16,
    stroke: Theme.textColorPrimary,
    marginRight: 8,
  },
  chart: {
    marginRight: 8,
    transform: 'translateY(-4px)',
  },
  opportunityText: {
    color: Theme.green,
    fontSize: 14,
    fontWeight: 500,
  },
  opportunityRowExpand: {
    extend: 'row',
    padding: 8,
    marginBottom: 4,
    width: 'fit-content',
    '&:hover': {
      backgroundColor: Theme.green5,
      cursor: 'pointer',
    },
  },
  checkbox: {
    display: 'flex',
    marginRight: 8,
    color: Theme.grey80,
  },
  table: {
    border: `1px ${Theme.borderColor} solid`,
    borderRadius: '4px',
    paddingTop: '2px',
  },
  details: {
    width: '550px',
  },
  detailsContainer: {
    height: '100%',
  },
  detailsContents: {
    padding: 0,
  },
});

const SuggestedNegotiationsCard = ({ negotiations, buyerName, selectedRows, setSelectedRows, loadingRows, setLoadingRows, user }) => {
  const classes = useStyles();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const [isOverFlowing, setIsOverFlowing] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [currentNegotiation, setCurrentNegotiation] = useState(null);
  const [negotiate] = useMutation(createNegotiationStagedListings);

  const checkRowItem = useCallback(
    (event, rowId) => {
      if (event.checked) {
        setSelectedRows([...selectedRows, rowId]);
      } else {
        setSelectedRows(selectedRows.filter((row) => row !== rowId));
      }
    },
    [selectedRows, setSelectedRows]
  );

  const reasonMessage = (reasonType, unitPrice, overrideMessage) => {
    if (overrideMessage) return overrideMessage;
    return reasonType === 'RESERVE_COUNTER'
      ? 'Please meet my minimum price for this item to be awarded this inventory.'
      : `You paid ${accounting.formatMoney(unitPrice)} for this inventory previously. Please meet this price to be awarded this inventory.`;
  };

  const checkOverflow = useCallback(() => {
    if (tableRef.current && dataTableRef.current && showSuggestions) {
      const windowWidth = tableRef.current.offsetWidth;
      const tableWidth = dataTableRef.current.offsetWidth;

      setIsOverFlowing(tableWidth > windowWidth);
    }
  }, [dataTableRef, showSuggestions]);

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [checkOverflow]);

  const checkboxColumn = useMemo(
    () =>
      new Column({
        displayName: '',
        field: '_id',
        formatter: (_, currentRow) => (
          <Checkbox
            id={`${currentRow._id}-select-box`}
            onChecked={(event) => {
              event.event.stopPropagation();
              checkRowItem(event, currentRow._id);
            }}
            checked={selectedRows.includes(currentRow._id)}
          />
        ),
        visible: true,
        sortable: false,
        style: { width: '76px' },
      }),
    [selectedRows, checkRowItem]
  );

  const columns = useMemo(() => {
    return [
      new Column({
        displayName: 'Site Name',
        field: 'siteName',
        formatter: (_, currentRow) => currentRow?.inventory?.siteName || '-',
        visible: true,
        sortable: false,
        defaultSort: true,
      }),
      new Column({
        displayName: 'Item Number',
        field: 'itemName',
        formatter: (_, currentRow) => currentRow?.inventory?.itemName || '-',
        visible: true,
        sortable: false,
      }),
      new Column({
        displayName: 'Description',
        field: 'description',
        formatter: (_, currentRow) => currentRow?.inventory?.description || '-',
        visible: true,
        sortable: false,
      }),
      new Column({
        displayName: 'Best By',
        field: 'bestBy',
        formatter: (_, currentRow) => moment(currentRow?.inventory?.bestByDate).format('MM/DD/YYYY') || '-',
        visible: true,
        sortable: false,
      }),
      new Column({
        displayName: 'Logistics',
        field: 'logistics',
        formatter: (_, currentRow) => currentRow?.logisticsTerm || '-',
        visible: true,
        sortable: false,
      }),
      new Column({
        displayName: 'Truck Type',
        field: 'truckType',
        formatter: (_, currentRow) => currentRow?.truckType || '-',
        visible: true,
        sortable: false,
      }),
      new Column({
        displayName: 'Current Quantity',
        field: 'originalQuantity',
        formatter: (_, currentRow) => currentRow?.originalQuantity || '-',
        visible: true,
        sortable: false,
      }),
      new Column({
        displayName: 'Counter Quantity',
        field: 'originalQuantity',
        formatter: (_, currentRow) => currentRow?.originalQuantity || '-',
        visible: true,
        sortable: false,
      }),
      new Column({
        displayName: 'Current Offer / Case',
        field: '',
        formatter: (_, currentRow) => accounting.formatMoney(currentRow?.originalTotalPrice / currentRow?.originalQuantity) || '-',
        visible: true,
        sortable: false,
      }),
      new Column({
        displayName: 'Counter Offer / Case',
        field: 'suggestions.negotiation.unitPrice',
        formatter: (_, currentRow) => accounting.formatMoney(currentRow?.suggestions?.negotiation.unitPrice) || '-',
        visible: true,
        sortable: false,
      }),
      new Column({
        displayName: 'GTV Gain',
        field: '',
        formatter: (_, currentRow) =>
          accounting.formatMoney(currentRow?.originalQuantity * currentRow?.suggestions?.negotiation.unitPrice - currentRow?.originalTotalPrice) ||
          '-',
        visible: true,
        sortable: false,
      }),
      new Column({
        displayName: 'Anchor',
        field: '',
        formatter: (_, currentRow) =>
          (currentRow?.suggestions?.negotiation.reason || '-') === 'RESERVE_COUNTER'
            ? `${user.site.counterOfferNumber}% of reserve price`
            : 'Historical price',
        visible: true,
        sortable: false,
      }),
      new Column({
        field: 'suggestions.negotiation.reason',
        displayName: '',
        visible: true,
        sortable: false,
        style: { width: '25px' },
        formatter: (_, row) => (
          <NegotiationMessage
            defaultMessage={reasonMessage(row.suggestions?.negotiation.reason, row.suggestions?.negotiation.unitPrice, row.overrideMessage)}
            row={row}
            key={row._id}
            saveOverride={(e) => (row.overrideMessage = e)}
            parentRef={tableRef}
          />
        ),
      }),
    ];
  }, [user]);

  const opportunityAmount = useMemo(() => {
    return accounting.formatMoney(
      negotiations.reduce((acc, curr) => {
        return acc + (curr?.originalQuantity * curr?.suggestions?.negotiation.unitPrice - curr?.originalTotalPrice);
      }, 0)
    );
  }, [negotiations]);

  const allSelected = useMemo(() => {
    return selectedRows.length > 0 && negotiations.every((ol) => selectedRows.includes(ol._id));
  }, [selectedRows, negotiations]);

  const partialSelected = useMemo(() => {
    return selectedRows.length > 0 && negotiations.some((ol) => selectedRows.includes(ol._id)) && !allSelected;
  }, [selectedRows, negotiations, allSelected]);

  const selectAll = (event) => {
    if (event.checked) {
      setSelectedRows([...selectedRows, ...negotiations.map((ol) => ol._id)]);
    } else {
      setSelectedRows(selectedRows.filter((row) => !negotiations.map((ol) => ol._id).includes(row)));
    }
  };

  const toggleDetails = (row) => {
    if (showDetails) {
      OverlayService.hide();
      setShowDetails(false);
    } else {
      OverlayService.show();
      setShowDetails(true);
      setCurrentNegotiation(row);
    }
  };

  const removeLoadingArray = (id) => {
    const index = loadingRows.indexOf(id);
    const arrayDup = [...loadingRows];
    if (index > -1) {
      arrayDup.splice(index, 1);
    }
    setLoadingRows(arrayDup);
  };

  const stageNegotiation = Timing.throttle(async (row) => {
    setLoadingRows([...loadingRows, row._id]);
    const response = await negotiate({
      variables: {
        counterOffers: [
          {
            offerListingId: row._id,
            counterOfferAnchor: row.suggestions?.negotiation.reason === 'RESERVE_COUNTER' ? 'ReservePrice' : 'BestHistoricalPrice',
            counterOfferPrice: row.suggestions?.negotiation.unitPrice,
            reason: reasonMessage(row.suggestions?.negotiation.reason, row.suggestions?.negotiation.unitPrice, row.overrideMessage),
          },
        ],
        negotiationOrigin: negotiationOrigin.SUGGESTED_NEGOTIATION,
        tags: ['suggested-negotiation-staged'],
      },
      refetchQueries: [
        {
          query: StagedNegotiationSummaryQuery,
        },
      ],
    });

    if (response.data?.createNegotiationStagedListings?.errors?.length) {
      AlertService.alert({
        type: 'error',
        message: response.data?.createNegotiationStagedListings?.errors[0].message,
        autoDismiss: true,
      });
    } else {
      AlertService.alert({
        type: 'success',
        message: <span>{`Negotiation started with ${buyerName}`}</span>,
        autoDismiss: true,
      });
    }
    removeLoadingArray(row._id);
  }, 1000);

  return (
    <div>
      <div className={classes.spacedRow}>
        <div className={classes.row}>
          <Checkbox
            id={`${buyerName}-select-all`}
            className={classes.checkbox}
            onChecked={selectAll}
            partial={partialSelected}
            checked={allSelected}
          />
          <span className={classes.title}>{buyerName}</span>
        </div>
      </div>
      <div className={classes.opportunityRow}>
        <div className={classes.opportunityRowExpand} onClick={() => setShowSuggestions(!showSuggestions)}>
          <ChevronIcon className={classes.arrow} style={{ transform: `rotate(${showSuggestions ? '180' : '0'}deg)`, transition: 'transform 0.3s' }} />
          <div>
            <BarChartIncreaseIcon className={classes.chart} />
            <span className={classes.opportunityText}>
              {showSuggestions ? 'HIDE' : 'SHOW'} {opportunityAmount} OPPORTUNITY
            </span>
          </div>
        </div>
      </div>

      {showSuggestions && (
        <div className={classes.table} ref={tableRef}>
          <DataTableNaked
            data={negotiations}
            rowActions={[
              <RowAction
                key={2}
                tooltipText="Negotiate"
                tooltipPosition="top"
                loadingTooltipText="Negotiating"
                icon={NegotiateIcon}
                loadingRows={loadingRows}
                disabledRows={loadingRows}
                onClick={(row) => stageNegotiation.bind(this, row)}
              />,
            ]}
            columns={[checkboxColumn, ...columns]}
            onRowClick={toggleDetails}
            checkedRows={selectedRows}
            horizontalScroll={isOverFlowing}
            ref={dataTableRef}
          />
        </div>
      )}
      <Flyout
        position="right"
        classes={{ flyout__container: classes.detailsContainer, flyout__wrapper: classes.details, flyout__contents: classes.detailsContents }}
        open={showDetails}
        onHide={toggleDetails}
      >
        <SuggestedNegotiationsDetails negotiation={currentNegotiation} user={user} />
      </Flyout>
    </div>
  );
};

SuggestedNegotiationsCard.propTypes = {
  negotiations: PropTypes.array,
  buyerName: PropTypes.string,
  selectedRows: PropTypes.array,
  setSelectedRows: PropTypes.func,
  loadingRows: PropTypes.array,
  setLoadingRows: PropTypes.func,
  user: PropTypes.object,
};

export default SuggestedNegotiationsCard;
