import React, { useMemo, useState } from 'react';
import accounting from 'accounting';
import { PropTypes } from 'mobx-react';
import { event } from 'react-fullstory';
import { createUseStyles } from 'react-jss';
import { ChevronIcon } from '@spoiler-alert/ui-library';
import styles from './offer-card-v2-styles';
import NegotiationPricingStrategyAnchor from '../negotiations/negotiation-pricing-strategy-anchors';

const useStyles = createUseStyles(styles, { name: 'OfferCard' });

const OfferCardNegotiationsDetails = ({ offerInfo, user, isNegotiationReceived, chevronArrowDirection }) => {
  const classes = useStyles();
  const [chevronDirection, setChevronDirection] = useState(chevronArrowDirection);

  const onNegotiationsClick = () => {
    if (chevronDirection === 'down') {
      event(`OC - Negotiations Info Expanded`, {
        userId: user._id,
        offerId: offerInfo._id,
      });
      setChevronDirection('up');
    }
    if (chevronDirection === 'up') setChevronDirection('down');
  };

  const renderNegotiationText = () => {
    const pricingStrategy = offerInfo?.negotiation?.pricingStrategy;
    const displayValue = NegotiationPricingStrategyAnchor.get(pricingStrategy);
    switch (displayValue) {
      case 'Default':
      case 'Flat Rate':
      case 'Manual':
        return `(${displayValue.toUpperCase()})`;
      case 'COGS':
      case 'List Price':
      case 'Best Price Received (Global)':
      case 'Best Price Received (DL)':
      case 'Current Offer':
      case 'Best Current Offer':
      case 'Set On Upload':
      case 'Reserve Price':
        return `(${offerInfo?.negotiation?.percentageModifier}% OF ${displayValue.toUpperCase()})`;
      default:
        return '';
    }
  };

  const buyerResponseQuantity = offerInfo?.negotiation?.buyerResponseQuantity || offerInfo.quantity;
  const buyerResponseUnitPrice = offerInfo?.negotiation?.buyerResponseUnitPrice || offerInfo.totalPrice / offerInfo.quantity || 0;

  const renderExpandedNegotiations = useMemo(
    () => (
      <div className={classes.expandedTrucklaneContainer}>
        <div className={classes.firstTrucklaneRow}>
          <span></span>
          <span className={classes.negotiationsQtyPrice}>
            <span className={`${classes.negotiationsQtyPriceCell} header`}>QTY</span>
            <span className={`${classes.negotiationsQtyPriceCell} header`}>PRICE</span>
          </span>
        </div>
        <div className={classes.trucklaneRowWithBorder}>
          <span className={`${classes.header} negotiation`}>CUSTOMER RESPONSE BID</span>
          <span className={classes.negotiationsQtyPrice}>
            <span className={classes.negotiationsQtyPriceCell}>{isNegotiationReceived ? accounting.formatNumber(buyerResponseQuantity) : '-'}</span>
            <span className={classes.negotiationsQtyPriceCell}>{isNegotiationReceived ? accounting.formatMoney(buyerResponseUnitPrice) : '-'}</span>
          </span>
        </div>
        <div className={classes.trucklaneRowWithBorder}>
          <span className={`${classes.header} negotiation`}>
            YOUR COUNTER OFFER <br /> {renderNegotiationText()}
          </span>
          <span className={classes.negotiationsQtyPrice}>
            <span className={classes.negotiationsQtyPriceCell}>{accounting.formatNumber(offerInfo?.negotiation?.stagedQuantity)}</span>
            <span className={classes.negotiationsQtyPriceCell}>{accounting.formatMoney(offerInfo?.negotiation?.stagedUnitPrice)}</span>
          </span>
        </div>
        <div className={classes.trucklaneRow}>
          <span className={`${classes.header} negotiation`}>CUSTOMER ORIGINAL BID</span>
          <span className={classes.negotiationsQtyPrice}>
            <span className={classes.negotiationsQtyPriceCell}>{accounting.formatNumber(offerInfo.originalQuantity)}</span>
            <span className={classes.negotiationsQtyPriceCell}>
              {accounting.formatMoney(offerInfo.originalTotalPrice / offerInfo.originalQuantity)}
            </span>
          </span>
        </div>
      </div>
    ),
    [offerInfo, isNegotiationReceived]
  );

  return (
    <>
      <div className={classes.inactiveTrucklane} onClick={onNegotiationsClick} data-testid="negotiations-info">
        <span className={classes.trucklaneText}>{`NEGOTIATION ACTIVITY`}</span>
        <div className={classes.statusContainer}>
          <span className={classes.inactiveChevronContainer} id="ChevronContainer">
            <ChevronIcon className={classes.chevron} direction={chevronDirection} id="Chevron" />
          </span>
        </div>
      </div>
      {chevronDirection === 'up' && renderExpandedNegotiations}
    </>
  );
};

OfferCardNegotiationsDetails.propTypes = {
  activeTruckLane: PropTypes.bool,
  offerInfo: PropTypes.object,
  isAwarded: PropTypes.bool,
  quantity: PropTypes.number,
  user: PropTypes.object,
  pricePerCase: PropTypes.number,
  isNegotiationReceived: PropTypes.bool,
  chevronArrowDirection: PropTypes.string,
};

export default OfferCardNegotiationsDetails;
