import routePaths from './route-paths';

export default class RouteGuards {
  static tax(user) {
    const isValid = user.privileges.canUserSiteUseTax;
    if (isValid) return { isValid, redirect: routePaths.taxDashboard };
    return {
      isValid,
      redirect: routePaths.notFound,
    };
  }

  static posts(user) {
    const isValid = user.privileges.canViewPosts;
    if (isValid) return { isValid, redirect: routePaths.myPosts };
    return {
      isValid,
      redirect: RouteGuards.claims(user).redirect,
    };
  }

  static post(user) {
    const isValid = user.privileges.canViewActivity;
    return { isValid, redirect: routePaths.home };
  }

  static claims(user) {
    const isValid = user.privileges.canViewClaims;
    if (isValid) return { isValid, redirect: routePaths.myVerifications };
    return {
      isValid,
      redirect: RouteGuards.inventory(user).redirect,
    };
  }

  static enterpriseDashboard(user) {
    const isValid = user.privileges.canViewEnterpriseDashboard;
    if (isValid) return { isValid, redirect: routePaths.home };
    return {
      isValid,
      redirect: RouteGuards.sellers(user).redirect,
    };
  }

  static holisticsDashboard(user) {
    const isSeller = user.site.siteRole.includes('SELLER');
    const hasPriv = user.privileges.canViewEnterpriseDashboard;
    let { redirect } = RouteGuards.posts(user);
    if (isSeller) redirect = routePaths.inventory;
    if (user.site.siteRole.includes('DONATION SELLER')) redirect = routePaths.enterpriseDashboard;
    return {
      isValid: hasPriv && isSeller,
      redirect,
    };
  }

  static userManagement(user) {
    const isValid = user.privileges.canViewUserManagement;
    return { isValid, redirect: routePaths.home };
  }

  static inventory(user) {
    const isValid = user.privileges.canViewInventory;
    if (isValid) return { isValid, redirect: routePaths.inventory };
    return {
      isValid,
      redirect: RouteGuards.receiveOffer(user).redirect,
    };
  }

  static sellers(user) {
    const isValid = user.privileges.canMakeOffers;
    if (isValid) return { isValid, redirect: routePaths.posts };
    return {
      isValid,
      redirect: RouteGuards.posts(user).redirect,
    };
  }

  static receiveOffer(user) {
    const isValid = user.privileges.canReceiveOffers;
    if (isValid) return { isValid, redirect: routePaths.offerComparison };
    return {
      isValid,
      redirect: RouteGuards.items(user).redirect,
    };
  }

  static items(user) {
    const isValid = user.privileges.canViewItems;
    if (isValid) return { isValid, redirect: routePaths.itemCatalog };
    return {
      isValid,
      redirect: RouteGuards.tax(user).redirect,
    };
  }

  static import(user) {
    const isValid = user.privileges.canUserAddBulkImport;
    return { isValid, redirect: routePaths.home };
  }

  static transactions(user) {
    const isValid = user.privileges.canReconcile;
    if (isValid) return { isValid, redirect: routePaths.transactions };
    return {
      isValid,
      redirect: routePaths.home,
    };
  }
}
