import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Theme } from '@spoiler-alert/ui-library';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import OfferCardV2 from '../offer-comparison/offer-card-v2';
import { useQuery } from '@apollo/client';
import { OfferListingsByInventoryQuery } from '../../graphql/queries';
import StaticLoading from '../static-loading';

const useStyles = createUseStyles({
  container: {
    overflowY: 'auto',
    padding: '24px',
  },
  wrappedRow: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 12,
    paddingTop: 12,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 24,
  },
  card: {
    border: `1px ${Theme.borderColor} solid`,
    borderRadius: 4,
    padding: 16,
  },
  marginCard: {
    extend: 'card',
    marginBottom: 24,
  },
  cardHeader: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
    marginBottom: 0,
  },
  cardMarginHeader: {
    extend: 'cardHeader',
    marginBottom: 12,
  },
  tableHeader: {
    color: Theme.tableHeaderTextColor,
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: '500',
  },
  tableText: {
    fontSize: '14px',
  },
  suggestionText: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '-0.084px',
    padding: '12px 0',
  },
  offerCard: {
    marginTop: 12,
  },
  offerCardMargin: {
    marginBottom: 12,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  detailTitle: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '-0.176px',
    marginBottom: 24,
  },
  emptyState: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '-0.084px',
  },
  offerCardWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 2,
    border: `solid 1px ${Theme.grey30}`,
    '&.awarded': {
      borderColor: Theme.green,
    },
    '&.negotiated': {
      borderColor: Theme.orange,
    },
    '&.negotiationStaged': {
      borderColor: Theme.grey,
    },
    '&.negotiationReceived': {
      borderColor: Theme.blue,
    },
  },
});

const SuggestedNegotiationsDetails = ({ negotiation, user }) => {
  const classes = useStyles();
  const { data, loading } = useQuery(OfferListingsByInventoryQuery, { variables: { inventoryIds: [negotiation.inventory._id] } });

  const inventoryTable = [
    { header: 'SKU', value: negotiation.inventory.itemName, key: uuidv4() },
    { header: 'DESCRIPTION', value: negotiation.inventory.description, key: uuidv4() },
    { header: 'BEST BY', value: moment(negotiation.inventory.bestByDate).format('MM/DD/YYYY'), key: uuidv4() },
    { header: 'SITE NAME', value: negotiation.inventory.siteName, key: uuidv4() },
    { header: 'AVAILABLE QTY', value: negotiation.inventory.availableQuantity, key: uuidv4() },
  ];

  const gtvGain = useMemo(
    () => accounting.formatMoney(negotiation?.originalQuantity * negotiation?.suggestions?.negotiation?.unitPrice - negotiation?.originalTotalPrice),
    [negotiation]
  );

  const { currentOfferListing, otherOfferListings } = useMemo(() => {
    const otherOfferListings = data?.offerListingsByInventoryQuery?.offerListings.filter((offer) => offer._id !== negotiation._id);
    const currentOfferListing = data?.offerListingsByInventoryQuery?.offerListings.find((offer) => offer._id === negotiation._id);
    return { currentOfferListing, otherOfferListings };
  }, [data, negotiation]);

  const suggestionMessage = () => {
    if (negotiation?.suggestions?.negotiation?.reason === 'RESERVE_COUNTER') {
      return `We suggest negotiating this offer up to ${user.site.counterOfferNumber}% of your reserve price as defined in your negotiation rules. This will result in a GTV gain of ${gtvGain}.`;
    } else {
      return `We suggest negotiating this offer up to your customer’s best historical transacted price of ${accounting.formatMoney(negotiation?.suggestions?.negotiation?.unitPrice)}. This will result in a GTV gain of
      ${gtvGain}.`;
    }
  };

  const displayOfferCards = () => {
    if (otherOfferListings.length > 0)
      return otherOfferListings.map((offer) => (
        <div className={classes.offerCardMargin} key={offer._id}>
          <OfferCardV2
            key={offer._id}
            disabled={true}
            setOfferCardsEnabledState={() => {}}
            inventoryId={offer.inventory._id}
            offerInfo={offer}
            user={user}
            fullstoryTag={'SuggestedNegotiationsDetails'}
            showSuggestions={true}
            showNegotiationSuggestions={true}
            disableActions={true}
            styles={{ offerCardWrapper: classes.offerCardWrapper }}
          />
        </div>
      ));
    return (
      <div className={classes.emptyState}>
        <span>There are no other offers on this inventory.</span>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.detailTitle}>Negotiation Suggestion</h1>
      <div className={classes.marginCard}>
        <h1 className={classes.cardMarginHeader}>INVENTORY</h1>
        <div className={classes.wrappedRow}>
          {inventoryTable.map((data) => {
            return (
              <div className={classes.column} key={data.key}>
                <span className={classes.tableHeader}>{data.header}</span>
                <span className={classes.tableText}>{data.value}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.marginCard}>
        <h1 className={classes.cardMarginHeader}>OFFER SUGGESTED FOR NEGOTIATION</h1>
        <span className={classes.suggestionText}>{suggestionMessage()}</span>
        <div className={classes.offerCard}>
          {loading && !currentOfferListing ? (
            <StaticLoading />
          ) : (
            <OfferCardV2
              disabled={true}
              setOfferCardsEnabledState={() => {}}
              inventoryId={currentOfferListing?.inventory?._id}
              offerInfo={currentOfferListing}
              user={user}
              fullstoryTag={'SuggestedNegotiationsDetails'}
              showSuggestions={true}
              showNegotiationSuggestions={true}
              disableActions={true}
              styles={{ offerCardWrapper: classes.offerCardWrapper }}
            />
          )}
        </div>
      </div>
      <div className={classes.marginCard}>
        <h1 className={classes.cardMarginHeader}>OTHER OFFERS ON THIS INVENTORY</h1>
        <div className={classes.offerCard}>{loading ? <StaticLoading /> : displayOfferCards()}</div>
      </div>
    </div>
  );
};

SuggestedNegotiationsDetails.propTypes = {
  negotiation: PropTypes.object,
  user: PropTypes.object,
};

export default SuggestedNegotiationsDetails;
