import { Theme as theme } from '@spoiler-alert/ui-library';

const styles = {
  offerCardWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 8,
    borderRadius: 2,
    border: `solid 1px ${theme.grey30}`,
    width: 443,
    '&.awarded': {
      borderColor: theme.green,
    },
    '&.negotiated': {
      borderColor: theme.orange,
    },
    '&.negotiationStaged': {
      borderColor: theme.grey,
    },
    '&.negotiationReceived': {
      borderColor: theme.blue,
    },
  },
  offerCardAwardedTag: {
    background: theme.green,
    width: '100%',
    height: 23,
    lineHeight: '1em',
    fontWeight: 500,
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    textAlign: 'left',
    padding: '5px 0px 4px 8px',
  },
  offerCardNegotiationTag: {
    background: theme.orange,
    width: '100%',
    height: 23,
    padding: '5px 0px 4px 8px',
    lineHeight: '1em',
    fontWeight: 500,
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    textAlign: 'left',
  },
  offerCardNegotiationTagDark: {
    background: theme.greyDark,
  },
  offerCardNegotiationTagBlue: {
    background: theme.blue,
  },
  offerCardIgnoredTag: {
    background: theme.grey30,
    width: '100%',
    height: 23,
    padding: '5px 0px 4px 8px',
    lineHeight: '1em',
    fontWeight: 500,
    color: theme.greyDark,
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    textAlign: 'left',
  },
  offerCardHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: [12, 16],
  },
  offerCardTitles: {
    flexGrow: 1,
    marginRight: 'auto',
    '&>h4': {
      margin: 0,
      fontSize: '16px',
      fontWeight: 500,
    },
    '&>span>div>h4': {
      margin: 0,
      fontSize: '16px',
      fontWeight: 500,
    },
    '&>h5': {
      margin: 0,
      fontSize: '14px',
      color: theme.grey,
      fontWeight: 'normal',
    },
  },
  trucklane: {
    display: 'flex',
    height: '21px',
    margin: '0px 16px',
    backgroundColor: theme.green10,
    borderRadius: '40px',
    justifyContent: 'space-between',
    color: theme.text,
    paddingLeft: 8,
    marginBottom: 16,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.green30,
      '&:hover #ChevronContainer': {
        backgroundColor: '#2ab24a',
      },
      '&:hover #Chevron': {
        fill: theme.white,
      },
      '&:hover #StatusText': {
        color: theme.greenVeryDark,
      },
    },
  },
  inactiveTrucklane: {
    display: 'flex',
    height: '21px',
    margin: '0px 16px',
    backgroundColor: theme.grey10,
    borderRadius: '40px',
    justifyContent: 'space-between',
    color: theme.text,
    paddingLeft: 8,
    marginBottom: 16,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.grey30,
      '&:hover #ChevronContainer': {
        backgroundColor: theme.greyDark,
      },
      '&:hover #Chevron': {
        fill: theme.white,
      },
      '&:hover #StatusText': {
        color: theme.greyDark,
      },
    },
  },
  trucklaneExpanded: {
    display: 'flex',
    height: '21px',
    margin: '0px 16px',
    backgroundColor: theme.green30,
    borderRadius: '40px',
    justifyContent: 'space-between',
    color: theme.text,
    paddingLeft: 8,
    marginBottom: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  expandedTrucklaneContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    padding: '0 16px 16px',
  },
  firstTrucklaneRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  negotiationsQtyPrice: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 150,
  },
  negotiationsQtyPriceCell: {
    textAlign: 'right',
    width: '50%',
    fontWeight: 500,
    '&.header': {
      color: theme.grey,
      fontSize: '12px',
      lineHeight: 1.33,
      fontWeight: 'normal',
    },
  },
  negotiationsRow: {
    height: 24,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    padding: '4 0',
  },
  trucklaneRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 7,
    alignItems: 'center',
  },
  trucklaneRowWithBorder: {
    extend: 'trucklaneRow',
    borderBottom: `1px solid ${theme.grey10}`,
    alignItems: 'center',
  },
  trucklaneRowNetRevenue: {
    extend: 'trucklaneRow',
    backgroundColor: theme.blue5,
    color: theme.blue,
    fontWeight: 500,
  },
  trucklaneCell: {
    textAlign: 'left',
    width: '100%',
    fontWeight: 500,
    margin: '0 2px 7px 0',
    lineHeight: 1.33,
    color: theme.greyDark,
    fontSize: '12px',
  },
  trucklaneCellNegative: {
    extend: 'trucklaneCell',
    color: theme.red,
  },
  header: {
    textAlign: 'left',
    width: '100%',
    color: theme.grey,
    fontSize: '12px',
    lineHeight: 1.33,
    margin: '0 2px 7px 0',
    '&.negotiation': {
      flexGrow: 1,
      width: 'auto',
    },
  },
  headerNetRevenue: {
    textAlign: 'left',
    width: '100%',
    fontSize: '12px',
    lineHeight: 1.33,
    margin: '0 2px 7px 0',
  },
  trucklaneText: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 1,
  },
  offerCardEditable: {
    padding: [0, 16, 16],
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  wandIcon: {
    width: 24,
    height: 24,
    fill: theme.green,
  },
  chevron: {
    fill: theme.greyDark,
    width: 10,
    height: 6,
    color: theme.greenDark,
  },
  statusText: {
    marginRight: 8,
    marginTop: 1,
    color: theme.greenDark,
  },
  inactiveStatusText: {
    marginRight: 8,
    marginTop: 1,
    color: theme.greyDark,
  },
  statusTextExpanded: {
    marginRight: 8,
    marginTop: 1,
    color: theme.greenVeryDark,
  },
  chevronContainer: {
    backgroundColor: theme.green30,
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    width: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  inactiveChevronContainer: {
    backgroundColor: theme.grey30,
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    width: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  chevronContainerExpanded: {
    backgroundColor: '#2ab24a',
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    width: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  textboxWithNumerator: {
    backgroundColor: theme.green5,
    color: theme.green,
    marginRight: 15,
  },
  textboxWithDenominatorleft: {
    marginRight: 15,
  },
  textboxWithDenominatorRight: {
    marginLeft: 5,
  },
  donationTextboxWithDenominator: {
    justifyContent: 'left',
  },
  trucklaneDistance: {
    marginRight: 8,
  },
  buyerNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
  },
  buyerName: {
    display: 'inline-block',
  },
  spoilerAlertApple: {
    width: 16,
    height: 18,
    marginTop: 2,
  },
  tooltipWrapper: {
    padding: 16,
    borderRadius: 2,
    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 12,
    alignItems: 'center',
    width: 327,
    height: 40,
    textAlign: 'left',
  },
  allianzLogo: {
    minHeight: 19,
    minWidth: 75,
  },
};

export default styles;
