import { gql } from '@apollo/client';
export const OfferListingFragment = gql `
  fragment OfferListingDetail on OfferListingType {
    _id
    buyerDestinationName
    buyerProposedQuantity
    buyerProposedTotalPrice
    buyerSiteId
    buyerSiteName
    conversionRate
    costRecoveryRate
    createdAt
    currencyType
    destinationType
    discountRate
    exportRunId
    exportStatus
    ftpImportUsername
    importedCurrencyTotalPrice
    importedCurrencyType
    inventoryId
    listingId
    logisticsCost
    logisticsRelationshipName
    logisticsTerm
    logisticsTime
    negotiationListingId
    negotiationStatus
    offerId
    originalQuantity
    originalTotalPrice
    poNumber
    postAwardUpdates {
      awardedTotalPrice
      awardedQuantity
      buyerUpdatedTotalPrice
      buyerUpdatedQuantity
      status
    }
    priceOfInventoryAwarded
    quantity
    requestedDeliveryDate
    reserveAnchor
    reserveNumber
    sellerSiteName
    status
    tags
    totalPrice
    transactionCycleId
    transactionLocationId
    unitPriceRecoveryRate
    weightUnit
    writeOffDiscountPercentage
    suggestedUnitPrice
    trucklaneDistance
    trucklaneCost
    suggestions {
      award {
        suggested
        quantity
      }
      negotiation {
        unitPrice
        recommended
        reason
      }
    }
    isVendorOfRecord
  }
`;
export const OfferListingRecalcFragment = gql `
  fragment OfferListingRecalcDetail on OfferListingType {
    _id
    buyerDestinationName
    buyerProposedQuantity
    buyerProposedTotalPrice
    buyerSiteId
    buyerSiteName
    buyerDestinationId
    conversionRate
    createdAt
    currencyType
    destinationType
    exportRunId
    exportStatus
    importedCurrencyTotalPrice
    importedCurrencyType
    inventoryId
    listingId
    logisticsCost
    logisticsRelationshipName
    logisticsTerm
    logisticsTime
    negotiationListingId
    negotiationStatus
    offerId
    originalQuantity
    originalTotalPrice
    poNumber
    postAwardUpdates {
      awardedTotalPrice
      awardedQuantity
      buyerUpdatedTotalPrice
      buyerUpdatedQuantity
      status
    }
    quantity
    requestedDeliveryDate
    reserveAnchor
    reserveNumber
    status
    sellerLocationId
    tags
    totalPrice
    transactionCycleId
    transactionLocationId
    weightUnit
    suggestedUnitPrice
    trucklaneDistance
    trucklaneCost
    truckType
    suggestions {
      award {
        suggested
        quantity
      }
      negotiation {
        unitPrice
        recommended
        reason
      }
    }
    ftpImportUsername
    activeTruckLane {
      _id
    }
    isVendorOfRecord
  }
`;
