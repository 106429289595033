import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import {
  Theme,
  MarketingOfferShiftArrowsIcon,
  MarketingPartialOfferShiftArrowsIcon,
  InformationIcon,
  SideDropArrowIcon,
  ActiveLaneIcon,
  DotIcon,
  ExclamationIcon,
  PickupIcon,
  DeliveryIcon,
  DryIcon,
  RefrigeratedIcon,
  FrozenIcon,
  BarChartIncreaseIcon,
  TruckSimpleIcon,
} from '@spoiler-alert/ui-library';
import accounting from 'accounting';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import weightTypes from '../../enums/weight-types';

const useStyles = createUseStyles({
  container: {
    overflowY: 'auto',
    padding: '24px',
  },
  row: {
    display: 'flex',
  },
  spacedRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  spacedMarginRow: {
    extend: 'spacedRow',
    marginBottom: 24,
  },
  wrappedRow: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 12,
  },
  logisticsRow: {
    extend: 'row',
    columnGap: 16,
    marginTop: 12,
    marginBottom: 16,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 24,
  },
  header: {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: 24,
  },
  opportunityText: {
    fontSize: '12px',
    fontWeight: 500,
    color: Theme.green,
  },
  informationBanner: {
    backgroundColor: Theme.teal5,
    display: 'flex',
    padding: 12,
    marginBottom: 24,
  },
  informationText: {
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '1.43',
    letterSpacing: '-0.08px',
    alignContent: 'center',
  },
  card: {
    border: `1px ${Theme.borderColor} solid`,
    borderRadius: 4,
    padding: 16,
  },
  marginCard: {
    extend: 'card',
    marginBottom: 24,
  },
  cardHeader: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
    marginBottom: 0,
  },
  cardMarginHeader: {
    extend: 'cardHeader',
    marginBottom: 24,
  },
  tableHeader: {
    color: Theme.tableHeaderTextColor,
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: '500',
  },
  tableText: {
    fontSize: '14px',
  },
  chartIncreaseIcon: {
    height: 16,
    width: 16,
    fill: Theme.green,
    marginRight: 8,
  },
  infoIcon: {
    width: 32,
    fill: Theme.infoColor,
    marginRight: 8,
    marginBottom: 'auto',
  },
  activeLane: {
    border: `1px solid ${Theme.primaryColor}`,
    backgroundColor: Theme.green5,
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      color: Theme.primaryColor,
      width: 'fit-content',
      padding: '2px 8px 2px 0px',
      marginBottom: 0,
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '0.75rem',
      whiteSpace: 'nowrap',
    },
    '& svg': {
      stroke: Theme.primaryColor,
      fill: 'transparent',
      width: 20,
      height: 20,
      marginLeft: 8,
      marginRight: 6,
    },
  },
  inactiveLane: {
    extend: 'activeLane',
    border: `1px solid ${Theme.grey80}`,
    backgroundColor: Theme.grey5,
    '& span': {
      color: Theme.textColorPrimary,
    },
    '& svg': {
      stroke: 'none',
    },
  },
  marginBreak: {
    marginBottom: 12,
  },
  awardButton: {
    width: '-webkit-fill-available',
    marginTop: 12,
  },
  ruleSet: {
    extend: 'spacedRow',
    padding: 12,
    '&:first-child': {
      padding: '0px 12px 12px 12px',
    },
    '&:last-child': {
      padding: '12px 12px 0px 12px',
    },
    '&:not(:last-child)': {
      borderBottom: `1px solid ${Theme.borderColor}`,
    },
    '& span': {
      extend: 'informationText',
    },
    '& p': {
      extend: 'informationText',
      color: Theme.grey80,
      marginBottom: 0,
      marginRight: 24,
    },
  },
  ruleIndicator: {
    display: 'flex',
    height: 'fit-content',
    alignItems: 'center',
    padding: '4px 8px',
  },
  logisticsIcon: {
    height: 16,
    width: 16,
    fill: Theme.grey,
    marginRight: 4,
  },
  overviewLogistics: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      margin: 0,
      fontSize: '16px',
      color: Theme.grey,
      fontWeight: 'normal',
    },
  },
  summaryTable: {
    backgroundColor: Theme.white,
    padding: 12,
    margin: 16,
    border: `1px solid ${Theme.borderColor}`,
    borderRadius: 2,
    width: 450,
    minHeight: 250,
    display: 'flex',
    flexDirection: 'column',
    '& h1': {
      fontSize: '14px',
      fontWeight: '500',
      color: Theme.grey,
    },
  },
  summaryTableRow: {
    display: 'flex',
    padding: '2px',
    borderTop: `1px solid ${Theme.grey10}`,
    '&:first-child': {
      borderTop: 'none',
    },
  },
  summaryTableCell: {
    width: '100px',
  },
  summaryTableHeader: {
    width: '100px',
    color: Theme.grey,
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '8px 0px',
  },
  summaryTableInfo: {
    width: '100px',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '8px 0px',
  },
});

const shiftType = {
  PARTIAL: 'PARTIAL',
  FULL: 'FULL',
};

const OfferReviewShiftDetail = ({ offer, shiftedOffer, trucklane }) => {
  const classes = useStyles();

  const truckTypeIcons = {
    Dry: DryIcon,
    Refrigerated: RefrigeratedIcon,
    Frozen: FrozenIcon,
  };

  const TruckIcon = truckTypeIcons[shiftedOffer.truckType] || DryIcon;

  const shiftInfoText =
    shiftedOffer.shiftType === shiftType.PARTIAL
      ? 'Shift the unallocated quantity from the original offer to the leftover inventory. The original inventory and the leftover inventory are identical aside from the best by date or origin.'
      : 'Shift the unawarded original offer to the leftover inventory. The original inventory and the leftover inventory are identical aside from the best by date or origin.';

  const shiftArrowIcon =
    shiftedOffer.shiftType === shiftType.PARTIAL ? (
      <MarketingPartialOfferShiftArrowsIcon style={{ margin: '2px 0px' }} />
    ) : (
      <MarketingOfferShiftArrowsIcon style={{ margin: '2px 0px' }} />
    );

  const generateBBD = (BBD) => `${moment(BBD).format('MM/DD/YYYY')} (${moment(BBD).startOf('day').diff(moment().startOf('day'), 'days')} days)`;

  const ogInventory = [
    { header: 'SKU', value: offer.itemName, key: uuidv4() },
    { header: 'DESCRIPTION', value: offer.itemDescription, key: uuidv4() },
    { header: 'BEST BY', value: generateBBD(offer.bestByDate), key: uuidv4() },
    { header: 'SITE NAME', value: offer.sellerLocationName, key: uuidv4() },
    { header: 'STATUS', value: offer.inventoryStatus, key: uuidv4() },
  ];

  const ogOffer = [
    { header: 'BID QTY', value: offer.buyerProposedQuantity, key: uuidv4() },
    { header: 'BID PRICE', value: accounting.formatMoney(offer.unitPrice), key: uuidv4() },
    { header: 'TOTAL BID', value: accounting.formatMoney(offer.unitPrice * offer.quantity), key: uuidv4() },
    { header: 'DESTINATION', value: trucklane.buyerDestinationName, key: uuidv4() },
    { header: 'STATUS', value: 'Unawarded', key: uuidv4() },
  ];

  const leftoverInv = [
    { header: 'SKU', value: offer.itemName, key: uuidv4() },
    { header: 'DESCRIPTION', value: offer.itemDescription, key: uuidv4() },
    { header: 'BEST BY', value: generateBBD(shiftedOffer.bestByDate), key: uuidv4() },
    { header: 'SITE NAME', value: trucklane.shiftSellerLocationName, key: uuidv4() },
    { header: 'AVAILABLE QTY', value: shiftedOffer.inventoryQuantity, key: uuidv4() },
    { header: 'QTY TO SHIFT', value: shiftedOffer.shiftedQuantity, key: uuidv4() },
    { header: 'STATUS', value: shiftedOffer.inventoryStatus, key: uuidv4() },
  ];

  const generateTable = (tableData) => {
    return (
      <div className={classes.wrappedRow}>
        {tableData.map((data) => {
          return (
            <div className={classes.column} key={data.key}>
              <span className={classes.tableHeader}>{data.header}</span>
              <span className={classes.tableText}>{data.value}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const ruleSetIndicator = (ruleMet) => {
    return (
      <div
        className={classes.ruleIndicator}
        style={{ backgroundColor: ruleMet ? Theme.green5 : Theme.orange5, color: ruleMet ? Theme.green : Theme.orange }}
      >
        {ruleMet ? <DotIcon style={{ marginRight: 8 }} /> : <ExclamationIcon style={{ transform: 'translateY(-1px)', marginRight: 8 }} />}
        {ruleMet ? 'YES' : 'NO'}
      </div>
    );
  };

  const displayLogisticsTerm = () => {
    const truckType = (
      <div className={classes.overviewLogistics}>
        <TruckIcon className={classes.logisticsIcon} />
        <p>{trucklane.truckType}</p>
      </div>
    );

    return trucklane.logisticsTerm === 'Pickup' ? (
      <div className={classes.logisticsRow}>
        {truckType}
        <div className={classes.overviewLogistics}>
          <PickupIcon className={classes.logisticsIcon} />
          <p>{trucklane.logisticsTerm}</p>
        </div>
      </div>
    ) : (
      <div className={classes.logisticsRow}>
        {truckType}
        <div className={classes.overviewLogistics}>
          <DeliveryIcon className={classes.logisticsIcon} />
          <p>
            {trucklane.logisticsTerm} - {accounting.formatNumber(trucklane.trucklaneDistance)} miles
          </p>
        </div>
      </div>
    );
  };

  const shiftedInventoryMetrics = () => {
    const acceptedShift = shiftedOffer.status === 'ACCEPTED';

    const weight = [
      'WEIGHT',
      trucklane.awardedWeight ? `${accounting.formatNumber(trucklane.awardedWeight)}  ${weightTypes.LB}` : '-',
      acceptedShift ? '-' : `${accounting.formatNumber(shiftedOffer.shiftedQuantity * shiftedOffer.unitGrossWeight)}  ${weightTypes.LB}`,
      acceptedShift
        ? `${accounting.formatNumber(trucklane.awardedWeight)} ${weightTypes.LB}`
        : `${accounting.formatNumber(shiftedOffer.shiftedQuantity * shiftedOffer.unitGrossWeight + trucklane.awardedWeight)}  ${weightTypes.LB}`,
    ];

    const pallets = [
      'PALLET',
      trucklane.awardedPallets ? accounting.formatNumber(trucklane.awardedPallets) : '-',
      acceptedShift ? 0 : accounting.formatNumber(shiftedOffer.shiftedQuantity / (shiftedOffer.casesPerPallet || 1)),
      acceptedShift
        ? accounting.formatNumber(trucklane.awardedPallets)
        : accounting.formatNumber(shiftedOffer.shiftedQuantity / (shiftedOffer.casesPerPallet || 1) + trucklane.awardedPallets),
    ];

    const revenue = [
      'REVENUE',
      trucklane.awardedRevenue ? accounting.formatMoney(trucklane.awardedRevenue) : '-',
      acceptedShift ? 0 : accounting.formatMoney(shiftedOffer.shiftedQuantity * shiftedOffer.unitPrice),
      acceptedShift
        ? accounting.formatMoney(trucklane.awardedRevenue)
        : accounting.formatMoney(shiftedOffer.shiftedQuantity * shiftedOffer.unitPrice + trucklane.awardedRevenue),
    ];

    const resultTable = [weight, pallets, revenue];

    return (
      <div>
        <div className={classes.summaryTableRow}>
          <span className={classes.summaryTableCell}></span>
          <span className={classes.summaryTableHeader}>CURRENTLY</span>
          <span className={classes.summaryTableHeader}>THIS OFFER</span>
          <span className={classes.summaryTableHeader}>TOTAL</span>
        </div>
        {resultTable.map((metric) => (
          <div key={metric[0]} className={classes.summaryTableRow}>
            <span className={classes.summaryTableHeader}>{metric[0]}</span>
            <span className={classes.summaryTableInfo}>{metric[1]}</span>
            <span className={classes.summaryTableInfo}>{metric[2]}</span>
            <span className={classes.summaryTableInfo}>{metric[3]}</span>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className={classes.container}>
      <div className={classes.spacedRow}>
        <span className={classes.header}>Offer Shift Details</span>
        <div className={classes.row}>
          <BarChartIncreaseIcon className={classes.chartIncreaseIcon} />
          <span className={classes.opportunityText}>{accounting.formatMoney(shiftedOffer.shiftedQuantity * shiftedOffer.unitPrice)} OPPORTUNITY</span>
        </div>
      </div>
      <div className={classes.informationBanner}>
        <InformationIcon className={classes.infoIcon} style={{ transform: 'translateY(5px)' }} />
        <span className={classes.informationText}>{shiftInfoText}</span>
      </div>
      <div className={classes.marginCard}>
        <h1 className={classes.cardMarginHeader}>ORIGINAL INVENTORY</h1>
        {generateTable(ogInventory)}
      </div>
      <div className={classes.card}>
        <h1 className={classes.cardMarginHeader}>ORIGINAL OFFER</h1>
        {generateTable(ogOffer)}
      </div>
      {shiftArrowIcon}
      <div className={classes.marginCard}>
        <div className={classes.spacedMarginRow}>
          <h1 className={classes.cardHeader}>LEFTOVER INVENTORY</h1>
        </div>
        {generateTable(leftoverInv)}
        <div className={classes.marginBreak} />
        <div className={classes.card}>
          <div>
            <div className={classes.spacedRow}>
              <span className={classes.informationText}>{trucklane.shiftSellerLocationName}</span>
              {trucklane.awardedWeight > 0 || shiftedOffer.status === 'ACCEPTED' ? (
                <div className={classes.activeLane}>
                  <ActiveLaneIcon /> <span>ACTIVE TRUCK LANE</span>
                </div>
              ) : (
                <div className={classes.inactiveLane}>
                  <TruckSimpleIcon style={{ transform: 'scaleX(-1)' }} /> <span>INACTIVE TRUCK LANE</span>
                </div>
              )}
            </div>
            <div className={classes.row}>
              <SideDropArrowIcon />
              <span className={classes.informationText}>{trucklane.buyerDestinationName}</span>
            </div>
          </div>
          {displayLogisticsTerm()}
          {shiftedInventoryMetrics()}
        </div>
      </div>
      <div className={classes.marginCard}>
        <div className={classes.ruleSet}>
          <span>Original offer is above reserve price?</span>
          {ruleSetIndicator(shiftedOffer.aboveReservePrice)}
        </div>
        <div className={classes.ruleSet}>
          <div className={classes.column}>
            <span>Best by date of original inventory is acceptable ?</span>
            <p>
              Buyers are generally ok when the best by date of the inventory an offer is shifted to is greater than 90%, or more than 180 days of the
              original inventory. If the best by date is less than 90% of the original inventory we recommend getting buyer approval.
            </p>
          </div>
          {ruleSetIndicator(shiftedOffer.lowRiskBBD)}
        </div>
        <div className={classes.ruleSet}>
          <span>Leftover inventory has the same origin DC as the original inventory ?</span>
          {ruleSetIndicator(shiftedOffer.sameDC)}
        </div>
      </div>
    </div>
  );
};

OfferReviewShiftDetail.propTypes = {
  user: PropTypes.object,
  offer: PropTypes.object,
  shiftedOffer: PropTypes.object,
  trucklane: PropTypes.object,
};

export default OfferReviewShiftDetail;
