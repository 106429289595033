import React from 'react';
import { createUseStyles } from 'react-jss';
import SuggestionsBanner from './suggestion-banner';
import PropTypes from 'prop-types';
import routePaths from '../../route-paths';

const useStyles = createUseStyles({
  block: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '-0.198px',
    marginBottom: 0,
  },
});

const RecommendedNegotiationsMoved = ({ history }) => {
  const classes = useStyles();
  const goToSuggestedNegotiations = () => history.push(routePaths.suggestedNegotiations);
  const actionButton = {
    onClick: goToSuggestedNegotiations,
    text: 'View Negotiation Suggestions',
  };
  const bannerText = (
    <div className={classes.block}>
      <div className={classes.header}>Bulk negotiation has moved to our new Negotiation Suggestions page!</div>
    </div>
  );
  return <SuggestionsBanner actionButton={actionButton}>{bannerText}</SuggestionsBanner>;
};

RecommendedNegotiationsMoved.propTypes = {
  history: PropTypes.object,
};

export default RecommendedNegotiationsMoved;
