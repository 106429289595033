import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import {
  ActiveLaneIcon,
  CheckCircleIcon,
  ChevronIcon,
  SideDropArrowIcon,
  Theme,
  TruckSimpleIcon,
  ExclamationCircleIcon,
} from '@spoiler-alert/ui-library';
import accounting from 'accounting';
import { TRUCKLANE_MINIMUM_MODE } from '../org-settings/smart-award-settings-input';
import OfferReviewShiftCard from './offer-review-shift-card';

const useStyles = createUseStyles({
  container: {
    overflowY: 'auto',
    paddingLeft: '31px',
    '&:not(:last-child)': {
      marginBottom: '16px',
    },
  },
  rows: {
    display: 'flex',
  },
  columns: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacedRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  centeredRow: {
    display: 'flex',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  border: {
    border: `1px ${Theme.borderColor} solid`,
    borderRadius: '4px',
    padding: '12px',
  },
  informationText: {
    fontSize: '16px',
    fontWeight: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.176px',
    alignContent: 'center',
  },
  expandCard: {
    cursor: 'pointer',
  },
  activeLane: {
    border: `1px solid ${Theme.primaryColor}`,
    backgroundColor: Theme.green5,
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      color: Theme.primaryColor,
      width: 'fit-content',
      padding: '2px 8px 2px 0px',
      marginBottom: 0,
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '0.75rem',
      whiteSpace: 'nowrap',
    },
    '& svg': {
      stroke: Theme.primaryColor,
      fill: 'transparent',
      width: 20,
      height: 20,
      marginLeft: 8,
      marginRight: 6,
    },
  },
  inactiveLane: {
    extend: 'activeLane',
    border: `1px solid ${Theme.grey80}`,
    backgroundColor: Theme.grey5,
    '& span': {
      color: Theme.textColorPrimary,
    },
    '& svg': {
      stroke: 'none',
    },
  },
  arrow: {
    marginTop: 18,
    height: 8,
    width: 16,
    stroke: Theme.textColorPrimary,
    marginRight: 8,
    transition: 'transform 0.3s',
  },
  offerTable: {
    borderCollapse: 'separate',
    textAlign: 'left',
    '& th': {
      paddingRight: '24px',
      color: Theme.tableHeaderTextColor,
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: '12px',
    },
    '& td': {
      paddingRight: '24px',
      fontSize: '14px',
    },
  },
  tableTitle: {
    color: Theme.green,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    marginLeft: '2px',
  },
  divider: {
    marginTop: '12px',
  },
  revenueIcon: {
    height: '14px',
    width: '14px',
    marginRight: '8px',
  },
  trucklaneInfo: {
    extend: 'columns',
    '& .trucklaneMinimumTitle': {
      fontSize: '18px',
      fontWeight: 'normal',
      fontWeight: 400,
      lineHeight: '26px',
      letterSpacing: '-0.198px',
      alignContent: 'center',
      textAlign: 'end',
    },
    '& .trucklaneMode': {
      extend: 'tableTitle',
      textAlign: 'end',
      textTransform: 'uppercase',
    },
    '& .trucklaneMinimumInfo': {
      extend: 'trucklaneMode',
      color: Theme.grey80,
    },
  },
});

const OfferReviewTrucklaneCard = ({ user, trucklane, buyerName, setShowActivatedLaneBanner }) => {
  const classes = useStyles();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const isTrucklaneActive = trucklane.awardedWeight > 0;

  const calculateTotals = (tl, statuses) => {
    return tl.originalOfferListings.reduce(
      (acc, ol) => {
        const offerData = ol.offerShifts.reduce(
          (olAcc, olCurrent) => {
            if (statuses.includes(olCurrent.status))
              return {
                weight: olAcc.weight + olCurrent.unitGrossWeight * olCurrent.shiftedQuantity,
                pallets: olAcc.pallets + olCurrent.shiftedQuantity / olCurrent.casesPerPallet,
                totalCost: olAcc.totalCost + olCurrent.unitCost * olCurrent.shiftedQuantity,
                totalPrice: olAcc.totalPrice + olCurrent.unitPrice * olCurrent.shiftedQuantity,
              };
            return olAcc;
          },
          { weight: 0, pallets: 0, totalCost: 0, totalPrice: 0 }
        );
        return {
          weight: acc.weight + offerData.weight,
          pallets: acc.pallets + offerData.pallets,
          totalCost: acc.totalCost + offerData.totalCost,
          totalPrice: acc.totalPrice + offerData.totalPrice,
        };
      },
      { weight: 0, pallets: 0, totalCost: 0, totalPrice: 0 }
    );
  };

  const opportunityTableData = useMemo(() => {
    const { weight, pallets, totalCost, totalPrice } = calculateTotals(trucklane, ['PROPOSED']);

    const costRecovery = (totalPrice / totalCost) * 100;
    return [
      { header: 'Weight', value: weight ? `${accounting.formatNumber(weight, 2)} lbs` : '0 lbs', key: 'Opportunity' },
      { header: 'Pallets', value: pallets ? accounting.formatNumber(pallets) : '0', key: 'Opportunity' },
      { header: 'Revenue', value: totalPrice ? accounting.formatMoney(totalPrice) : '$0.00', key: 'Opportunity' },
      { header: 'Recovery', value: costRecovery ? `${accounting.formatNumber(costRecovery, 2)}%` : '0%', key: 'Opportunity' },
    ];
  }, [trucklane]);

  const awardedTableData = useMemo(() => {
    const { weight, pallets, totalCost, totalPrice } = calculateTotals(trucklane, ['ACCEPTED']);

    trucklane.awardedWeight += weight;
    trucklane.awardedPallets += pallets;
    trucklane.awardedTotalPrice += totalPrice;
    trucklane.awardedTotalCost += totalCost;
    trucklane.awardedRevenue += totalPrice;
    trucklane.costRecovery = (trucklane.awardedTotalPrice / trucklane.awardedTotalCost) * 100;

    return [
      { header: 'Weight', value: trucklane.awardedWeight ? `${accounting.formatNumber(trucklane.awardedWeight, 2)} lbs` : '0 lbs', key: 'Awarded' },
      { header: 'Pallets', value: trucklane.awardedPallets ? accounting.formatNumber(trucklane.awardedPallets) : '0', key: 'Awarded' },
      { header: 'Recovery', value: trucklane.costRecovery ? `${accounting.formatNumber(trucklane.costRecovery, 2)}%` : '0%', key: 'Awarded' },
    ];
  }, [trucklane]);

  const trucklaneTableGenerator = useCallback(
    (tableData) => {
      const tableHeaders = [];
      const tableValues = [];

      tableData.forEach((data) => {
        tableHeaders.push(<th key={`${data.header}-${data.key}`}>{data.header}</th>);
        tableValues.push(<td key={`${data.header}-value-${data.key}`}>{data.value}</td>);
      });

      return (
        <table className={classes.offerTable}>
          <thead>
            <tr>{tableHeaders}</tr>
          </thead>
          <tbody>
            <tr>{tableValues}</tr>
          </tbody>
        </table>
      );
    },
    [classes.offerTable]
  );

  const getTrucklaneMode = () => {
    const trucklaneMinimum = user?.site?.trucklaneMinimums[trucklane.logisticsTerm.toLowerCase()][trucklane.truckType.toLowerCase()] || 0;

    let trucklaneMode = 'Trucklane Settings Not Set';
    if (user?.site?.trucklaneMinimums?.mode === TRUCKLANE_MINIMUM_MODE.NET_REVENUE) trucklaneMode = 'Awarded Net Revenue';
    if (user?.site?.trucklaneMinimums?.mode === TRUCKLANE_MINIMUM_MODE.TOTAL_REVENUE) trucklaneMode = 'Awarded Revenue';

    const variable = isTrucklaneActive ? trucklane.awardedRevenue : 0;
    return (
      <div className={classes.trucklaneInfo}>
        <span className="trucklaneMinimumTitle">{`${accounting.formatMoney(variable)}`}</span>
        <span className="trucklaneMode">{trucklaneMode}</span>
        <div className={classes.centeredRow}>
          {trucklane.awardedRevenue >= trucklaneMinimum ? (
            <CheckCircleIcon className={classes.revenueIcon} />
          ) : (
            <ExclamationCircleIcon className={classes.revenueIcon} />
          )}
          <span className="trucklaneMinimumInfo">{`${accounting.formatMoney(trucklaneMinimum)} MINIMUM`}</span>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.border}>
        <div className={classes.rows}>
          <div className={classes.expandCard} onClick={() => setShowSuggestions(!showSuggestions)}>
            <ChevronIcon className={classes.arrow} direction={showSuggestions ? 'up' : 'down'} />
          </div>
          <div className={classes.fullWidth}>
            <div>
              <div className={classes.spacedRow}>
                <span className={classes.informationText}>{trucklane.shiftSellerLocationName}</span>
                {isTrucklaneActive ? (
                  <div className={classes.activeLane}>
                    <ActiveLaneIcon /> <span>ACTIVE TRUCK LANE</span>
                  </div>
                ) : (
                  <div className={classes.inactiveLane}>
                    <TruckSimpleIcon style={{ transform: 'scaleX(-1)' }} /> <span>INACTIVE TRUCK LANE</span>
                  </div>
                )}
              </div>
              <div>
                <SideDropArrowIcon style={{ marginRight: '8px' }} />
                <span className={classes.informationText}>{trucklane.buyerDestinationName}</span>
              </div>
            </div>
            <div className={classes.divider} />
            <div className={classes.spacedRow}>
              <div className={classes.rows}>
                <div>
                  <span className={classes.tableTitle}>OPPORTUNITY</span>
                  {trucklaneTableGenerator(opportunityTableData)}
                </div>
                <div>
                  <span className={classes.tableTitle}>CURRENTLY AWARDED</span>
                  {trucklaneTableGenerator(awardedTableData)}
                </div>
              </div>
              {getTrucklaneMode()}
            </div>
          </div>
        </div>
        {showSuggestions && (
          <div>
            <div className={classes.divider} />
            {trucklane.originalOfferListings.map((shiftedOffer) => {
              return (
                <OfferReviewShiftCard
                  key={shiftedOffer._id}
                  offer={shiftedOffer}
                  buyerName={buyerName}
                  trucklane={trucklane}
                  setShowActivatedLaneBanner={setShowActivatedLaneBanner}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

OfferReviewTrucklaneCard.propTypes = {
  user: PropTypes.object,
  trucklane: PropTypes.object,
  buyerName: PropTypes.string,
  setShowActivatedLaneBanner: PropTypes.func,
};

export default OfferReviewTrucklaneCard;
