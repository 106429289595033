import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { graphql } from '@apollo/client/react/hoc';
import pluralize from 'pluralize';
import {
  DataTableNaked,
  RowAction,
  ClearListIcon,
  CreateListingsIcon,
  AlertService,
  compose,
  ColorBadge,
  Column,
  Theme,
  SpoilerAlertAppleIcon,
} from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import { StagedNegotiationSummaryQuery, AwardSummaryQuery } from '../../graphql/queries';
import { clearNegotiationStagedListings, publishNegotiationListings } from '../../graphql/mutations';
import deleteCachedFieldsOnUserQuery from '../../apollo/cache-helpers/delete-cached-fields-on-user-query';
import routePaths from '../../route-paths';
import { Breadcrumbs } from '../../store';

const CYPRESS_TAGS = {
  PUBLISH_NEGOTIATIONS_ROW_ACTION_BUTTON: 'publish-negotiation-row-action-button',
  CLEAR_NEGOTIATIONS_ROW_ACTION_BUTTON: 'clear-negotiation-row-action-button',
  TABLE: 'negotiations-table',
};

const styles = {
  negotiations_table: {
    border: '1px #D1D6DA solid',
    borderRadius: 2,
    marginTop: 1,
  },
  listNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
  },
  spoilerAlertApple: {
    width: 16,
    height: 18,
  },
};

export class Negotiations extends Component {
  static propTypes = {
    user: PropTypes.object,
    classes: PropTypes.object,
    data: PropTypes.object,
    match: PropTypes.object,
    clearNegotiationStagedListings: PropTypes.func,
    publishNegotiationListings: PropTypes.func,
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  state = {
    publishing: false,
    resetting: false,
    actionOnRows: [],
  };

  constructor(props) {
    super(props);
    TitleService.setTitles('Negotiations');
    Breadcrumbs.set([
      {
        url: props.match.url,
        title: 'Negotiations',
      },
    ]);
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.data && !nextProps.data.loading) {
      const { StagedNegotiationSummaryQuery } = nextProps.data;
      return {
        stagedNegotiationSummary: StagedNegotiationSummaryQuery,
      };
    }
    return {};
  }

  handleRowClick = (row) => {
    this.context.router.history.push(`${routePaths.negotiations}/${row._id}/${row.buyerSiteName}`);
  };

  addToActionOnRowIds(rowId, action) {
    const newRows = [...this.state.actionOnRows];
    newRows.push({ rowId, action });
    return newRows;
  }
  removeFromActionOnRowIds(rowId) {
    const newRows = [...this.state.actionOnRows];
    const idx = newRows.findIndex((o) => o.rowId === rowId);
    newRows.splice(idx, 1);
    return newRows;
  }

  get rowActions() {
    const { actionOnRows } = this.state;
    const { data } = this.props;
    const resettingRows = [];
    const publishingRows = [];
    const activeRows = [];
    actionOnRows.forEach((r) => {
      if (r.action === 'published') {
        activeRows.push(r.rowId);
      } else if (r.action === 'reset') {
        resettingRows.push(r.rowId);
      } else {
        publishingRows.push(r.rowId);
      }
      activeRows.push(r.rowId);
    });
    if (!data.loading) {
      data?.StagedNegotiationSummaryQuery.forEach((row) => {
        if (row.status === 'PUBLISHED') {
          activeRows.push(row._id);
        }
      });
    }

    return [
      <RowAction
        key={1}
        tooltipText="Clear Negotiations"
        loadingTooltipText="Clearing Negotiations"
        icon={ClearListIcon}
        onClick={(row) => this.handleClearAll.bind(this, row)}
        warning
        loadingRows={resettingRows}
        disabledRows={activeRows}
        cypressTag={CYPRESS_TAGS.CLEAR_NEGOTIATIONS_ROW_ACTION_BUTTON}
      />,
      <RowAction
        key={2}
        tooltipText="Publish Negotiations"
        loadingTooltipText="Publishing Negotiations"
        icon={CreateListingsIcon}
        onClick={(row) => this.handlePublish.bind(this, row)}
        loadingRows={publishingRows}
        disabledRows={activeRows}
        cypressTag={CYPRESS_TAGS.PUBLISH_NEGOTIATIONS_ROW_ACTION_BUTTON}
      />,
    ];
  }

  handlePublish = (row, e) => {
    e.stopPropagation();
    const actionOnRows = this.addToActionOnRowIds(row._id, 'publish');
    this.setState({ publishing: true, actionOnRows });
    this.props
      .publishNegotiationListings({
        variables: { buyerSiteIds: [row._id] },
        refetchQueries: [{ query: AwardSummaryQuery }, { query: StagedNegotiationSummaryQuery }],
      })
      .then((response) => {
        const result = response.data.publishNegotiationListings;
        if (result.negotiationStagedListingsCount === 0 || result.errors.length > 0) throw new Error(result.errors.join(', '));
        this.showSuccess(
          `You have published ${result.negotiationStagedListingsCount} ${pluralize('negotiation', result.negotiationStagedListingsCount)} to ${
            row.buyerSiteName
          }.`,
          row._id
        );
      })
      .catch(() => this.showFailure('A problem occurred while publishing listings.'), row._id);
  };

  handleClearAll = (row, e) => {
    e.stopPropagation();

    const actionOnRows = this.addToActionOnRowIds(row._id, 'reset');
    this.setState({ resetting: true, actionOnRows });
    this.props
      .clearNegotiationStagedListings({
        variables: { buyerSiteIds: [row._id] },
        update: (cache) => deleteCachedFieldsOnUserQuery(cache, ['negotiationStagedListings', 'negotiationStagedInventoryFilterParameters']),
        refetchQueries: [{ query: StagedNegotiationSummaryQuery }],
      })
      .then((response) => {
        const result = response.data.clearNegotiationStagedListings;
        if (result.negotiationStagedListingsCount === 0 || result.errors.length > 0) throw new Error(result.errors.join(', '));
        this.showSuccess(
          `You have cleared ${result.negotiationStagedListingsCount} ${pluralize('negotiation', result.negotiationStagedListingsCount)} from ${
            row.buyerSiteName
          }.`,
          row._id
        );
      })
      .catch(() => this.showFailure('A problem occurred while clearing negotiations.', row._id));
  };

  showFailure = (message, rowId) => {
    const actionOnRows = this.removeFromActionOnRowIds(rowId);
    this.setState({ publishing: false, resetting: false, actionOnRows });
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  };

  showSuccess = (message, rowId) => {
    const actionOnRows = this.removeFromActionOnRowIds(rowId);
    this.setState({ publishing: false, resetting: false, actionOnRows });
    AlertService.alert({
      type: 'success',
      message: <span>{message}</span>,
      autoDismiss: true,
      dismissDelay: 3000,
    });
  };

  additionalColumn = new Column({
    field: 'status',
    displayName: 'Status',
    sortable: false,
    style: { padding: '11px 0' },
    formatter: (value) => {
      if (value === 'UNPUBLISHED') {
        return <ColorBadge theme={{ color: Theme.greyDark, backgroundColor: Theme.grey10 }}>UNPUBLISHED</ColorBadge>;
      } else {
        return <ColorBadge theme={Theme.badgeColors.green}>PUBLISHED</ColorBadge>;
      }
    },
  });

  columnOverrides = {
    buyerSiteName: {
      formatter: (_, row) => (
        <div className={this.props.classes.listNameContainer}>
          <span>{row.buyerSiteName}</span>
          {row.isVendorOfRecord && <SpoilerAlertAppleIcon className={this.props.classes.spoilerAlertApple} />}
        </div>
      ),
    },
  };

  render() {
    const { classes, user, data } = this.props;
    const rows = data.loading || !data ? [] : data.StagedNegotiationSummaryQuery;
    return (
      <div className={classes.negotiations_table}>
        <DataTableNaked
          data={rows}
          userId={this.props.user._id}
          filterable="none"
          filterParameters={{}}
          filters={[]}
          loading={data.loading}
          transition
          onRowClick={this.handleRowClick}
          columns={[...getColumnsFromDataTableProfile('Negotiations', user.site.dataTableProfiles, this.columnOverrides), this.additionalColumn]}
          rowActions={this.rowActions}
          sticky
          cypressTagTable={CYPRESS_TAGS.TABLE}
        />
      </div>
    );
  }
}

const StyledComponent = injectSheet(styles)(Negotiations);

export default compose(
  graphql(AwardSummaryQuery, { name: 'AwardSummaryQuery' }),
  graphql(StagedNegotiationSummaryQuery),
  graphql(clearNegotiationStagedListings, { name: 'clearNegotiationStagedListings' }),
  graphql(publishNegotiationListings, { name: 'publishNegotiationListings' })
)(StyledComponent);
