import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DataTableNaked, Checkbox, Column } from '@spoiler-alert/ui-library';
import { offerStatus, trucklaneStatusEnum, offerlistingStatuses } from '../../enums';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import { VTable } from './v-table';
import ScrollContext from '../../layouts/scroll-context';
import featureFlags from '../../enums/feature-flags';
import checkFeature from '../../helpers/check-feature-flag';

const CYPRESS_TAGS = {
  TABLE: 'offer-comparison-table',
};

const OfferComparisonTable = ({
  noDataMessage,
  handleRowClick,
  inventories,
  selectedInventory,
  searchText,
  filters,
  user,
  filteredInventoryIds,
  checkedInventoryIds = [],
  handleCheck,
  loading,
}) => {
  const rowsWithMetadata = useMemo(() => {
    return inventories.map((inv) => {
      let highestOfferPercentSuggestedPrice = null;
      let highestOfferTotalBidPrice = null;
      const highestOffer = inv.offerListings?.length > 0 ? [...inv.offerListings].sort((a, b) => b.totalPrice - a.totalPrice)[0] : null;
      if (highestOffer) {
        highestOfferTotalBidPrice = highestOffer.totalPrice;
        const unitPrice = highestOffer.totalPrice / highestOffer.quantity;
        highestOfferPercentSuggestedPrice = highestOffer.suggestedUnitPrice > 0 ? (unitPrice / highestOffer.suggestedUnitPrice) * 100 : null;
      }
      const row = {
        ...inv,
        highestOfferTotalBidPrice,
        highestOfferPercentSuggestedPrice,
      };
      return row;
    });
  }, [inventories]);

  const doesOfferStatusMatchFilter = (value, row) => {
    const noOffers = value === offerStatus.noOffers && row.offerListingCount === 0;
    const offersAwarded = value === offerStatus.offersAwarded && row.availableQuantity === 0;
    const offersAvailable = value === offerStatus.offersAvailable && row.offerListingCount > 0 && !(row.availableQuantity === 0);
    const partiallyAwarded =
      value === offerStatus.partiallyAwarded && row.offerListingCount > 0 && row.availableQuantity < row.onHandQuantity && row.availableQuantity > 0;
    return noOffers || partiallyAwarded || offersAwarded || offersAvailable;
  };

  const shouldIncludeInSiteFilter = (row) => filters.sites?.length === 0 || filters.sites?.some((site) => site.value === row.site._id);

  const shouldIncludeInStatusFilter = (row) =>
    filters.status.length === 0 || filters.status.some((status) => doesOfferStatusMatchFilter(status.value, row));

  const shouldIncludeInCategoryFilter = (row) =>
    filters.categories.length === 0 ||
    filters.categories.some((category) => {
      return category.value.toLowerCase() === row.foodType.toLowerCase() || category.value.toLowerCase() === row.foodSubtype.toLowerCase();
    });

  const shouldIncludeInTruckTypeFilter = (row) =>
    filters.truckTypes.length === 0 ||
    filters.truckTypes.some((truckType) => {
      return truckType.toLowerCase() === row.truckType.toLowerCase();
    });

  const shouldIncludeInNegotiationStatusFilter = (row) =>
    filters.negotiationStatus.length === 0 ||
    filters.negotiationStatus.some((negotiationStatusType) => {
      return row.offerListings?.some((offerListing) => offerListing.negotiationStatus?.toLowerCase() === negotiationStatusType.toLowerCase());
    });

  const shouldIncludeInLogisticsFilter = (row) =>
    filters.logistics.length === 0 ||
    filters.logistics.some((logisticsTerm) => {
      return row.offerListings?.some((offerListing) => offerListing.logisticsTerm?.toLowerCase() === logisticsTerm.toLowerCase());
    });

  const shouldIncludeInTrucklaneStatusFilter = (row) => {
    return (
      !filters.trucklaneStatus.length ||
      (filters.trucklaneStatus.includes(trucklaneStatusEnum.active) &&
        row.offerListings?.some((offerListing) => [offerlistingStatuses.ACCEPTED, offerlistingStatuses.AWARDED].includes(offerListing.status))) ||
      (filters.trucklaneStatus.includes(trucklaneStatusEnum.inactive) &&
        row.offerListings?.every((offerListing) => ![offerlistingStatuses.ACCEPTED, offerlistingStatuses.AWARDED].includes(offerListing.status)))
    );
  };

  const shouldIncludeInBusinessUnitFilter = (row) =>
    filters.businessUnits.length === 0 ||
    filters.businessUnits.some((businessUnit) => {
      return businessUnit.value.toLowerCase() === row.businessUnit.toLowerCase();
    });

  const shouldIncludeInTiedFilter = (row) => (filters.showTied === true ? Array.isArray(row.tags) && row.tags.includes('tied') : true);

  const shouldIncludeInPostAwardUpdatesFilter = (row) =>
    filters.postAwardUpdates === true
      ? row.offerListings?.some(
          (offerListing) =>
            offerListing.postAwardUpdates &&
            offerListing.postAwardUpdates.status === 'PENDING' &&
            offerListing.status === offerlistingStatuses.AWARDED
        )
      : true;

  const shouldIncludeInSuggestedAwardFilter = (row) =>
    filters.suggestedAward === true
      ? row.offerListings?.some((offerListing) => offerListing.suggestions?.award?.suggested && offerListing.status !== offerlistingStatuses.AWARDED)
      : true;

  const shouldIncludeInReservePriceFilter = (row) => {
    return filters.belowReservePrice === true
      ? Array.isArray(row.offerListings) && row.offerListings?.some((offerListing) => offerListing?.tags?.includes('below-reserve'))
      : true;
  };

  const shouldIncludeInInventoryTypeFilter = (row) =>
    filters.inventoryTypes.length === 0 ||
    filters.inventoryTypes.some((inventoryType) => {
      return inventoryType.value.toLowerCase() === row.inventoryType.toLowerCase();
    });

  const shouldIncludeInCodeDateFilter = (row) => {
    if (filters.codeDate.start !== undefined && filters.codeDate.end !== undefined) {
      return (
        new Date(row.bestByDate).toISOString() >= new Date(filters.codeDate.start).toISOString() &&
        new Date(row.bestByDate).toISOString() <= new Date(filters.codeDate.end).toISOString()
      );
    }
    return true;
  };

  const shouldIncludeInDistributionListFilter = (row) => {
    return (
      filters.distributionLists.length === 0 ||
      filters.distributionLists.some((distributionList) => {
        return row.distributionLists?.some((dl) => {
          return dl.listId === distributionList.value;
        });
      })
    );
  };

  const shouldIncludeInImportBatchFilter = (row) => {
    return filters.importBatches.length === 0 || filters.importBatches.map((b) => b.value).includes(row.importBatch);
  };

  const filteredListings = useMemo(() => {
    const rows = rowsWithMetadata.filter((row) => {
      return (
        shouldIncludeInSiteFilter(row) &&
        shouldIncludeInStatusFilter(row) &&
        shouldIncludeInCategoryFilter(row) &&
        shouldIncludeInBusinessUnitFilter(row) &&
        shouldIncludeInTiedFilter(row) &&
        shouldIncludeInReservePriceFilter(row) &&
        shouldIncludeInSuggestedAwardFilter(row) &&
        shouldIncludeInPostAwardUpdatesFilter(row) &&
        shouldIncludeInTruckTypeFilter(row) &&
        shouldIncludeInLogisticsFilter(row) &&
        shouldIncludeInNegotiationStatusFilter(row) &&
        shouldIncludeInInventoryTypeFilter(row) &&
        shouldIncludeInCodeDateFilter(row) &&
        shouldIncludeInTrucklaneStatusFilter(row) &&
        shouldIncludeInDistributionListFilter(row) &&
        shouldIncludeInImportBatchFilter(row)
      );
    });
    if (filteredInventoryIds) filteredInventoryIds.current = rows.map((row) => row._id);
    return rows;
  }, [filters, rowsWithMetadata]);

  const scrollContainer = useContext(ScrollContext);

  const showVtable = checkFeature(featureFlags.virtualizedDataTable);

  const columnOverrides = {
    offerPerCase: {
      width: 158,
      resizable: false,
    },
  };

  if (showVtable) {
    return (
      <VTable
        data={filteredListings}
        loading={loading}
        profileName="Offer Comp"
        columnOverrides={columnOverrides}
        userSite={user.site}
        checkboxes
        visibleRowsRef={filteredInventoryIds}
        onRowClick={handleRowClick}
        highlightRowId={selectedInventory?._id}
        searchText={searchText}
        noDataMessage={noDataMessage}
        keyboardNavigation
        cypressTagTable={CYPRESS_TAGS.TABLE}
        checkedRows={checkedInventoryIds}
        onRowCheck={handleCheck}
        scrollContainer={scrollContainer.current}
        sticky
        stickyOffset={45}
      />
    );
  }

  const checkboxColumn = () => {
    return new Column({
      field: '_id',
      displayName: '',
      sortable: false,
      formatter: (value) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Checkbox value={value} checked={Array.isArray(checkedInventoryIds) && checkedInventoryIds.includes(value)} onChecked={handleCheck} />
        </div>
      ),
    });
  };

  const columns = () => {
    const col = getColumnsFromDataTableProfile('Offer Comp', user.site.dataTableProfiles, {}, true);
    col.unshift(checkboxColumn());
    return col;
  };

  return (
    <DataTableNaked
      data={filteredListings}
      columns={columns()}
      onRowClick={handleRowClick}
      highlightRowId={selectedInventory?._id}
      searchText={searchText}
      noDataMessage={noDataMessage}
      sticky
      stickyOffset={45}
      keyboardNavigation
      cypressTagTable={CYPRESS_TAGS.TABLE}
      checkedRows={checkedInventoryIds}
    />
  );
};

OfferComparisonTable.propTypes = {
  user: PropTypes.object,
  inventories: PropTypes.array,
  noDataMessage: PropTypes.string,
  handleRowClick: PropTypes.func,
  searchText: PropTypes.string,
  filters: PropTypes.object,
  selectedInventory: PropTypes.object,
  filteredInventoryIds: PropTypes.object,
  checkedInventoryIds: PropTypes.array,
  handleCheck: PropTypes.func,
  loading: PropTypes.bool,
};

export default OfferComparisonTable;
