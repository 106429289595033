/* eslint-disable react/display-name */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import pluralize from 'pluralize';
import {
  GraphQLDataTable,
  Filter,
  Button,
  AlertService,
  Theme as theme,
  ClearListIcon,
  PublishListIcon,
  CreateListingsIcon,
  AdjustSuggestedPriceIcon,
  RowAction,
  Tooltip,
  InformationIconSmall,
  publishHistoryStatus,
  ButtonWithDisclosure,
  AlertWarningYellowIcon,
} from '@spoiler-alert/ui-library';
import accounting from 'accounting';
import { TitleService } from '../../services';
import SplitInventoryModal from '../../components/modals/split-inventory-modal';
import {
  UserStagedListingsQuery,
  StagedListingSummaryQuery,
  StagedListingSummaryWithSiteQuery,
  StagedListingSummaryByCatalogQuery,
} from '../../graphql/queries';
import { ClearStagedListings, RelistPublishedListings } from '../../graphql/mutations';
import SuggestedPriceModal from './suggested-price-modal';
import SuggestedPricingTextbox from './suggested-price-textbox';
import { getColumnsFromDataTableProfile, getDefaultSortFromDataTableProfile } from '../../components/data-table';
import { Breadcrumbs, UserFilters } from '../../store';
import dataExportService from '../../services/data-export-service';
import destinationType from '../../enums/staged-inventory-destination-type';
import { ArchiveStatus } from './archive-status-enum';
import PublishListModal from './publish-list-modal';
import checkFeature from '../../helpers/check-feature-flag';
import featureFlags from '../../enums/feature-flags';

const styles = {
  quantity: {
    textDecoration: `underline dashed ${theme.pillFontColor}`,
    cursor: 'pointer',
  },
  informationWrapper: {
    height: 24,
    width: 27,
    boxSizing: 'border-box',
    marginRight: -12,
    top: -3,
    position: 'relative',
    backgroundColor: theme.green30,
    borderRadius: '0% 50% 50% 0%',
    display: 'inline-block',
    marginLeft: 7,
    '&>svg': {
      height: '15px',
      width: '15px',
      cursor: 'pointer',
      fill: theme.greenDark,
      margin: '4px 0 0 6px',
    },
  },
  informationIconStyles: {
    height: '15px',
    width: '15px',
    cursor: 'pointer',
    fill: theme.greenDark,
    margin: '4px 0 0 6px',

    backgroundColor: theme.green30,
    borderRadius: '0% 50% 50% 0%',
  },
  alertIcon: {
    width: 20,
    height: 20,
  },
  alertIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: '16px',
  },
  disclaimer: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 4,
    padding: '10px 16px 10px 16px',
    backgroundColor: theme.yellow10,
    fontSize: 14,
    marginBottom: 9,
  },
};

const useStyles = createUseStyles(styles);

const CatalogDetail = ({ user, match, history }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [total, setTotal] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const [inventoryIdToSplit, setInventoryIdToSplit] = useState();
  const [showSplitModal, setShowSplitModal] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [showPublishListModal, setShowPublishListModal] = useState(false);
  const [actionOnRows, setActionOnRows] = useState([]);
  const [publishLoading, setPublishLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [relistLoading, setRelistLoading] = useState(false);
  const [tableDeletedList, setTableDeletedList] = useState([]);
  const [variables, setVariables] = useState({
    paginate: {
      pageNumber: 1,
      limit: 30,
      filter: {
        distributionLists: [],
        sortBy: getDefaultSortFromDataTableProfile('Staging Detail', user.site.dataTableProfiles),
      },
    },
  });
  const [inventoryFilters, setInventoryFilters] = useState([]);
  const [closeOnPromise, setCloseOnPromise] = useState(null);
  const actionRowsRef = useRef();

  useEffect(() => {
    actionRowsRef.current = actionOnRows;
  });

  const [clearStagedListings] = useMutation(ClearStagedListings);
  const [relistPublishedListings] = useMutation(RelistPublishedListings);
  const { data: listingSummary, loading, refetch } = useQuery(StagedListingSummaryQuery);

  const summary = listingSummary?.currentUserQuery.stagedListingSummary.find((dl) => dl._id === match.params.catalogId) || null;
  let channel = null;
  if (summary) {
    const { channel: tempChannel } = summary;
    channel = {
      ...tempChannel,
      _id: summary.distributionChannelId,
      catalogId: summary.catalogId,
      firstName: listingSummary?.currentUserQuery.firstName,
      lastName: listingSummary?.currentUserQuery.lastName,
    };
  }

  const pollingCatalogIds =
    listingSummary?.currentUserQuery?.stagedListingSummary
      .filter((x) => x.status === publishHistoryStatus.created || x.status === publishHistoryStatus.running)
      .map((y) => y._id) || [];
  const { startPolling, stopPolling } = useQuery(StagedListingSummaryByCatalogQuery, {
    variables: {
      catalogIds: pollingCatalogIds,
    },
    skip: pollingCatalogIds.length === 0,
  });

  useEffect(() => {
    if (pollingCatalogIds.length > 0) {
      startPolling(5000);
    }
    return stopPolling;
  }, [pollingCatalogIds]);

  const filterParameters = { catalogId: match.params.catalogId, distributionLists: [summary?.distributionChannelId] };

  // add tooltip to badge in breadcrumbs
  const formatPublishBadge = (stagedListingSummary) => {
    let color;
    let text;
    switch (stagedListingSummary?.status || publishHistoryStatus.created) {
      case publishHistoryStatus.completed:
        text = publishHistoryStatus.published;
        color = theme.badgeColors.green;
        break;
      case publishHistoryStatus.created:
        text = publishHistoryStatus.unpublished;
        color = theme.badgeColors.grey;
        break;
      case publishHistoryStatus.running:
      case publishHistoryStatus.failed:
      default:
        text = publishHistoryStatus.publishInProgress;
        color = theme.badgeColors.yellow;
        break;
    }
    return { text, color };
  };

  const TooltipWithIcon = () => (
    <Tooltip
      text={
        <span>
          <b>Published By:</b> <br></br> {channel.firstName} {channel.lastName}
        </span>
      }
      small
      bottom
    >
      <span className={classes.informationWrapper}>
        <InformationIconSmall style={styles.informationIconStyles}></InformationIconSmall>
      </span>
    </Tooltip>
  );

  useEffect(() => {
    const badge = formatPublishBadge(summary);
    TitleService.setTitles(`Staged Listings For ${channel?.name}`);
    Breadcrumbs.set([
      {
        url: '/staging',
        title: 'Staging',
      },
      {
        url: match.url,
        title: `Staged Listings for ${channel?.name || '...'}`,
        badge: {
          text: badge.text,
          colors: badge.color,
          tooltip: summary?.status === publishHistoryStatus.completed ? TooltipWithIcon : undefined,
        },
      },
    ]);
    const variablesValue = { ...variables };
    variablesValue.paginate.filter.distributionLists = [channel?._id];
    setVariables(variablesValue);
  }, [channel?._id]);

  const createInitialFilters = (userValue) => {
    if (inventoryFilters && inventoryFilters.length > 0) return inventoryFilters;
    const locationFilter = { displayName: 'Location', field: 'sites', queryField: 'sites', selectAll: true, filterType: 'search' };
    const defaultFilters = [
      { displayName: 'Created Date Range', filterType: 'daterange' },
      { displayName: 'Code Date Range', filterType: 'daterange', options: { startParam: 'bestByStartDate', endParam: 'bestByEndDate' } },
      { displayName: 'Category', field: 'categories', selectAll: true },
      { displayName: 'Handling', field: 'handlings', selectAll: true },
      { displayName: 'Truck Type', field: 'truckTypes', selectAll: true },
      { displayName: 'Pricing Strategies', field: 'pricingStrategies', selectAll: true },
      { displayName: 'Brand', field: 'brands', filterType: 'search', selectAll: true },
    ];
    if (userValue.privileges.canUserManageMultipleSites) defaultFilters.splice(1, 0, locationFilter);

    const storeFilters = UserFilters.filters.stagingDetail || [];
    const inventoryFiltersValue = defaultFilters.map((df) => new Filter({ ...df, ...storeFilters.find((sf) => sf?.displayName === df.displayName) }));
    setInventoryFilters(inventoryFiltersValue);
    return inventoryFiltersValue;
  };

  useEffect(() => {
    const filters = createInitialFilters(user);
    const filterState = filters.reduce((filtersObj, filter) => {
      if (filter.filterType === 'daterange') {
        filtersObj[filter.options?.startParam || 'startDate'] = filter.range?.start ? moment(filter.range.start) : undefined;
        filtersObj[filter.options?.endParam || 'endDate'] = filter.range?.end ? moment(filter.range?.end) : undefined;
      }

      if (filter.queryField && filter.values.length > 0) {
        filtersObj[filter.queryField] = filter.values.map((v) => v.value);
      }

      return filtersObj;
    }, {});

    const paginateFilter = { ...variables.paginate.filter, ...filterState };
    setVariables({ paginate: { ...variables.paginate, filter: paginateFilter } });
  }, []);

  const clearTableDeletedList = () => {
    setTableDeletedList([]);
  };

  const handleSelectChange = (selectedValue, totalValue, allSelectedValue) => {
    setSelected(selectedValue);
    setTotal(totalValue);
    setAllSelected(allSelectedValue);
  };

  const handleQueryChange = (variablesValue) => {
    setVariables({ ...variablesValue, ...filterParameters });
  };

  const refetchQueries = [{ query: StagedListingSummaryQuery }, { query: StagedListingSummaryWithSiteQuery }];

  const addToActionOnRows = (rowId, action) => {
    const newRows = [...actionRowsRef.current];
    newRows.push({ rowId, action });
    setActionOnRows(newRows);
  };

  const removeFromActionOnRows = (rowId) => {
    const newRows = [...actionRowsRef.current];
    const idx = newRows.findIndex((o) => o.rowId === rowId);
    if (idx > -1) newRows.splice(idx, 1);
    setActionOnRows(newRows);
  };

  const showSuccess = (message, rowId) => {
    removeFromActionOnRows(rowId);
    AlertService.alert({
      type: 'success',
      message: <span>{message}</span>,
      autoDismiss: true,
      dismissDelay: 3000,
    });
  };

  const showFailure = (message, rowId) => {
    removeFromActionOnRows(rowId);
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  };

  const showMessage = (message) => {
    setPublishLoading(false);
    AlertService.alert({
      type: 'info',
      message: <span>{message}</span>,
    });
  };

  const handleRemove = (row, e) => {
    e.stopPropagation();
    addToActionOnRows(row._id);
    clearStagedListings({
      variables: {
        stagedListingIds: [row.stagedListing._id],
        catalogId: match.params.catalogId,
        distributionChannelId: summary.distributionChannelId,
      },
      refetchQueries,
    })
      .then((response) => {
        const result = response.data.clearStagedListings;
        if (result.errors.length > 0) throw new Error(result.errors.map((err) => err.message).join(', '));
        setTableDeletedList([...tableDeletedList, row._id]);
        showSuccess(`You have cleared ${result.listingCount} ${pluralize('listing', result.listingCount)}.`, row._id);
      })
      .catch(() =>
        showFailure(
          'We were unable to clear the listings due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.',
          row._id
        )
      );
  };

  const rowActions =
    summary?.status === publishHistoryStatus.created
      ? [
          <RowAction
            key={1}
            tooltipText="Remove Item"
            loadingTooltipText="Removing Item"
            icon={ClearListIcon}
            onClick={(row) => handleRemove.bind(this, row)}
            warning
            loadingRows={actionOnRows}
            disabledRows={actionOnRows}
          />,
        ]
      : [];

  const handleClearAll = (e) => {
    e.stopPropagation();
    setResetLoading(true);
    clearStagedListings({
      variables: {
        catalogId: match.params.catalogId,
        distributionChannelId: summary.distributionChannelId,
      },
      refetchQueries,
      awaitRefetchQueries: true,
    })
      .then((response) => {
        const result = response.data.clearStagedListings;
        if (result.errors.length > 0) throw new Error(result.errors.map((err) => err.message).join(', '));
        history.push('/staging');
        showSuccess(`You have cleared ${result.listingCount} ${pluralize('listing', result.listingCount)} from ${channel.name}.`);
      })
      .catch(() =>
        showFailure(
          'We were unable to clear the listings due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.'
        )
      )
      .finally(() => setResetLoading(false));
  };

  const handlePublish = async (event, publishMessage, dueDate) => {
    event.preventDefault();
    setPublishLoading(true);
    const params = {
      catalogId: match.params.catalogId,
      distributionChannelId: summary.distributionChannelId,
      publishMessage,
      dueDate,
    };
    try {
      const promise = dataExportService.export('bid-sheet', params);
      setCloseOnPromise(promise);
      const response = await promise;
      const data = await response.json();
      setPublishLoading(false);
      setShowPublishListModal(false);
      if (data.errors.length > 0) {
        showFailure('A problem occurred while publishing listings.');
      } else if (data.exportStatus === 'BLOCKED') {
        showMessage(
          <span>
            The {channel.name} list is still publishing. When publishing is complete, you&rsquo;ll receive the offer sheet in your inbox. This could
            take several minutes.
            <br></br>
            Please wait until publishing is complete to try and publish again.
          </span>
        );
      } else {
        showMessage(
          `We've started publishing the ${channel.name} list. When publishing is complete, you'll receive the offer sheet in your inbox. This could take several minutes.`
        );
        await refetch();
        history.push('/staging');
      }
    } catch {
      showFailure('A problem occurred while publishing listings.');
    }
  };

  const handleRelist = (relistError) => {
    relistError.stopPropagation();
    setRelistLoading(true);
    relistPublishedListings({
      variables: {
        catalogId: match.params.catalogId,
      },
      refetchQueries,
    })
      .then((response) => {
        const result = response.data.RelistCatalog;
        if (result.errors.length > 0) throw new Error(result.errors.map((e) => e.message).join(', '));
        history.push('/staging');
        if (result.reducedInventoryCount === 0 && result.unavailableInventoryCount === 0) {
          showSuccess(`The ${channel.name} list has been relisted and no available quantities have changed.`);
        } else if (result.reducedInventoryCount > 0 || result.unavailableInventoryCount > 0) {
          showSuccess(
            `The ${channel.name} list has been relisted and some available quantities have changed. If there is no longer available quantity, the inventory has not been included.`
          );
        } else {
          showFailure(
            `The ${channel.name} list could not be published due to an error. Please try again and contact support if the problem persists`
          );
        }
      })
      .catch(() =>
        showFailure(`The ${channel.name} list could not be published due to an error. Please try again and contact support if the problem persists`)
      )
      .finally(() => setRelistLoading(false));
  };

  const openPriceModal = () => {
    setShowPriceModal(true);
  };

  const openSplitModal = (row, event) => {
    event.stopPropagation();
    setShowSplitModal(true);
    setInventoryIdToSplit(row._id);
  };

  const error = (errorCount, loggedCount) => {
    const message = `Sorry ${errorCount} of ${
      loggedCount + errorCount
    } changes failed. If this problem persists, please contact a Spoiler Alert Administrator to help you.`;
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  };

  const createMessage = (createdCount) => {
    const message = createdCount > 1 ? `${createdCount} changes were successfully made.` : `${createdCount} change was successfully made.`;
    AlertService.alert({ type: 'success', message: <span>{message}</span>, autoDismiss: true, dismissDelay: 3000 });
  };

  const hideModal = (_, errorCount, loggedCount) => {
    if (errorCount > 0) error(errorCount, loggedCount);
    if (loggedCount > 0 && errorCount === 0) createMessage(loggedCount);
    setShowPriceModal(false);
    setShowSplitModal(false);
    setShowPublishListModal(false);
  };

  const columnOverrides = {
    stagedListing_availableQuantity: {
      formatter: (_, row) => (
        <span
          className={summary.status === publishHistoryStatus.created ? classes.quantity : ''}
          {...(summary.status === publishHistoryStatus.created && { onClick: openSplitModal.bind(this, row) })}
        >
          {row.stagedListing.availableQuantity}
        </span>
      ),
    },
    stagedListing_suggestedUnitPrice: {
      formatter: (_, row) =>
        summary.status === publishHistoryStatus.created && summary?.channel?.destinationType !== destinationType.donation ? (
          <SuggestedPricingTextbox key={row._id} row={row} onFailure={showFailure} channel={channel} />
        ) : (
          <span>${accounting.formatMoney(row.stagedListing.suggestedUnitPrice, '')}</span>
        ),
    },
  };

  const publishDisabled = (status) =>
    status === publishHistoryStatus.running ||
    status === publishHistoryStatus.failed ||
    publishLoading ||
    resetLoading ||
    summary?.archiveStatus === ArchiveStatus.ALL_ARCHIVED;

  const pageActionButtons = (status) => {
    const offerDueDateEnabled = checkFeature(featureFlags.offerDueDate);
    if (status === publishHistoryStatus.completed) {
      return [
        <Button
          primary
          key="relist"
          loading={relistLoading}
          icon={CreateListingsIcon}
          onClick={handleRelist}
          loadingText="Relisting List"
          disabled={summary?.archiveStatus === ArchiveStatus.ALL_ARCHIVED}
        >
          Relist Available Inventory
        </Button>,
      ];
    }
    return [
      <Button
        warning
        key="reset"
        loading={resetLoading}
        icon={ClearListIcon}
        onClick={handleClearAll}
        loadingText="Clearing List"
        disabled={status === publishHistoryStatus.running || status === publishHistoryStatus.failed || publishLoading}
      >
        Clear List
      </Button>,
      offerDueDateEnabled ? (
        <Button
          key="publish"
          loadingText="Publishing"
          icon={PublishListIcon}
          onClick={() => setShowPublishListModal(true)}
          loading={publishLoading}
          disabled={publishDisabled(status)}
        >
          Publish List
        </Button>
      ) : (
        <ButtonWithDisclosure
          key="publish"
          buttonText="Publish List"
          loadingText="Publishing"
          icon={PublishListIcon}
          onSubmit={handlePublish}
          closeOnPromise={closeOnPromise}
          loading={publishLoading}
          disabled={publishDisabled(status)}
          placeholder="Use this space if you would like to communicate any additional information to your customer."
        />
      ),
    ];
  };

  return (
    <>
      {!loading && (
        <div>
          <GraphQLDataTable
            query={UserStagedListingsQuery}
            queryField="stagedListingsForChannel"
            filterParameterField="stagedInventoryFilterParameters"
            search
            queryParameters={filterParameters}
            columns={getColumnsFromDataTableProfile('Staging Detail', user.site.dataTableProfiles, columnOverrides)}
            filters={createInitialFilters(user)}
            updateStoredFilters={(filters) => UserFilters.updateFilters('stagingDetail', filters)}
            deletedList={tableDeletedList}
            clearDeletedList={clearTableDeletedList}
            selectable={summary?.status === publishHistoryStatus.created}
            onSelectChange={handleSelectChange}
            onFilterChange={handleQueryChange}
            perPage={30}
            pageActionButtons={pageActionButtons(summary?.status)}
            bulkActionButtons={
              summary?.status === publishHistoryStatus.created && summary?.channel?.destinationType !== destinationType.donation
                ? [
                    <Button key="adjust" primary icon={AdjustSuggestedPriceIcon} onClick={openPriceModal}>
                      Adjust Suggested Price
                    </Button>,
                  ]
                : []
            }
            rowActions={rowActions}
            disclaimer={
              summary?.archiveStatus === ArchiveStatus.SOME_ARCHIVED || summary?.archiveStatus === ArchiveStatus.ALL_ARCHIVED ? (
                <div className={classes.disclaimer}>
                  <div className={classes.alertIconContainer}>
                    <AlertWarningYellowIcon className={classes.alertIcon} />
                  </div>
                  Some or all of the inventory has been archived and removed from this list.
                </div>
              ) : null
            }
            noDataMessage={'All inventory in this list has been archived.'}
          ></GraphQLDataTable>
          <SplitInventoryModal
            open={showSplitModal}
            onHide={hideModal}
            inventoryId={inventoryIdToSplit}
            refetchQueries={refetchQueries}
            filters={{}}
          />
          <SuggestedPriceModal
            open={showPriceModal}
            onHide={hideModal}
            stagedListingIds={selected.map((inv) => inv.stagedListing._id)}
            allSelected={allSelected}
            total={total}
            filters={variables.paginate.filter}
            user={user}
          />
          <PublishListModal
            open={showPublishListModal}
            onHide={hideModal}
            onSubmit={handlePublish}
            donation={summary?.channel?.destinationType === destinationType.donation}
          />
        </div>
      )}
    </>
  );
};

CatalogDetail.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default CatalogDetail;
