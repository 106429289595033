import React, { useCallback, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { CheckboxWithLabel, OverlayService, Theme } from '@spoiler-alert/ui-library';
import { UserFilters } from '../../store';
import PropTypes from 'prop-types';

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.176px',
    marginBottom: '10px',
  },
  clearFilters: {
    color: Theme.teal,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.084px',
    cursor: 'pointer',
  },
  filterTitle: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.084px',
  },
});

const SuggestedNegotiationsFilter = ({ filters, setFilters }) => {
  const classes = useStyles();

  const defaultFilterState = {
    reserveAnchor: [],
  };

  const filterOptions = {
    reserveAnchor: [
      { id: 'reserve-anchor-1', label: 'Below Reserve Price', value: 'RESERVE_COUNTER' },
      { id: 'reserve-anchor-2', label: 'Below Highest Historical Transacted Price', value: 'HISTORICAL_BEST' },
    ],
  };

  useEffect(() => {
    OverlayService.show();
    return () => OverlayService.hide();
  }, []);

  useEffect(() => {
    UserFilters.updateFilters('suggestedNegotiations', filters);
  }, [filters]);

  const handleFilterChange = useCallback(
    (prop, value) => {
      setFilters({
        ...filters,
        [prop]: value,
      });
    },
    [filters, setFilters]
  );

  const removeItem = (array, item) => {
    const index = array.indexOf(item);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  };

  const handleCheckFilterChange = useCallback(
    (prop, event) =>
      handleFilterChange(prop, event.checked ? [...filters[prop], event.value] : removeItem(filters[prop], event.value), [handleFilterChange]),
    [filters, handleFilterChange]
  );

  const clearFilters = () => {
    setFilters(defaultFilterState);
  };

  return (
    <div>
      <div className={classes.row}>
        <h1 className={classes.header}>Filters</h1>
        <span onClick={clearFilters} className={classes.clearFilters}>
          Clear Filters
        </span>
      </div>
      <div>
        <span className={classes.filterTitle}>Negotiation Anchor</span>
        {filterOptions.reserveAnchor.map((reserveAnchor) => (
          <CheckboxWithLabel
            id={reserveAnchor.id}
            label={reserveAnchor.label}
            key={reserveAnchor.id}
            value={reserveAnchor.value}
            checked={filters.reserveAnchor.includes(reserveAnchor.value)}
            onChecked={handleCheckFilterChange.bind(this, 'reserveAnchor')}
          />
        ))}
      </div>
    </div>
  );
};

SuggestedNegotiationsFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default SuggestedNegotiationsFilter;
