import { gql } from '@apollo/client';

export const UserPostsQuery = gql`
  query UserPostsQuery($paginate: PaginatedPostFilterInput) {
    currentUserQuery {
      _id
      firstName
      postFilterParameters {
        _id
        locations {
          id
          name
          category
          count
        }
        sources {
          id
          name
          count
        }
        categories {
          id
          name
          count
        }
        subcategories {
          id
          name
          count
        }
        statuses {
          id
          name
          count
        }
        recipients {
          id
          name
          count
        }
        suppliers {
          id
          name
          count
        }
        destinations {
          id
          name
          count
        }
        vendorReturns {
          id
          name
          count
        }
      }
      posts(paginate: $paginate) {
        total
        pageContents {
          _id
          itemName
          source
          category
          quantity
          unitOfMeasure
          transactedAt
          destination
          status
          isVendorReturn
          actions
          description
          supplierSiteName
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const UserClaimsQuery = gql`
  query UserPostsQuery($paginate: PaginatedPostFilterInput) {
    currentUserQuery {
      _id
      firstName
      postFilterParameters {
        _id
        locations {
          id
          name
          category
        }
        sources {
          id
          name
        }
        categories {
          id
          name
        }
        statuses {
          id
          name
        }
        recipients {
          id
          name
        }
        suppliers {
          id
          name
        }
        destinations {
          id
          name
        }
        vendorReturns {
          id
          name
        }
      }
      claims(paginate: $paginate) {
        total
        pageContents {
          _id
          itemName
          source
          category
          supplierSiteName
          transactionId
          quantity
          unitOfMeasure
          transactedAt
          destination
          status
          isVendorReturn
          actions
          description
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const PostQuery = gql`
  query PostQuery($id: String!) {
    postByIdQuery(_id: $id) {
      _id
      transactedAt
      transactionId
      source
      category
      quantity
      unitOfMeasure
      destination
      description
      audience
      bestByDate
      mustGoByDate
      claimDate
      completedAtDate
      verifiedAtDate
      supplierSiteName
      status
      quality
      images
      minClaim
      maxDeliveryRadiusMiles
      supplierId
      recipientId
      isVendorReturn
      unitPrice
      isSelfReportedDonation
      isForSale
      isDonation
      isDiscountedSale
      isGiveaway
      distanceInMiles
      distanceInKm
      purchaseOrders {
        purchaseOrderNumber
        description
        amount
      }
      supplierSite {
        _id
        siteName
      }
      outletName
      outletSite {
        _id
        siteName
      }
      itemName
      item {
        _id
        itemName
        itemDesc
        unitOfMeasure
        packQty
        packSize
        packUOM
      }
      actions
      location {
        fullAddress
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export const TaxReceiptQuery = gql`
  query TaxReceiptQuery($id: String!) {
    postByIdQuery(_id: $id) {
      _id
      transactedAt
      completedAtDate
      verifiedAtDate
      category
      quantity
      unitOfMeasure
      description
      unitPrice
      transactionId
      totalPrice
      supplierSite {
        _id
        siteName
        physicalAddress {
          city
          streetAddress
          stateCode
          zipCode
        }
      }
      outletSite {
        _id
        siteName
        physicalAddress {
          city
          streetAddress
          stateCode
          zipCode
        }
        taxId
        purpose
        responsibleParty {
          title
          name
          signatureImage
        }
      }
    }
  }
`;

export const TotalCostQuery = gql`
  query TotalCostQuery($postFilter: PostFilterInput!) {
    siteMetricsQuery(postFilter: $postFilter) {
      totalCost
    }
  }
`;

export const TotalAmountQuery = gql`
  query TotalAmountQuery($postFilter: PostFilterInput!) {
    siteMetricsQuery(postFilter: $postFilter) {
      totalAmount
    }
  }
`;

export const TotalPoundsQuery = gql`
  query TotalPoundsQuery($postFilter: PostFilterInput!) {
    siteMetricsQuery(postFilter: $postFilter) {
      totalPounds
    }
  }
`;

export const TotalMealsQuery = gql`
  query TotalMealsQuery($postFilter: PostFilterInput!) {
    siteMetricsQuery(postFilter: $postFilter) {
      totalMeals
    }
  }
`;

export const TotalPoundsOfVerificationsQuery = gql`
  query TotalPoundsOfVerificationsQuery($postFilter: PostFilterInput!) {
    siteMetricsQuery(postFilter: $postFilter) {
      totalPoundsOfVerifications
    }
  }
`;

export const SellThroughRateQuery = gql`
  query SellThroughRateQuery($postFilter: PostFilterInput!) {
    discountedSalesMetricsQuery(postFilter: $postFilter) {
      sellThroughRate {
        value
        changeRate
        positiveChange
      }
    }
  }
`;

export const TotalRevenueQuery = gql`
  query TotalRevenueQuery($postFilter: PostFilterInput!) {
    discountedSalesMetricsQuery(postFilter: $postFilter) {
      totalRevenue {
        value
        changeRate
        positiveChange
      }
    }
  }
`;

export const BidRateQuery = gql`
  query BidRateQuery($postFilter: PostFilterInput!) {
    discountedSalesMetricsQuery(postFilter: $postFilter) {
      bidRate {
        value
        changeRate
        positiveChange
      }
    }
  }
`;

export const TotalTaxDeductibleQuery = gql`
  query TotalTaxDeductibleQuery($postFilter: PostFilterInput!) {
    siteMetricsQuery(postFilter: $postFilter) {
      totalTaxDeductible
    }
  }
`;

export const TaxDeductionsAreaChartQuery = `
  query TaxDeductionsAreaChartQuery($postFilter: PostFilterInput!) {
    siteMetricsQuery(postFilter: $postFilter) {
      taxDeductionPerPeriod {
        year
        data
      }
    }
  }`;

export const TaxDeductiblePostsQuery = gql`
  query UserTaxDeducationQuery($paginate: PaginatedPostFilterInput) {
    currentUserQuery {
      _id
      firstName
      postFilterParameters {
        _id
        locations {
          id
          name
          category
        }
        sources {
          id
          name
        }
        categories {
          id
          name
        }
        statuses {
          id
          name
        }
        recipients {
          id
          name
        }
        suppliers {
          id
          name
        }
        destinations {
          id
          name
        }
        vendorReturns {
          id
          name
        }
      }
      taxDeductiblePosts(paginate: $paginate) {
        total
        pageContents {
          _id
          transactionId
          quantity
          unitOfMeasure
          transactedAt
          status
          actions
          description
          totalCost
          totalPrice
          enhancedTaxDeduction
          outletName
          supplierSiteName
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const UserVerificationsQuery = gql`
  query UserVerificationsQuery($paginate: PaginatedPostFilterInput) {
    currentUserQuery {
      _id
      firstName
      postFilterParameters {
        _id
        locations {
          id
          name
          category
        }
        sources {
          id
          name
        }
        categories {
          id
          name
        }
        statuses {
          id
          name
        }
        recipients {
          id
          name
        }
        suppliers {
          id
          name
        }
        destinations {
          id
          name
        }
        vendorReturns {
          id
          name
        }
      }
      pendingVerifications(paginate: $paginate) {
        total
        pageContents {
          _id
          itemName
          source
          category
          pounds
          supplierSiteName
          transactionId
          quantity
          unitOfMeasure
          transactedAt
          destination
          status
          isVendorReturn
          actions
          description
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const verificationCountQuery = gql`
  query getUserQuery {
    currentUserQuery {
      _id
      pendingVerificationsCount
    }
  }
`;
