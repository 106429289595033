import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  TextInput,
  Radio,
  Button,
  Theme as theme,
  EmptyStateIcon,
  AlertWarningIcon,
  OverlayService,
  AlertService,
  MiniTooltip,
} from '@spoiler-alert/ui-library';
import { event } from 'react-fullstory';
import { createUseStyles } from 'react-jss';
import { useQuery, useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import MultiSelectWithButton from '../../../components/select/multi-select-with-button';
import { GetCustomerProfilesQuery, DistributionListContacts, DistributionListsWithBuyersQuery } from '../../../graphql/queries';
import { CreateDistributionList, UpdateDistributionList } from '../../../graphql/mutations';
import AddedCustomer from './added-customer';
import WarningModal from './warning-modal';
import ArchiveDistributionListWarningModal from './archive-distribution-list-warning-modal';
import createEmailMap from './possible-emails-per-buyer';
import { DistributionListTypes } from './distribution-list-enums';

const styles = {
  main: {
    padding: '24px',
    letterSpacing: '-0.18px',
  },
  container: {
    fontSize: '16px',
  },
  backTo: {
    cursor: 'pointer',
    fontSize: '12px',
  },
  title: {
    margin: 0,
    fontSize: '16px',
    fontWeight: 500,
    height: '24px',
  },
  titleCreateNew: {
    margin: 0,
    fontSize: '16px',
    fontWeight: 500,
    height: '24px',
    display: 'none',
  },
  listDescription: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  listName: {
    width: '50%',
  },
  destinationType: {
    marginRight: '-16px',
    marginBottom: '24px',
  },
  type: {
    fontSize: '12px',
    fontStyle: 'normal',
    marginBottom: '8px',
  },
  customers: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  buyerName: {
    fontWeight: 500,
  },
  zeroState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  zeroStateText: {
    width: '345px',
    flexGrow: 0,
    margin: '21px 0 4.7px 24.2px',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: '-0.18px',
    textAlign: 'left',
  },
  footer: {
    display: 'flex',
    justifyContent: ({ edit }) => (edit ? 'space-between' : 'flex-end'),
    alignItems: 'center',
    boxShadow: 'inset 0px 1px 0 0 #d9dee1',
    padding: '24px',
    width: '798px',
    '& button': {
      marginLeft: 8,
    },
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.white,
    zIndex: 1,
  },
  warningIcon: {
    width: 16,
    height: 16,
    marginRight: 7,
    fill: theme.red,
  },
  errorMessage: {
    fontSize: 12,
    color: theme.red,
    display: 'flex',
    justifyContent: 'start',
  },
  footer__buttonWrap: {
    display: 'flex',
  },
  archiveButton: {
    color: theme.red,
    cursor: 'pointer',
  },
  archiveButtonDisabled: {
    color: theme.red,
    opacity: '30%',
    cursor: 'pointer',
  },
};

const DistributionList = ({
  distributionLists,
  distributionListNames,
  siteId,
  listName,
  setListName,
  listType,
  setListType,
  addedCustomers,
  setAddedCustomers,
  originalListName,
  setOriginalListName,
  basePath,
  match,
  history,
  edit,
  showDestinationType,
  flashSnackbar,
}) => {
  const useStyles = createUseStyles(styles, { name: 'DistributionList' });

  const [showSelectAll, setShowSelectAll] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showArchiveWarning, setShowArchiveWarning] = useState(false);
  const [touched, setTouched] = useState(false);
  const [notInUse, setNotInUse] = useState(false);

  const classes = useStyles({ edit });

  const distributionList = useMemo(() => {
    return distributionLists?.find((dl) => dl._id === match.params.listId);
  }, [match?.params?.listId, distributionLists]);

  const getInitialListType = () => {
    if (!distributionList) return null;
    if (distributionList.destinationType !== 'donation') {
      return { ...DistributionListTypes.LIQUIDATION };
    }
    return { ...DistributionListTypes.DONATION };
  };

  const { data: customerProfilesData } = useQuery(GetCustomerProfilesQuery);
  const { data: bsrData, loading: seedBSRsLoading } = useQuery(DistributionListContacts, {
    variables: { listId: edit ? match.params.listId : undefined },
  });

  useEffect(() => {
    if (distributionList?._id) {
      setListName(distributionList.key);
      setOriginalListName(distributionList.key);
    }
  }, [distributionList?._id]);

  useEffect(() => {
    if (edit && bsrData?.distributionListContactsQuery?.customers) {
      setAddedCustomers(JSON.parse(JSON.stringify(bsrData?.distributionListContactsQuery?.customers)));
      setNotInUse(bsrData?.distributionListContactsQuery?.notInUse);
    } else setAddedCustomers([]);
  }, [bsrData?.distributionListContactsQuery]);

  useEffect(() => {
    if (!edit && !showDestinationType) setListType({ ...DistributionListTypes.LIQUIDATION });
  }, []);

  const [updateDistributionList, { loading: updateListLoading }] = useMutation(UpdateDistributionList);
  const [createDistributionList, { loading: createListLoading }] = useMutation(CreateDistributionList);

  const handleRemoveCustomer = (id) => {
    const filteredCustomers = addedCustomers.filter((customer) => customer.buyerSiteId !== id);
    setTouched(true);
    setAddedCustomers(filteredCustomers);
    if (edit) event('Removed Buyer from Existing List');
  };

  const isListNameUnique = !distributionListNames.includes(listName);

  const possibleEmailsPerBuyer = createEmailMap(bsrData?.distributionListContactsQuery?.seeds || []);

  const getContacts = (buyerSiteId) => {
    return possibleEmailsPerBuyer.get(buyerSiteId);
  };
  // this is if we want to leave the list page that is prefilled or touched
  const handleCancel = (e) => {
    e.preventDefault();
    if (!edit && listType && listName && addedCustomers.length > 0) {
      setShowWarning(true);
      OverlayService.show();
    } else if (edit && touched) {
      setShowWarning(true);
      OverlayService.show();
    } else {
      history.push(basePath);
    }
  };

  const handleArchiveClick = () => {
    setShowArchiveWarning(true);
    OverlayService.show();
  };

  const createBSRsForEachBuyer = () => {
    const bsrs = [];
    addedCustomers.forEach((customer) => {
      const emails = customer.contacts;
      const offerSellerEmails = [];
      const awardSellerEmails = [];
      const offerBuyerEmails = [];
      const awardBuyerEmails = [];
      emails.forEach((email) => {
        if (email.value.includes('Offer Sheets') && email.groupBy === 'Customer') {
          offerBuyerEmails.push(email.key);
        }
        if (email.value.includes('Award Sheets') && email.groupBy === 'Customer') {
          awardBuyerEmails.push(email.key);
        }
        if (email.value.includes('Offer Sheets') && email.groupBy === 'Internal') {
          offerSellerEmails.push(email.key);
        }
        if (email.value.includes('Award Sheets') && email.groupBy === 'Internal') {
          awardSellerEmails.push(email.key);
        }
      });
      const offerBSR = {
        sellerSiteId: customer.sellerSiteId,
        buyerSiteId: customer.buyerSiteId,
        type: 'OFFER',
        name: `${listName} - ${customer.buyerName} - OFFER - ${uuidv4()}`,
        buyerUserEmails: offerBuyerEmails,
        sellerUserEmails: offerSellerEmails,
      };
      const awardBSR = {
        sellerSiteId: customer.sellerSiteId,
        buyerSiteId: customer.buyerSiteId,
        type: 'AWARD',
        name: `${listName} - ${customer.buyerName} - AWARD - ${uuidv4()}`,
        buyerUserEmails: awardBuyerEmails,
        sellerUserEmails: awardSellerEmails,
      };
      bsrs.push(awardBSR);
      bsrs.push(offerBSR);
    });
    return bsrs;
  };

  const handleUpdateList = () => {
    const bsrs = createBSRsForEachBuyer();
    const variables = {
      distributionList: {
        _id: match.params.listId,
        name: listName,
        destinationType: listType.value,
      },
      bsrs,
    };
    updateDistributionList({
      variables,
      refetchQueries: [
        { query: DistributionListContacts, variables: { listId: edit ? match.params.listId : undefined } },
        { query: GetCustomerProfilesQuery },
        { query: DistributionListsWithBuyersQuery, variables: { siteId } },
      ],
    }).then((res) => {
      if (res.data.updateDistributionList.errors.length > 0) {
        AlertService.alert({
          type: 'warning',
          message: (
            <span>
              {`We were unable to update the distribution list due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.`}
            </span>
          ),
          autoDismiss: true,
        });
      } else {
        event('Save Changes');
        flashSnackbar('Your list has been saved');
        history.push(basePath);
      }
    });
  };

  const handleCreateList = () => {
    const bsrs = createBSRsForEachBuyer();
    const variables = {
      distributionList: {
        name: listName,
        destinationType: listType.value,
      },
      bsrs,
    };
    createDistributionList({
      variables,
      refetchQueries: [
        { query: DistributionListContacts },
        { query: GetCustomerProfilesQuery },
        { query: DistributionListsWithBuyersQuery, variables: { siteId } },
      ],
    }).then((res) => {
      if (res.data.createDistributionList.errors.length > 0) {
        AlertService.alert({
          type: 'warning',
          message: (
            <span>
              {`We were unable to create a new distribution list due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.`}
            </span>
          ),
          autoDismiss: true,
        });
      } else {
        flashSnackbar(`The list, ${listName}, was created successfully`);
        if (listType === DistributionListTypes.LIQUIDATION) event('Created New Liquidation DL');
        if (listType === DistributionListTypes.DONATION) event('Created New Donation DL');
      }
      history.push(basePath);
    });
  };

  const handleChange = (value, setter) => {
    setter(value);
    setTouched(true);
  };

  const showEmptyState = !addedCustomers.length && !seedBSRsLoading && (!edit || bsrData);

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <Link to={basePath} className={classes.backTo} onClick={handleCancel}>
          Back to lists
        </Link>
        <h2 className={edit ? classes.titleCreateNew : classes.title}>Create new list</h2>
        <div className={classes.listDescription}>
          <div className={classes.listName}>
            <TextInput
              type="text"
              labelText="List name *"
              onChange={(value) => handleChange(value, setListName)}
              value={listName || distributionList?.key}
              error={!isListNameUnique && listName !== originalListName}
            ></TextInput>
            {!isListNameUnique && listName !== originalListName && (
              <span className={classes.errorMessage}>
                <AlertWarningIcon className={classes.warningIcon} />
                List name already exists. Please enter a unique list name.
              </span>
            )}
          </div>
          {showDestinationType && (
            <div className={classes.destinationType}>
              <h3 className={classes.type}>LIST TYPE *</h3>
              <Radio
                options={[{ ...DistributionListTypes.LIQUIDATION }, { ...DistributionListTypes.DONATION }]}
                selectedItem={listType || getInitialListType()}
                onChange={(value) => handleChange(value, setListType)}
              ></Radio>
            </div>
          )}
        </div>
        <div className={classes.customers}>
          <h3 className={classes.title}>Customers</h3>
          <Button secondary onClick={() => setShowSelectAll(true)}>
            Add Customer
          </Button>
          {showSelectAll && (
            <MultiSelectWithButton
              customers={JSON.parse(JSON.stringify(customerProfilesData?.customerProfiles || []))}
              setShowSelectAll={setShowSelectAll}
              setAddedCustomers={setAddedCustomers}
              addedCustomers={addedCustomers}
              getContacts={getContacts}
              setTouched={setTouched}
            />
          )}
        </div>
        {addedCustomers.map((customer) => {
          return (
            <AddedCustomer
              customer={customer}
              key={customer.buyerSiteId}
              contacts={customer.contacts || []}
              handleRemoveCustomer={handleRemoveCustomer}
              setTouched={setTouched}
              notInUse={notInUse}
              edit={edit}
            />
          );
        })}
        {showEmptyState && (
          <div className={classes.zeroState}>
            <EmptyStateIcon />
            <div className={classes.zeroStateText}>
              You haven’t added any customers yet. Get started by adding the customers you want to include in this list.
            </div>
          </div>
        )}
      </div>
      <div className={classes.footer}>
        {edit && (
          <>
            {notInUse ? (
              <div className={classes.archiveButton} onClick={handleArchiveClick}>
                Archive list
              </div>
            ) : (
              <MiniTooltip text="Lists cannot be archived while in-use">
                <div className={classes.archiveButtonDisabled} onClick={undefined}>
                  Archive list
                </div>
              </MiniTooltip>
            )}
          </>
        )}
        <div className={classes.footer__buttonWrap}>
          <Button secondary onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            primary
            disabled={edit ? !touched || !listName || !listType || !addedCustomers.length > 0 : !listName || !listType || !addedCustomers.length > 0}
            onClick={edit ? handleUpdateList : handleCreateList}
            loading={updateListLoading || createListLoading}
            loadingText={edit ? 'Saving Changes' : 'Saving List'}
          >
            {edit ? 'Save Changes' : 'Save List'}
          </Button>
        </div>
      </div>
      {showWarning && <WarningModal setShowWarning={setShowWarning} showWarning={showWarning} history={history} basePath={basePath} />}
      {showArchiveWarning && (
        <ArchiveDistributionListWarningModal
          setShowArchiveWarning={setShowArchiveWarning}
          showArchiveWarning={showArchiveWarning}
          listName={listName}
          listId={match.params.listId}
          siteId={siteId}
          basePath={basePath}
          history={history}
          flashSnackbar={flashSnackbar}
        />
      )}
    </div>
  );
};

DistributionList.propTypes = {
  distributionLists: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      archived: PropTypes.bool,
      destinationType: PropTypes.string,
      key: PropTypes.string,
    })
  ),
  distributionListNames: PropTypes.array,
  siteId: PropTypes.string,
  listName: PropTypes.string,
  setListName: PropTypes.func,
  listType: PropTypes.object,
  setListType: PropTypes.func,
  addedCustomers: PropTypes.array,
  setAddedCustomers: PropTypes.func,
  originalListName: PropTypes.string,
  setOriginalListName: PropTypes.func,
  basePath: PropTypes.string,
  match: PropTypes.object,
  history: PropTypes.object,
  edit: PropTypes.bool,
  showDestinationType: PropTypes.bool,
  flashSnackbar: PropTypes.func,
};

export default DistributionList;
