import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, MenuHeader, MenuSeparator, Avatar, AlertService, Theme } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { graphql } from '@apollo/client/react/hoc';
import { toggleImpersonateSite } from '../graphql/mutations';
import { UserQuery } from '../graphql/queries';
import routePaths from '../route-paths';
import client from '../apollo/client';

const styles = {
  'user-menu__container': {
    position: 'relative',
  },
  chevron__icon: {
    marginLeft: 8,
    stroke: '#292e34',
    height: 9,
    width: 13,
  },
  'user-menu__target': {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  'menu-header__siteName': {
    fontSize: '0.875rem',
    fontWeight: '500',
    lineHeight: '1.43',
    letterSpacing: '-0.08px',
    color: Theme.textColorPrimary,
    paddingTop: '2px',
  },
  'menu-header__name': {
    color: Theme.textColorPrimary,
    paddingBottom: '0px',
  },
  text__capitalize: {
    textTransform: 'capitalize',
  },
  '@media (max-width: 840px)': {
    'user-menu__container': {
      display: 'none',
    },
  },
};

@injectSheet(styles)
@graphql(toggleImpersonateSite)
export default class UserMenu extends React.Component {
  state = {
    show: false,
  };

  redirect = () => {
    this.props.history.push(routePaths.impersonate);
  };

  deactivateImpersonate = (e) => {
    e.preventDefault();
    const errorMessage = 'Sorry there was an error turning off impersonate';
    const regex = /coordinates/;
    for (let i = 0; i < localStorage.length; i++) {
      if (regex.exec(localStorage.key(i))) localStorage.removeItem(localStorage.key(i));
    }
    this.props
      .mutate({
        refetchQueries: [
          {
            query: UserQuery,
          },
        ],
      })
      .then(this.redirect)
      .catch(() => this.error(errorMessage))
      .finally(async () => {
        await client.cache.reset();
      });
  };

  error(message) {
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  }

  showMenu = () => {
    this.setState({ show: true });
  };

  hideMenu = () => {
    this.setState({ show: false });
  };

  menu = React.createRef();

  render() {
    const { user, classes } = this.props;
    const menuClass = user.isImpersonating ? classes['menu__container--impersonate'] : undefined;
    return (
      <div className={classes['user-menu__container']}>
        <div className={classes['user-menu__target']} onClick={this.showMenu} ref={this.menu}>
          <Avatar image={user.photo} firstName={user.firstName} lastName={user.lastName} size="small" impersonating={user.isImpersonating} />
        </div>
        <Menu show={this.state.show} position="topright" parent={this.menu} className={menuClass} onHide={this.hideMenu}>
          <MenuHeader>
            <Avatar image={user.photo} firstName={user.firstName} lastName={user.lastName} impersonating={user.isImpersonating} />
          </MenuHeader>
          <MenuHeader className={`${classes.text__capitalize} ${classes['menu-header__name']}`}>
            {user.firstName} {user.lastName}
          </MenuHeader>
          <MenuHeader className={classes['menu-header__siteName']}>{user.site.siteName}</MenuHeader>
          <MenuSeparator />
          {user.site.isNonProfit && (
            <MenuItem route="/site/settings" icon="taxdocument">
              Taxes
            </MenuItem>
          )}
          {!user.site.isNonProfit && (
            <MenuItem route="/organization/settings" icon="settingsslider">
              Organization Settings
            </MenuItem>
          )}
          <MenuItem route="/support/sso" newWindow={true} icon="questionhelp">
            Help
          </MenuItem>
          {user.privileges.canViewUserManagement && (
            <MenuItem route="/users" icon="usergroup">
              User Management
            </MenuItem>
          )}
          {user.privileges.isSystemAdmin && (
            <MenuItem route="/administration/impersonate" icon="key">
              System Administrator
            </MenuItem>
          )}
          <MenuSeparator />
          {user.isImpersonating && (
            <MenuItem route="#" onClick={this.deactivateImpersonate} icon="impersonateoff">
              Impersonate Off
            </MenuItem>
          )}
          <MenuItem route="/logout" icon="logout">
            Log Out
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

UserMenu.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object,
  client: PropTypes.object,
  mutate: PropTypes.func,
};
