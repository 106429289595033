import React, { useRef, useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { inject, observer } from 'mobx-react';
import accounting from 'accounting';
import {
  DataTableNaked,
  Button,
  AlertService,
  Theme as theme,
  AcceptOffersIcon,
  Timing,
  Search,
  RowActionInstanceUnaward,
  ButtonInstanceUnawardAll,
  usePolling,
} from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { OfferListingsByBuyerQuery, OfferReviewDetailsPollingQuery, AwardSummaryQuery } from '../../graphql/queries';
import {
  acceptOffer as AcceptOfferMutation,
  UnawardOfferListingsByBuyerSiteId as UnawardOfferListingsByBuyerSiteIdMutation,
  UnawardOfferListingsBySellerLocationId as unawardOfferListingsBySellerLocationIdMutation,
  UnawardOfferListingsByTruckLane as UnawardOfferListingsByTruckLaneMutation,
} from '../../graphql/mutations';
import deleteCachedFieldsOnUserQuery from '../../apollo/cache-helpers/delete-cached-fields-on-user-query';
import deleteCachedFieldsOnListedInventoriesQuery from '../../apollo/cache-helpers/delete-cached-fields-on-offer-review-polling-query';
import deleteCachedObject from '../../apollo/cache-helpers/delete-cached-object';
import { OfferComparisonStrings } from '../../string-resources';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import routePaths from '../../route-paths';
import { Breadcrumbs } from '../../store';
import { weightWithUnit } from '../../utilities/unit-helper';

export const styles = {
  buyer_detail_wrap: {
    marginBottom: -40,
  },
  buyer_detail_status: {
    position: 'relative',
    top: '-40px',
    height: 0,
    left: '275px',
  },
  buyer_detail_container: {
    minHeight: 'calc(100vh - 106px)',
  },
  buyer_detail_actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: [0, 0, 24],
    '&>button': {
      marginLeft: 8,
    },
  },
  table__container: {
    border: '1px #D1D6DA solid',
    borderRadius: 2,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    '& td': {
      padding: 5,
    },
  },
  table__totalrow: {
    fontWeight: 600,
    borderTop: '1px solid',
    borderTopColor: theme.tableHeaderTextColor,
  },
  table__headerrow: {
    color: theme.tableHeaderTextColor,
    fontWeight: 600,
  },
  table__numeric: {
    textAlign: 'right',
  },
  table__link: {
    backgroundColor: `${theme.errorColor} !important`,
    color: '#fff !important',
    display: 'inline-block !important',
  },
  modal__title: {
    fontSize: '1.5rem',
    padding: 0,
    marginTop: 0,
    color: '#292e34',
    fontWeight: 'normal',
  },
  offerReviewSearch: {
    width: 350,
    marginRight: 'auto',
  },
  buttonIcon: {
    '& svg': {
      width: '24px !important',
      height: '24px !important',
    },
  },
};

const useStyles = createUseStyles(styles);

const OfferLogisticsReview = ({ match, user, history }) => {
  const classes = useStyles();
  const {
    params: { buyerSiteId },
  } = match;
  const buyerSiteName = decodeURIComponent(match.params.buyerSiteName);

  // State
  const [searchText, setSearchText] = useState('');

  const pollResponse = usePolling({
    idleInterval: 10000,
    activeInterval: 2000,
    isInProgress: (data) => data.exportStatus === 'IN_PROGRESS',
    poll: true,
    query: OfferReviewDetailsPollingQuery,
    responseKey: 'offerReviewDetailsPollingQuery',
    parameters: { buyerSiteId },
  });

  const blockAction = pollResponse ? pollResponse.exportStatus === 'IN_PROGRESS' : true;

  const [actionOnRows, updateActionOnRows] = useReducer((state, action) => {
    switch (action.type) {
      case 'addRow':
        return [...state, action.rowId];
      case 'removeRow':
        return state.filter((row) => row !== action.rowId);
      default:
        return null;
    }
  }, []);

  // Reference if component is mounted so we can check before async tasks
  const isComponentMounted = useRef(false);
  useEffect(() => {
    isComponentMounted.current = true;
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  // Apollo
  /// Cache Access
  const { cache: apolloCache } = useApolloClient();

  /// Queries
  const {
    data: offerListingsByBuyerData,
    loading: offerListingsByBuyerLoading,
    refetch: offerListingsByBuyerRefetch,
  } = useQuery(OfferListingsByBuyerQuery, {
    variables: { buyerSiteIds: [buyerSiteId] },
  });

  /// Mutations
  const [acceptOffers, { loading: acceptingAll }] = useMutation(AcceptOfferMutation);
  const [unawardOfferListingsByBuyerSiteId, { loading: unawardingAll }] = useMutation(UnawardOfferListingsByBuyerSiteIdMutation);
  const [unawardOfferListingsBySellerLocationId] = useMutation(unawardOfferListingsBySellerLocationIdMutation);
  const [unawardOfferListingsByTruckLane] = useMutation(UnawardOfferListingsByTruckLaneMutation);

  useEffect(() => {
    // On initial load of this page, if there is already data and we are not loading more, we want to refetch as other pages
    //  in the app are calling 'OfferListingsByBuyerQuery' but then the cache is not updated when changes happen to awarded offers
    if (offerListingsByBuyerData && !offerListingsByBuyerLoading) offerListingsByBuyerRefetch();

    TitleService.setTitles('Offer Review');
    Breadcrumbs.set([
      {
        url: routePaths.review,
        title: 'Offer Review',
      },
      {
        url: match.url,
        title: `${buyerSiteName}${(offerListingsByBuyerData?.offerListingsByBuyerQuery[0]?.isVendorOfRecord && ' (Spoiler Alert Buyer)') || ''}`,
        badge: { text: 'NOT ACCEPTED', colors: theme.badgeColors.grey },
      },
    ]);
  }, [buyerSiteName, match.url, offerListingsByBuyerData, offerListingsByBuyerLoading, offerListingsByBuyerRefetch]);

  const successMessage = (message) => {
    AlertService.alert({
      type: 'success',
      autoDismiss: true,
      message: <span>{message}</span>,
    });
  };

  const infoMessage = (message) => {
    AlertService.alert({
      type: 'info',
      autoDismiss: true,
      message: <span>{message}</span>,
    });
  };

  const errorMessage = (message) => {
    AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{message}</span> });
  };

  const columnOverrides = {
    logisticsTerm: {
      groupHeader: (key) => key,
    },
    weight: {
      groupSummary: (rows) =>
        weightWithUnit(
          rows.reduce((sum, row) => sum + row.weight, 0),
          user
        ),
    },
    cubeAdjustedWeight: {
      groupSummary: (rows) =>
        weightWithUnit(
          rows.reduce((sum, row) => sum + row.cubeAdjustedWeight, 0),
          user
        ),
    },
    cases: {
      groupSummary: (rows) => rows.reduce((sum, row) => sum + row.cases, 0),
    },
    pallets: {
      groupSummary: (rows) => rows.reduce((sum, row) => sum + row.pallets, 0).toFixed(2),
    },
    offerPrice: {
      groupSummary: (rows) => accounting.formatMoney(rows.reduce((sum, row) => sum + row.offerPrice, 0)),
    },
    items: {
      groupSummary: (rows) => rows.reduce((sum, row) => sum + row.items, 0),
    },
    truckType: {
      style: { textTransform: 'capitalize' },
    },
    totalRevenue: {
      groupSummary: (rows) =>
        `${accounting.formatNumber(
          (rows.reduce((sum, row) => sum + row.totalRevenue, 0) / rows.reduce((sum, row) => sum + row.costOfInventoryAwarded, 0)) * 100,
          2
        )}%`,
    },
    costRecoveryRate: {
      groupSummary: (rows) => {
        const totalCost = rows.reduce((sum, row) => sum + row.costOfInventoryAwarded, 0);
        const acceptedOffer = rows.reduce((sum, row) => sum + row.totalRevenue, 0);
        return `${((acceptedOffer / totalCost) * 100).toFixed(2)}%`;
      },
    },
    unitPriceRecoveryRate: {
      groupSummary: (rows) => {
        const totalUnitPriceRecoveryRate = rows.reduce((sum, row) => sum + row.unitPriceRecoveryRate, 0);
        return `${(totalUnitPriceRecoveryRate / rows.length).toFixed(2)}%`;
      },
    },
    priceOfInventoryAwarded: {
      groupSummary: (rows) => accounting.formatMoney(rows.reduce((sum, row) => sum + row.priceOfInventoryAwarded, 0)),
    },
    writeOffDiscountPercent: {
      groupSummary: (rows) => {
        const offerPrice = rows.reduce((sum, row) => sum + row.offerPrice, 0);
        const writeOffPriceOfInventoryAwarded = rows.reduce((sum, row) => sum + row.writeOffPriceOfInventoryAwarded, 0);
        const result =
          offerPrice && writeOffPriceOfInventoryAwarded ? ((1 - offerPrice / writeOffPriceOfInventoryAwarded) * 100).toFixed(2) : undefined;
        return isNaN(result) ? undefined : `${result}%`;
      },
    },
    trucklaneCost: {
      groupSummary: (rows) => {
        const total = rows[0].logisticsTerm === 'Delivery' ? rows.reduce((sum, row) => sum + row.trucklaneCost, 0) : '-';
        return total > -1 ? `(${accounting.formatMoney(total)})` : total;
      },
    },
    netRevenue: {
      groupSummary: (rows) => {
        const totalRev = rows.reduce((sum, row) => sum + (row.totalRevenue - row.trucklaneCost), 0);
        return totalRev > -1 ? accounting.formatMoney(totalRev) : `(${accounting.formatMoney(-1 * totalRev)})`;
      },
    },
  };

  const handleSearch = Timing.debounce((text) => setSearchText(text), 300);

  const deleteCachedOfferListingSummaryTypesForBuyer = (cache) => {
    offerListingsByBuyerData?.offerListingsByBuyerQuery.forEach((offerListingSummaryType) => {
      deleteCachedObject(
        cache,
        'OfferListingSummaryType',
        `${buyerSiteId}_${offerListingSummaryType.logisticsTerm}_${offerListingSummaryType.truckType}_${offerListingSummaryType.transactionLocationId}_${offerListingSummaryType.sellerSiteId}`
      );
    });
  };

  useEffect(() => {
    let badge;
    switch (pollResponse?.exportStatus) {
      case 'NOT_IN_PROGRESS':
        badge = { text: 'NOT ACCEPTED', colors: theme.badgeColors.grey };
        break;
      case 'IN_PROGRESS':
        badge = { text: 'ACCEPTANCE IN PROGRESS', colors: theme.badgeColors.yellow };
        break;
      case 'COMPLETE':
        badge = { text: 'COMPLETED', colors: theme.badgeColors.green };
        break;
      default:
    }
    Breadcrumbs.update(1, {
      url: match.url,
      title: buyerSiteName,
      badge,
    });
  }, [pollResponse?.exportStatus, acceptingAll]);

  // Click Handlers
  const handleRowClick = (row) => {
    const trucklaneId = `${row.truckType}:${row.logisticsTerm}:${row.sellerSiteId}:${row.buyerDestinationId}:${user.site.transactionCycleId}`;
    history.push(`${routePaths.offerComparison}/trucklane/${trucklaneId}`);
  };

  /// Page Action Button 'Accept All'
  const handleAcceptAllClick = () => {
    acceptOffers({
      variables: { buyerSiteIds: [buyerSiteId], acceptAll: false },
      update: (cache) => {
        deleteCachedFieldsOnListedInventoriesQuery(cache);
      },
      refetchQueries: ['ProgressBarsQuery', { query: AwardSummaryQuery }],
    })
      .then(() => {
        infoMessage(`Acceptance for ${buyerSiteName} has been initiated.`);
        deleteCachedOfferListingSummaryTypesForBuyer(apolloCache);
        history.push(routePaths.review);
      })
      .catch(() => {
        errorMessage(OfferComparisonStrings.awardingError);
      });
  };

  /// Page Action Button 'Unaward All'
  const handleUnawardAllClick = () => {
    unawardOfferListingsByBuyerSiteId({
      variables: { siteId: buyerSiteId }, // Unaward by buyer parent site id
      update: (cache) => {
        deleteCachedOfferListingSummaryTypesForBuyer(cache);
        deleteCachedFieldsOnUserQuery(cache, ['awardedOfferListings', 'awardedInventoryFilterParameters', 'getAwardSummary']);
      },
    })
      .then(() => {
        successMessage(`All offers from ${buyerSiteName} have been unawarded.`);
        if (isComponentMounted.current) {
          history.push(`${routePaths.review}`);
        }
      })
      .catch(() => {
        errorMessage(OfferComparisonStrings.unawardingError);
      });
  };

  /// Row Action Button (header rows)
  const handleUnawardBySellerLocation = (rows, e) => {
    e.stopPropagation();
    // See note [1] below about sellerSiteId vs sellerLocationId
    const { sellerSiteId: sellerLocationId, sellerSiteName } = rows[0];
    updateActionOnRows({ type: 'addRow', rowId: sellerSiteName });
    unawardOfferListingsBySellerLocationId({
      variables: {
        buyerSiteId,
        sellerLocationId,
      },
      update: (cache) => {
        // Need to remove the associated OfferListingSummaryTypes in cache for this sellerLocation.  An OfferListingSummaryType
        //  represents each row on this page, so we must remove all that are associated with this sellerLocationId
        rows.forEach((row) => {
          deleteCachedObject(
            cache,
            'OfferListingSummaryType',
            `${buyerSiteId}_${row.logisticsTerm}_${row.truckType}_${row.transactionLocationId}_${sellerLocationId}`
          );
        });
        // Also refresh fields on user query
        deleteCachedFieldsOnUserQuery(cache, ['awardedOfferListings', 'awardedInventoryFilterParameters', 'getAwardSummary']);
      },
    })
      .then(() => {
        // Determine number of data table sections so that we can determine correct Success Messaging
        const numberOfDataTableSections = offerListingsByBuyerData?.offerListingsByBuyerQuery
          .map((row) => row.sellerSiteName)
          .reduce(
            (acc, val) => {
              if (acc.uniqueSiteNames.includes(val)) {
                return acc;
              }
              return {
                result: acc.result + 1,
                uniqueSiteNames: [...acc.uniqueSiteNames, val],
              };
            },
            { result: 0, uniqueSiteNames: [] }
          ).result;
        const message = numberOfDataTableSections === 1 ? `All offers from ${buyerSiteName} have been unawarded.` : `The offers have been unawarded.`;
        successMessage(message);
        if (isComponentMounted.current) {
          if (rows.length === offerListingsByBuyerData?.offerListingsByBuyerQuery.length) {
            history.push(`${routePaths.review}`);
          } else {
            updateActionOnRows({ type: 'removeRow', rowId: sellerLocationId });
          }
        }
      })
      .catch(() => {
        errorMessage(OfferComparisonStrings.unawardingError);
        if (isComponentMounted.current) {
          updateActionOnRows({ type: 'removeRow', rowId: sellerLocationId });
        }
      });
  };

  /// Row Action Button (individual rows)
  const handleUnawardByTruckLaneClick = (row, e) => {
    e.stopPropagation();
    // See note [1] below about sellerSiteId vs sellerLocationId
    const { _id: rowId, sellerSiteId: sellerLocationId, transactionLocationId, truckType, logisticsTerm } = row;
    updateActionOnRows({ type: 'addRow', rowId });
    unawardOfferListingsByTruckLane({
      variables: {
        buyerSiteId,
        sellerLocationId,
        transactionLocationId,
        truckType,
        logisticsTerm,
      },
      update: (cache) => {
        // Need to remove the associated OfferListingSummaryType in cache.  An OfferListingSummaryType represents each row on this page.
        deleteCachedObject(
          cache,
          'OfferListingSummaryType',
          `${buyerSiteId}_${logisticsTerm}_${truckType}_${transactionLocationId}_${sellerLocationId}`
        );
        // Also refresh fields on user query
        deleteCachedFieldsOnUserQuery(cache, ['awardedOfferListings', 'awardedInventoryFilterParameters', 'getAwardSummary']);
      },
    })
      .then(() => {
        successMessage(`All offers from the trucklane have been unawarded.`);
        if (isComponentMounted.current) {
          if (offerListingsByBuyerData?.offerListingsByBuyerQuery.length === 1) {
            history.push(`${routePaths.review}`);
          } else {
            updateActionOnRows({ type: 'removeRow', rowId });
          }
        }
      })
      .catch(() => {
        errorMessage(OfferComparisonStrings.unawardingError);
        if (isComponentMounted.current) {
          updateActionOnRows({ type: 'removeRow', rowId });
        }
      });
  };

  const rowActions = [
    <RowActionInstanceUnaward
      key={1}
      onClick={(row) => handleUnawardByTruckLaneClick.bind(this, row)}
      disabledRows={actionOnRows}
      loadingRows={actionOnRows}
    />,
  ];

  const headerRowActions = [
    <RowActionInstanceUnaward
      key={1}
      headerRowOnClick={(row) => handleUnawardBySellerLocation.bind(this, row)}
      disabledRows={actionOnRows}
      loadingRows={actionOnRows}
    />,
  ];

  return (
    <div className={classes.buyer_detail_wrap}>
      <div className={classes.buyer_detail_container}>
        <div className={classes.buyer_detail_actions}>
          <Search onChange={handleSearch} className={classes.offerReviewSearch} />
          <ButtonInstanceUnawardAll onClick={handleUnawardAllClick} disabled={blockAction} loading={unawardingAll} />
          <Button
            classes={{ iconHolder: classes.buttonIcon }}
            primary
            icon={AcceptOffersIcon}
            disabled={blockAction}
            loading={acceptingAll || blockAction}
            loadingText="Accepting Offers"
            onClick={handleAcceptAllClick}
            cypressTag="accept-offers-button"
          >
            Accept Offers
          </Button>
        </div>
        <div className={classes.table__container}>
          <DataTableNaked
            data={offerListingsByBuyerData?.offerListingsByBuyerQuery || []}
            groupBy={(row) => row.sellerSiteName}
            searchText={searchText}
            onRowClick={handleRowClick}
            columns={getColumnsFromDataTableProfile('Offer Review Buyer', user.site.dataTableProfiles, columnOverrides)}
            sticky
            rowActions={rowActions}
            headerRowActions={headerRowActions}
            isHeaderRowClickable={false}
            cypressTagTable="offer-review-buyer-table"
          />
        </div>
      </div>
    </div>
  );
};

OfferLogisticsReview.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object,
};

const ConnectedComponent = inject((store) => store)(observer(OfferLogisticsReview));

export default ConnectedComponent;

// Notes:
// 1.  Note: below we call sellerSiteId by sellerLocationId, because in graphql these are different.  On the backend,
//     'sellerSiteId' is always a parent site's id, while 'sellerLocationId' is a specific seller location id.  Here
//     on the front-end, sellerSiteId does not necessarily refer to the parent site, but instead the specific seller location.
