import { FileUploadService } from '../services';
import AppSettings from '../app-settings';

export default class ImageHelper {
  constructor(folder, identifier, successCallback, errorCallback) {
    this.maxWidth = 1024;
    this.maxHeight = 768;
    this.preloadRetryLimit = 5;
    this.preloadTimeout = 100;
    this.folder = folder;
    this.identifier = identifier;
    this.successCallback = successCallback;
    this.errorCallback = errorCallback;
    this.bind();
  }

  bind() {
    this.readFile = this.readFile.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
  }

  deleteImage(url) {
    const fileName = url.split('/').pop();
    FileUploadService.deleteLogo(`${this.folder}/${fileName}`);
  }

  preloadImage(url, callCount = 0) {
    const img = new Image();
    img.onload = () => {
      this.successCallback(img);
    };
    img.onerror = () => {
      if (callCount < this.preloadRetryLimit) {
        setTimeout(() => {
          this.preloadImage(url, callCount + 1);
        }, this.preloadTimeout);
      }
    };
    img.src = url;
  }

  filenames() {
    const path = `${this.folder}/${this.identifier}-${Date.now()}.jpg`;
    return {
      path,
      cdn: `${AppSettings.CDN_URL}${path}`,
    };
  }

  uploadImage(file) {
    const filenames = this.filenames(file.name);
    file.cdnPath = filenames.cdn;
    FileUploadService.importLogo(file, filenames.path)
      .then(() => {
        setTimeout(() => {
          this.preloadImage(filenames.cdn);
        }, this.preloadTimeout);
      })
      .catch();
  }

  createLoaderImage() {
    return { url: this.loaderImage, className: 'loader' };
  }

  readFile(file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.readImage(reader.result, this.uploadImage, file);
    };
    reader.readAsDataURL(file);
  }

  readImage(data, callback, originalFile) {
    const img = new Image();
    img.src = data;

    img.onload = () => {
      if (!this.needsResize(img)) {
        callback(originalFile);
        return;
      }
      originalFile.buffer = this.resizeImage(img);
      callback(originalFile);
    };

    img.onerror = () => {
      this.errorCallback('There was a problem with your image. Please try again.');
    };
  }

  needsResize(img) {
    if (img.width <= this.maxWidth && img.height <= this.maxHeight) {
      return false;
    }
    return true;
  }

  resizeImage(img) {
    // this method scales the image down in size maintaining image clarity
    // by using a two step downsize process. It scales it down 50% and then
    // another 50%.

    const dims = this.getDimensions(img);
    const canvas = document.createElement('canvas');
    canvas.width = dims.final.width;
    canvas.height = dims.final.height;
    const ctx = canvas.getContext('2d');

    // step 1
    const oc = document.createElement('canvas');
    const octx = oc.getContext('2d');

    oc.width = dims.step.width;
    oc.height = dims.step.height;
    octx.drawImage(img, 0, 0, oc.width, oc.height);

    // step 2
    octx.drawImage(oc, 0, 0, dims.final.width, dims.final.height);

    ctx.drawImage(oc, 0, 0, dims.final.width, dims.final.height, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL('image/jpeg', 0.85);
  }

  getDimensions(img) {
    // This method gets the proper size dimensions for each step in the
    // scaling down process. It has to calculate based on the aspect ratio
    // in which greater than 1 is taller than wide. In the case that it's
    // taller, we need to limit by the maxHeight instead of maxWidth.
    // `height` and `width` are step 1, and `finalHeight` and `finalWidth`
    // are step 2.
    const aspectRatio = img.height / img.width;
    let height;
    let width;
    let finalHeight;
    let finalWidth;
    if (aspectRatio >= 1) {
      height = Math.floor((img.height - this.maxHeight) / 2) + this.maxHeight;
      width = Math.floor(height / aspectRatio);
      finalHeight = this.maxHeight;
      finalWidth = Math.floor(this.maxHeight / aspectRatio);
    } else {
      width = Math.floor((img.width - this.maxWidth) / 2) + this.maxWidth;
      height = Math.floor(width * aspectRatio);
      finalWidth = this.maxWidth;
      finalHeight = Math.floor(this.maxWidth * aspectRatio);
    }
    return {
      aspectRatio,
      step: {
        height,
        width,
      },
      final: {
        height: finalHeight,
        width: finalWidth,
      },
    };
  }
}
