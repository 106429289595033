import { Theme as theme } from '@spoiler-alert/ui-library';

const styles = {
  quantity: {
    textDecoration: `underline dashed ${theme.pillFontColor}`,
    cursor: 'pointer',
  },
  select__box: {
    order: 0,
    marginRight: '12px !important',
  },
  search: {
    order: 2,
    marginLeft: 'auto',
  },
  search__row: {
    justifyContent: 'flex-start',
  },
  filter__button: {
    order: 3,
  },
  negotiations__wrap: {
    marginBottom: 20,
  },
  'custom-content__container': {
    display: 'flex',
    alignItems: 'center',
  },
  'bulk-action__buttons': {
    order: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 5,
  },
  table_header: {
    color: `${theme.greyDark}`,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: -0.176,
    marginBottom: 12,
  },
  header_row: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .customActions': {
      display: 'flex',
      alignItems: 'center',
      '& .select_box': {
        marginRight: '8px',
      },
      '& .search_bar': {
        marginRight: '8px',
        width: '350px',
      },
      '& button': {
        marginRight: '24px',
      },
    },
    '& .bulkActions': {
      display: 'flex',
      alignItems: 'center',
      '& .clear_all': {
        marginRight: '8px',
      },
    },
  },
  staged_table: {
    marginTop: '16px',
    marginBottom: '24px',
    border: `1px solid ${theme.borderColor}`,
  },
};

export default styles;
