import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { AlertService, Button, ContentCard, MinimalRadioTable, Snackbar } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import routePaths from '../../route-paths';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import { getHandlingMappingsBySiteIdQuery } from '../../graphql/queries';
import truckType from '../../enums/truck-type';
import { UpdateHandlingMappings } from '../../graphql/mutations';
import SmartAwardSettings from './smart-award-settings';
import DeliveryCostSettings from './delivery-cost-settings';
import DistributionListsSettings from './distribution-lists-settings/distribution-lists-settings';
import checkFeature from '../../helpers/check-feature-flag';
import featureFlags from '../../enums/feature-flags';
import subscriptionTier from '../../enums/subscription-tier';
import SettingsLayout from '../../layouts/settings-layout';
import CompanyLogo from './company-logo/company-logo';

const styles = {
  container: {
    paddingTop: 13,
  },
  handlingMappingDisplay: {
    padding: '24px',
  },
  saveHandlingMappings: {
    display: 'flex',
    justifyContent: 'flex-end',
    boxShadow: 'inset 0px 1px 0 0 #d9dee1',
    padding: '24px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '-0.18px',
    textAlign: 'left',
    margin: 0,
  },
  subtitle: {
    fontSize: '14px',
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
    textAlign: 'left',
    margin: 0,
    marginBottom: '24px',
  },
};
const useStyles = createUseStyles(styles);

const OrgSettings = ({ user, history, match }) => {
  const classes = useStyles();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [handlingMappingsCurrent, setHandlingMappingsCurrent] = useState([]);
  const [handlingMappingIdsToUpdate, setHandlingMappingIdsToUpdate] = useState([]);
  const saasLiteCustomer = user.site.subscriptionTier === subscriptionTier.saasLite;
  const { data: handlingMappingQueryData, loading: handlingMappingQueryLoading } = useQuery(getHandlingMappingsBySiteIdQuery, {
    variables: { siteId: user.site._id },
  });
  const [updateHandlingMappings, { loading: updateHandlingMappingsLoading }] = useMutation(UpdateHandlingMappings);

  const handlingMappingsInDB = useMemo(() => {
    if (!handlingMappingQueryData) return [];
    return handlingMappingQueryData?.getHandlingMappingsBySiteId.map((obj) => ({ _id: obj._id, key: obj.name, value: obj.truckType }));
  }, [handlingMappingQueryData?.getHandlingMappingsBySiteId]);

  useEffect(() => {
    TitleService.setTitles('Organization Settings');
    Breadcrumbs.set([
      {
        url: '/organization/settings',
        title: 'Organization Settings',
      },
    ]);
  });

  useEffect(() => {
    if (handlingMappingQueryData) {
      setHandlingMappingsCurrent(
        handlingMappingQueryData?.getHandlingMappingsBySiteId.map((obj) => ({ _id: obj._id, key: obj.name, value: obj.truckType }))
      );
    }
  }, [handlingMappingQueryData?.getHandlingMappingsBySiteId]);

  const changeTruckType = (value, id) => {
    const updatedMappings = handlingMappingsCurrent.map((mapping) => {
      if (mapping._id === id) {
        mapping.value = value;
      }
      return mapping;
    });
    const idsToUpdate = [];
    const handlingMappingsInDBMap = handlingMappingsInDB.reduce((acc, handling) => acc.set(handling._id, handling), new Map());
    updatedMappings.forEach((updatedMapping) => {
      const dbMapping = handlingMappingsInDBMap.get(updatedMapping._id);
      if (dbMapping.value !== updatedMapping.value) {
        idsToUpdate.push(updatedMapping._id);
      }
    });
    setHandlingMappingIdsToUpdate(idsToUpdate);
    setHandlingMappingsCurrent(updatedMappings);
  };

  const handlingMappingsSaveDisabled = () => {
    return handlingMappingIdsToUpdate.length === 0;
  };

  const saveHandlingMappings = () => {
    const handlingsToUpdate = handlingMappingsCurrent
      .filter((handling) => {
        return handlingMappingIdsToUpdate.includes(handling._id);
      })
      .map((handling) => {
        return { handlingId: handling._id, truckType: handling.value };
      });

    return updateHandlingMappings({
      variables: { handlings: handlingsToUpdate },
    })
      .then((res) => {
        if (res.data.updateHandlingMapping.errors && res.data.updateHandlingMapping.errors.length > 0) {
          AlertService.alert({
            type: 'warning',
            message: 'There was an unexpected error saving your changes, please try again. Contact support if this problem persists.',
          });
        } else {
          setShowSuccessMessage(true);
          setHandlingMappingIdsToUpdate([]);
        }
      })
      .catch(() => {
        AlertService.alert({
          type: 'warning',
          message: 'There was an unexpected error saving your changes, please try again. Contact support if this problem persists.',
        });
      });
  };

  const hideSuccessMessage = () => setShowSuccessMessage(false);

  const hasDistributionListsSettings = checkFeature(featureFlags.distributionListSettings);

  const hasCompanyLogo = checkFeature(featureFlags.companyLogo);

  const links = useMemo(() => {
    const _links = [
      {
        label: 'Handling Mapping',
        path: routePaths.orgSettings,
      },
      {
        label: 'Smart Awarding Settings',
        path: `${routePaths.orgSettings}/smart-awarding`,
      },
      {
        label: 'Estimate Delivery Costs',
        path: `${routePaths.orgSettings}/delivery-costs`,
      },
    ];
    if (hasDistributionListsSettings)
      _links.push({
        label: 'Distribution Lists',
        path: `${routePaths.orgSettings}/distribution-lists`,
      });

    if (hasCompanyLogo)
      _links.push({
        label: 'Company Logo',
        path: `${routePaths.orgSettings}/company-logo`,
      });

    return _links;
  }, [hasDistributionListsSettings, hasCompanyLogo]);

  return (
    <div className={classes.container}>
      <SettingsLayout links={links} saasLiteCustomer={saasLiteCustomer}>
        {!handlingMappingQueryLoading && (
          <Switch>
            <Route exact path={routePaths.orgSettings}>
              <ContentCard title="Handling Mapping" noPadding={true} key="handling-mapping">
                <div className={classes.handlingMappingDisplay}>
                  <h1 className={classes.title}>Handling Mapping</h1>
                  <div className={classes.subtitle}>
                    Use this space to map your uploaded handling values to a corresponding truck type. This ensures accurate truck types are displayed
                    during awarding.
                  </div>
                  <MinimalRadioTable
                    keyHeader="Uploaded Handling Value"
                    valueHeader="Truck Type"
                    possibleValues={Object.values(truckType)}
                    data={handlingMappingsCurrent}
                    onChange={changeTruckType}
                  />
                </div>
                <div className={classes.saveHandlingMappings}>
                  <Button
                    onClick={saveHandlingMappings}
                    primary
                    disabled={handlingMappingsSaveDisabled()}
                    loading={updateHandlingMappingsLoading}
                    loadingText="Saving"
                  >
                    Save
                  </Button>
                </div>
              </ContentCard>
            </Route>
            <Route path={`${routePaths.orgSettings}/smart-awarding`}>
              {!saasLiteCustomer && (
                <ContentCard title="Smart Awarding Settings" noPadding={true} key="smart-awarding-settings">
                  <SmartAwardSettings user={user} />
                </ContentCard>
              )}
            </Route>
            <Route path={`${routePaths.orgSettings}/delivery-costs`}>
              {!saasLiteCustomer && (
                <ContentCard title="Estimate Delivery Costs" noPadding={true} key="delivery-cost-settings">
                  <DeliveryCostSettings user={user} />
                </ContentCard>
              )}
            </Route>
            <Route path={`${routePaths.orgSettings}/distribution-lists`}>
              {hasDistributionListsSettings && (
                <ContentCard title="Distribution Lists" key="distribution-lists-settings" noPadding={true} height="100vh">
                  <DistributionListsSettings user={user} history={history} match={match} />
                </ContentCard>
              )}
            </Route>
            <Route path={`${routePaths.orgSettings}/company-logo`}>
              <ContentCard title="Company Logo" noPadding={true} key="company-logo">
                <CompanyLogo user={user} />
              </ContentCard>
            </Route>
          </Switch>
        )}
      </SettingsLayout>
      <Snackbar show={showSuccessMessage} callback={hideSuccessMessage} message="Settings saved successfully" />
    </div>
  );
};

OrgSettings.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default OrgSettings;
