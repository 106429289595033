import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Theme as theme, MessageAddIcon, MessageAddedIcon, MiniTooltip, ClickAwayListener, AlertService } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { createNegotiationStagedListings } from '../../graphql/mutations';
import { StagedNegotiationSummaryQuery } from '../../graphql/queries';

const styles = {
  cellContainer: {
    position: 'relative',
    margin: '-4px 0 -10px 0',
  },
  rowButton: {
    right: '-10px',
    position: 'relative',
  },
  textAreaContainer: {
    position: 'absolute',
    display: 'flex',
    top: '-1px',
    right: '-8px',
    width: '483px',
    height: '147px',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: '16px',
    borderRadius: '2px',
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
    border: `solid 1px ${theme.borderColor}`,
    backgroundColor: theme.white,
  },
  characterCount: {
    width: '100%',
    fontSize: '12px',
    textTransform: 'uppercase',
    textAlign: 'right',
    color: ({ limitExceeded }) => (limitExceeded ? theme.red : theme.grey80),
  },
  messageInput: {
    width: '451px',
    height: '69px',
    margin: '4px 0 0',
    padding: '13px 12px 16px 16px',
    borderRadius: '2px',
    border: ({ limitExceeded }) => (limitExceeded ? `solid 1px ${theme.red}` : `solid 1px ${theme.grey30}`),
    outline: 'none',
    resize: 'none',
    backgroundColor: theme.white,
  },
  saveButton: {
    textAlign: 'right',
    backgroundColor: 'transparent',
    color: theme.teal,
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    padding: '0',
    fontSize: '12px',
    marginTop: '12px',
    '&[disabled]': {
      color: ({ isSaved }) => (isSaved ? theme.teal : theme.grey30),
      cursor: 'not-allowed',
    },
  },
};

const NegotiationMessage = ({ row, hovered, defaultMessage, saveOverride, parentRef }) => {
  const useStyles = createUseStyles(styles);

  const textAreaRef = useRef();
  const messageBoxRef = useRef();

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [updateNegotiationStagedListing, { loading }] = useMutation(createNegotiationStagedListings);
  const [isSaved, setIsSaved] = useState(false);
  const [touched, setTouched] = useState(false);
  const [isOverFlowing, setIsOverFlowing] = useState(false);

  const classes = useStyles({ hovered, limitExceeded: message?.length > 120, isSaved });

  const checkOverflow = useCallback(() => {
    if (parentRef.current && messageBoxRef.current && !isOverFlowing) {
      const parentRect = parentRef.current.getBoundingClientRect();
      const childRect = messageBoxRef.current.getBoundingClientRect();

      // Check if the child is overflowing the parent horizontally
      const isOverflowingVertically = childRect.top < parentRect.top || childRect.bottom > parentRect.bottom;

      setIsOverFlowing(isOverflowingVertically);
    }
  }, [parentRef, isOverFlowing]);

  useEffect(() => {
    if (showMessage) {
      checkOverflow();
      window.addEventListener('resize', checkOverflow);
    }

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [checkOverflow, showMessage]);

  useEffect(() => {
    if (showMessage) textAreaRef.current.focus();
  }, [showMessage]);

  useEffect(() => {
    setMessage(row.negotiationListing?.reason || defaultMessage || '');
    if (defaultMessage) setTouched(true);
  }, [row.negotiationListing?.reason, defaultMessage]);

  const handleChange = (e) => {
    setTouched(true);
    setIsSaved(false);
    setMessage(e.target.value);
  };

  const saveMessage = (event) => {
    event.stopPropagation();
    if (isSaved) return;
    setShowMessage(true);
    updateNegotiationStagedListing({
      variables: {
        counterOffers: [
          {
            offerListingId: row.offerListing?._id,
            counterOfferQuantity: row.negotiationListing.quantity,
            counterOfferPrice: row.negotiationListing.suggestedUnitPrice,
            reason: message,
            // Not passing in anchor or percentage since this is just a reason change. The NSL creator will keep the existing anchor/percentage.
          },
        ],
      },
      refetchQueries: [{ query: StagedNegotiationSummaryQuery }],
    })
      .then((response) => {
        const result = response.data.createNegotiationStagedListings;
        if (result.errors.length > 0) {
          const msg =
            result.errors[0].code === 'NEGOTIATION_PUBLISHED'
              ? result.errors[0].message
              : 'We were unable to set the negotiation reason due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.';
          throw new Error(msg);
        } else {
          setTouched(false);
          setIsSaved(true);
          setMessage(response.data.createNegotiationStagedListings.reason);
        }
      })
      .catch((error) => {
        AlertService.alert({ type: 'warning', message: <span>{error.message}</span> });
        setIsSaved(false);
      });
  };

  const colapseForm = () => {
    setShowMessage(false);
    setIsSaved(false);
  };

  const buttonText = () => {
    if (loading) {
      return 'Saving';
    }
    if (isSaved && !touched) {
      return 'Saved!';
    }
    return 'Save';
  };

  const handleShowMessage = (event) => {
    event.stopPropagation();
    setShowMessage(true);
  };

  const getIcon = () => {
    if (message) {
      return MessageAddedIcon;
    }
    return MessageAddIcon;
  };

  return (
    <div className={classes.cellContainer}>
      <MiniTooltip text={message ? 'Edit message' : 'Add message'} position="top">
        <Button className={classes.rowButton} onClick={handleShowMessage} icon={getIcon()} resting={!hovered}></Button>
      </MiniTooltip>
      {showMessage && (
        <ClickAwayListener onClickAway={colapseForm}>
          <div
            className={classes.textAreaContainer}
            style={{ transform: isOverFlowing ? 'translateY(-100px)' : 'none' }}
            onClick={(e) => e.stopPropagation()}
            ref={messageBoxRef}
          >
            <div className={classes.characterCount}>{`${message?.length || 0} / 120 characters`}</div>
            <textarea
              name="negotiation-message"
              data-element="text-area"
              value={message}
              onChange={handleChange}
              className={classes.messageInput}
              ref={textAreaRef}
              onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
              onClick={(e) => e.stopPropagation()}
            ></textarea>
            <button
              type="submit"
              className={classes.saveButton}
              onClick={(e) => {
                if (saveOverride) {
                  saveOverride(message);
                  setTouched(false);
                  setIsSaved(true);
                  return;
                }
                saveMessage(e);
              }}
              disabled={!touched || message?.length > 120}
            >
              {buttonText()}
            </button>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

NegotiationMessage.propTypes = {
  row: PropTypes.object,
  hovered: PropTypes.bool,
  defaultMessage: PropTypes.string,
  saveOverride: PropTypes.func,
  parentRef: PropTypes.object,
};
export default NegotiationMessage;
