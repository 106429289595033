import React, { useEffect, useState } from 'react';
import { ModalContent, Button, Theme, InformationIconSmall, ModalFooter, AlertService, Modal, OverlayService } from '@spoiler-alert/ui-library';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { PossibleNegotiationsSummary, UserQuery } from '../../graphql/queries';
import { useMutation, useQuery } from '@apollo/client';
import StaticLoading from '../../pages/static-loading';
import { SetNegotiationRules } from '../../graphql/mutations';

const useStyles = createUseStyles({
  container: {
    width: '750px',
  },
  row: {
    display: 'flex',
  },
  title: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '28px',
    marginBottom: 24,
  },
  subTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.176px',
    marginBottom: '8px',
  },
  infoText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.176px',
  },
  infoBox: {
    backgroundColor: Theme.grey10,
    padding: '12px',
    width: '50%',
    '&:last-child': {
      marginLeft: '12px',
    },
  },
  actionBox: {
    extend: 'infoBox',
    width: '55%',
    '&:last-child': {
      width: '45%',
      marginLeft: '12px',
    },
  },
  infoTitle: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.072px',
    textTransform: 'uppercase',
  },
  infoBody: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.084px',
  },
  lineBreak: {
    margin: '23px 0px 24px 0px',
    borderTop: `1px solid ${Theme.borderColor}`,
  },
  inputLine: {
    border: 'none',
    borderBottom: `solid 2px ${Theme.grey50}`,
    backgroundColor: Theme.grey10,
    width: 40,
    '&:focus': {
      outline: 'none',
      padding: '0 0 4px 0',
    },
  },
  inputLineFocused: {
    extend: 'inputLine',
    padding: '0 0 4px 0',
    '&:focus': {
      padding: 'none',
    },
  },
  inputLineError: {
    extend: 'inputLineFocused',
    borderBottom: `solid 2px ${Theme.red}`,
  },
  errorText: {
    marginTop: '4px',
    display: 'flex',
    textAlign: 'flex-start',
    color: Theme.red,
    fontSize: 12,
    fontWeight: 400,
    '& svg': {
      height: 16,
      width: 16,
      fill: Theme.red,
      marginRight: 4,
    },
  },
  link: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.176px',
    color: Theme.teal,
    cursor: 'pointer',
  },
});

const NegotiationSettingsModalContent = ({ history, modalOpen, onSave, onCancel }) => {
  const classes = useStyles();
  const [lowerCutoff, setLowerCutoff] = useState('');
  const [upperCutoff, setUpperCutoff] = useState('');
  const [counterOfferNumber, setCounterOfferNumber] = useState('');
  const { data, loading } = useQuery(PossibleNegotiationsSummary, { fetchPolicy: 'network-only' });
  const [setNegotiationRules, { loading: loadingSetRules }] = useMutation(SetNegotiationRules);

  const isReservePriceEnabled = data?.possibleNegotiationsSummaryQuery?.isReservePriceEnabled;
  const counterOfferError = counterOfferNumber && (upperCutoff === 0 || upperCutoff) && counterOfferNumber <= upperCutoff;
  const upperCutoffError = (upperCutoff === 0 || upperCutoff) && (lowerCutoff === 0 || lowerCutoff) && upperCutoff < lowerCutoff;
  const invalidRules = !lowerCutoff || !upperCutoff || !counterOfferNumber || counterOfferError || upperCutoffError;

  useEffect(() => {
    const { negotiationLowerCutoff, negotiationUpperCutoff, counterOfferNumber } = data?.possibleNegotiationsSummaryQuery || {};

    if (negotiationLowerCutoff || negotiationLowerCutoff === 0) {
      setLowerCutoff(negotiationLowerCutoff);
    }

    if (negotiationUpperCutoff || negotiationUpperCutoff === 0) {
      setUpperCutoff(negotiationUpperCutoff);
    }

    if (counterOfferNumber) {
      setCounterOfferNumber(counterOfferNumber);
    }
  }, [data]);

  const saveRules = async () => {
    const result = await setNegotiationRules({
      variables: {
        negotiationLowerCutoff: parseInt(lowerCutoff, 10),
        negotiationUpperCutoff: parseInt(upperCutoff, 10),
        counterOfferNumber: parseInt(counterOfferNumber, 10),
      },
      refetchQueries: [{ query: UserQuery }],
    });
    if (result.errors || result.data.setNegotiationRules.errors.length > 0) {
      return AlertService.alert({
        type: 'warning',
        message: (
          <span>
            We were unable to save the rules due to an unknown error. Our team has been notified and are looking into the issue. Please contact
            customer support if the issue persists.
          </span>
        ),
        autoDismiss: true,
      });
    }
    onSave();
    return AlertService.alert({
      type: 'success',
      message: <span>Successfully saved negotiation rules</span>,
      autoDismiss: true,
    });
  };

  const handleLowerCutoffChange = (ev) => {
    if (isNaN(ev.target.value)) return;
    if (ev.target.value < 0 || ev.target.value > 100) return;
    setLowerCutoff(ev.target.value === '' ? '' : parseInt(ev.target.value, 10));
  };

  const handleUpperCutoffChange = (ev) => {
    if (isNaN(ev.target.value)) return;
    if (ev.target.value < 0 || ev.target.value > 100) return;
    setUpperCutoff(ev.target.value === '' ? '' : parseInt(ev.target.value, 10));
  };

  const handleCounterOfferChange = (ev) => {
    if (isNaN(ev.target.value)) return;
    if (ev.target.value === '0') return;
    setCounterOfferNumber(ev.target.value === '' ? '' : parseInt(ev.target.value, 10));
  };

  const counterOfferClass = () => {
    if (counterOfferError && counterOfferNumber) return classes.inputLineError;
    if (counterOfferNumber) return classes.inputLineFocused;
    return classes.inputLine;
  };

  const upperCutoffClass = () => {
    if (upperCutoffError && upperCutoff) return classes.inputLineError;
    if (upperCutoff) return classes.inputLineFocused;
    return classes.inputLine;
  };

  const navigateToItems = () => {
    OverlayService.hide();
    history.push('/items');
  };

  const infoBox = (title, body) => {
    return (
      <div className={classes.infoBox}>
        <h1 className={classes.infoTitle}>{title}</h1>
        <p className={classes.infoBody}>{body}</p>
      </div>
    );
  };

  const inputOptions = () => {
    return (
      <div>
        <h1 className={classes.subTitle}>Reserve Price</h1>
        <p className={classes.infoText}>
          Set your upper and lower threshold, as well as your counter offer value for negotiating offers below reserve price. These values will be
          used to limit negotiation suggestions.
        </p>
        <div className={classes.row}>
          <div className={classes.actionBox}>
            <h1 className={classes.infoTitle}>Stage offers that are</h1>
            <div>
              Between&nbsp;&nbsp;
              <input
                tabIndex={0}
                type="text"
                className={lowerCutoff ? classes.inputLineFocused : classes.inputLine}
                value={lowerCutoff}
                onChange={handleLowerCutoffChange}
              />
              %&nbsp;&nbsp; and{' '}
              <input tabIndex={0} type="text" className={upperCutoffClass()} value={upperCutoff} onChange={handleUpperCutoffChange} /> %&nbsp;&nbsp;
              of reserve price.
            </div>
            {upperCutoffError && (
              <div className={classes.errorText}>
                <InformationIconSmall /> Upper cutoff percent must be higher than {lowerCutoff}%
              </div>
            )}
          </div>
          <div className={classes.actionBox}>
            <h1 className={classes.infoTitle}>set counter offers to</h1>
            <div>
              <input type="text" className={counterOfferClass()} value={counterOfferNumber} onChange={handleCounterOfferChange} />
              %&nbsp;&nbsp; of reserve price.
              {counterOfferError && (
                <div className={classes.errorText}>
                  <InformationIconSmall /> Counter offer percent must be higher than {upperCutoff}%
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const noReservePrice = () => {
    return (
      <div>
        <h1 className={classes.subTitle}>Reserve Price</h1>
        <div className={classes.infoText}>
          No reserve price is set. Set a reserve price on the{' '}
          <span className={classes.link} onClick={navigateToItems}>
            items screen
          </span>
          , or contact your customer success manager for more information.
        </div>
      </div>
    );
  };

  const displayDetails = () => {
    if (loading) {
      return (
        <div>
          <StaticLoading />
        </div>
      );
    } else {
      return isReservePriceEnabled ? inputOptions() : noReservePrice();
    }
  };

  return (
    <Modal open={modalOpen} onHide={() => onCancel()} closeOnEsc closeOnOutsideClick>
      <div className={classes.container}>
        <ModalContent>
          <div data-testid="modal-title" className={classes.title}>
            Negotiation Settings
          </div>
          <div data-testid="modal-body" className={classes.modal__body}>
            <h1 className={classes.subTitle}>Highest historical transacted price</h1>
            <div className={classes.row}>
              {infoBox(
                'stage offers that are',
                'Below the highest historical transaction price for the customer for this item in the past 540 days.'
              )}
              {infoBox('set counter offers to', 'The customer’s highest historical transaction price for the item.')}
            </div>
            <div className={classes.lineBreak} />
            {displayDetails()}
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            testId="cancel"
            className={classes.modal__cancel}
            type="button"
            onClick={() => onCancel()}
            secondary
            disabled={loading || loadingSetRules}
          >
            Cancel
          </Button>
          <Button
            testId="submit"
            type="submit"
            primary
            loadingText="Saving Rules"
            loading={loading || loadingSetRules}
            disabled={invalidRules || loading || loadingSetRules}
            onClick={saveRules}
          >
            Save Rules
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

NegotiationSettingsModalContent.propTypes = {
  history: PropTypes.object,
  modalOpen: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default NegotiationSettingsModalContent;
