export default function filterCounter(filters) {
  return Object.keys(filters).filter((fkey) => {
    const filter = filters[fkey];
    if (Array.isArray(filter)) {
      return filter.length > 0;
    }
    if (filter === true) return true;
    if (filter.start !== undefined || filter.end !== undefined) return true;
    return false;
  }).length;
}
