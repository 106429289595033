import { gql } from '@apollo/client';

export const DeletePost = gql`
  mutation DeletePost($id: String!) {
    deletePost(postId: $id) {
      _id
      transactedAt
      source
      category
      quantity
      unitOfMeasure
      destination
      description
      audience
      bestByDate
      mustGoByDate
      claimDate
      completedAtDate
      supplierSiteName
      status
      quality
      images
      maxDeliveryRadiusMiles
      supplierId
      recipientId
      isVendorReturn
      unitPrice
      isForSale
      isDonation
      isGiveaway
      supplierSite {
        _id
        siteName
      }
      outletSite {
        _id
        siteName
      }
      itemName
      actions
      location {
        fullAddress
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export const VerifyPost = gql`
  mutation VerifyPost($id: String!, $completedAt: Date!, $quantity: Int!) {
    verifyPost(postId: $id, completedAt: $completedAt, quantity: $quantity) {
      _id
      createdAt
      source
      category
      quantity
      unitOfMeasure
      destination
      description
      audience
      bestByDate
      mustGoByDate
      claimDate
      completedAtDate
      supplierSiteName
      status
      quality
      images
      maxDeliveryRadiusMiles
      supplierId
      recipientId
      isVendorReturn
      unitPrice
      isForSale
      isDonation
      isGiveaway
      supplierSite {
        _id
        siteName
      }
      outletSite {
        _id
        siteName
      }
      itemName
      actions
      location {
        fullAddress
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export const VerifyPosts = gql`
  mutation VerifyPosts($ids: [String]) {
    batchVerifyPosts(postIds: $ids) {
      _id
      createdAt
      source
      category
      quantity
      unitOfMeasure
      destination
      description
      audience
      bestByDate
      mustGoByDate
      claimDate
      completedAtDate
      supplierSiteName
      status
      quality
      images
      maxDeliveryRadiusMiles
      supplierId
      recipientId
      isVendorReturn
      unitPrice
      isForSale
      isDonation
      isGiveaway
      supplierSite {
        _id
        siteName
      }
      outletSite {
        _id
        siteName
      }
      itemName
      actions
      location {
        fullAddress
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export const RejectPosts = gql`
  mutation RejectPosts($ids: [String]) {
    batchRejectPosts(postIds: $ids) {
      _id
      createdAt
      source
      category
      quantity
      unitOfMeasure
      destination
      description
      audience
      bestByDate
      mustGoByDate
      claimDate
      completedAtDate
      supplierSiteName
      status
      quality
      images
      maxDeliveryRadiusMiles
      supplierId
      recipientId
      isVendorReturn
      unitPrice
      isForSale
      isDonation
      isGiveaway
      supplierSite {
        _id
        siteName
      }
      outletSite {
        _id
        siteName
      }
      itemName
      actions
      location {
        fullAddress
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export const UnclaimPost = gql`
  mutation UnclaimPost($id: String!, $reasonCode: Int!, $reasonDetails: String) {
    unclaimPost(postId: $id, reasonCode: $reasonCode, reasonDetails: $reasonDetails) {
      _id
      createdAt
      source
      category
      quantity
      unitOfMeasure
      destination
      description
      audience
      bestByDate
      mustGoByDate
      claimDate
      completedAtDate
      supplierSiteName
      status
      quality
      images
      maxDeliveryRadiusMiles
      supplierId
      recipientId
      isVendorReturn
      unitPrice
      isForSale
      isDonation
      isGiveaway
      supplierSite {
        _id
        siteName
      }
      outletSite {
        _id
        siteName
      }
      itemName
      actions
      location {
        fullAddress
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export const createOrUpdateTransaction = gql`
  mutation createOrUpdateTransaction(
    $postId: String
    $itemId: String!
    $quantity: safeFloat
    $quality: String!
    $unitOfMeasure: String!
    $isVendorReturn: Boolean
    $destination: String!
    $outlet: String
    $outletSiteId: String
    $transactionDate: Date!
    $transactionPrice: safeFloat
  ) {
    createOrUpdateTransaction(
      postId: $postId
      itemId: $itemId
      quantity: $quantity
      quality: $quality
      unitOfMeasure: $unitOfMeasure
      isVendorReturn: $isVendorReturn
      destination: $destination
      outlet: $outlet
      outletSiteId: $outletSiteId
      transactionDate: $transactionDate
      transactionPrice: $transactionPrice
    ) {
      errors
    }
  }
`;
