import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { EmptyStateIcon, AddContactIcon, Button, Theme as theme, EmailCard, DLCard, AlertService } from '@spoiler-alert/ui-library';
import { useQuery, useMutation } from '@apollo/client';
import { event } from 'react-fullstory';
import { TitleService } from '../../services';
import { DistributionListsByBuyerAndSellerQuery } from '../../graphql/queries';
import { AddBSRContact, EditBSRContact, DeleteBSRContact } from '../../graphql/mutations';
import { Breadcrumbs } from '../../store';
import AddContactModal from './add-contact-modal';
import EditContactModal from './edit-contact-modal';
import DeleteContactModal from './delete-contact-modal';
import ContactTypesEnum from '../../enums/contact-types';
import ErrorCodesEnum from '../../enums/error-codes';
import routePaths from '../../route-paths';
import DownloadSheetService from '../../services/download-sheet-service';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  infoContainer: {
    border: `1px solid ${theme.grey30}`,
    borderRadius: '2px',
    flexDirection: 'column',
    display: 'flex',
    padding: 24,
    marginBottom: 24,
  },
  zeroStateText: {
    textAlign: 'center',
    fontSize: 16,
    color: theme.greyDark,
    width: 535,
    margin: 'auto',
    marginBottom: 16,
  },
  zeroStateIcon: {
    margin: 'auto',
    marginBottom: 16,
    width: '106.8px',
    height: '86.9px',
  },
  infoTitleText: {
    color: theme.greyDark,
    fontWeight: 500,
    fontSize: 16,
    marginTop: 4,
  },
  infoButton: {
    width: 109,
    height: 36,
    margin: 'auto',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  },
  infoButtonDisabled: {
    cursor: 'not-allowed',
  },
  contentContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  sectionTitle: {
    color: theme.grey,
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: 12,
    margin: '12px 0 -4px',
  },
  customerTitle: {
    color: theme.grey,
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: 12,
    margin: '4px 0 -4px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerButton: {
    height: 36,
  },
  noContactsContainer: {
    display: 'flex',
    marginBottom: 12,
    marginTop: 4,
  },
  noContacts: {
    fontSize: 16,
  },
  noContactsLink: {
    fontSize: 16,
    color: theme.teal,
    marginLeft: 2,
    cursor: 'pointer',
  },
  exportContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 24,
  },
});

const CustomerProfileDetail = ({ user, match, history }) => {
  const classes = useStyles();
  const [addContactModalOpen, setAddContactModalOpen] = useState(false);
  const [editContactModalOpen, setEditContactModalOpen] = useState(false);
  const [deleteContactModalOpen, setDeleteContactModalOpen] = useState(false);
  const [editContactEmail, setEditContactEmail] = useState();
  const [editContactType, setEditContactType] = useState();
  const [editContactDistributionListsUpdated, setEditContactDistributionListsUpdated] = useState(false);
  const [addContactType, setAddContactType] = useState();
  const [exporting, setExporting] = useState(false);

  const { data, refetch } = useQuery(DistributionListsByBuyerAndSellerQuery, {
    variables: { buyerSiteName: match.params.buyerSiteName },
    fetchPolicy: 'no-cache',
  });
  const [addBSRContact, { loading: addBSRContactLoading }] = useMutation(AddBSRContact);
  const [editBSRContact, { loading: editBSRContactLoading }] = useMutation(EditBSRContact);
  const [deleteBSRContact, { loading: deleteBSRContactLoading }] = useMutation(DeleteBSRContact);
  const [addContactDistributionLists, setAddContactDistributionLists] = useState(
    data?.distributionListsByBuyerAndSeller.distributionLists.map((dl) => ({ _id: dl._id, key: dl.name, value: [] }))
  );
  const [editContactDistributionLists, setEditContactDistributionLists] = useState(
    data?.distributionListsByBuyerAndSeller.distributionLists.map((dl) => ({ _id: dl._id, key: dl.name, value: [] }))
  );
  const [deleteContactDistributionLists, setDeleteContactDistributionLists] = useState(
    data?.distributionListsByBuyerAndSeller.distributionLists.map((dl) => ({ _id: dl._id, key: dl.name, value: [] }))
  );

  useEffect(() => {
    setAddContactDistributionLists(data?.distributionListsByBuyerAndSeller.distributionLists.map((dl) => ({ _id: dl._id, key: dl.name, value: [] })));
    setEditContactDistributionLists(
      data?.distributionListsByBuyerAndSeller.distributionLists.map((dl) => ({ _id: dl._id, key: dl.name, value: [] }))
    );
    setDeleteContactDistributionLists(
      data?.distributionListsByBuyerAndSeller.distributionLists.map((dl) => ({ _id: dl._id, key: dl.name, value: [] }))
    );
  }, [data]);

  useEffect(() => {
    TitleService.setTitles(`Customer profiles / ${match.params.buyerSiteName}`);
    Breadcrumbs.set([
      {
        url: `/customers`,
        title: `Customer profiles`,
      },
      {
        url: match.url,
        title: match.params.buyerSiteName,
      },
    ]);
  }, []);

  const handleEmailCardEdit = (email) => {
    setEditContactEmail(email);
    setEditContactType(data.distributionListsByBuyerAndSeller.contacts.find((contact) => email === contact.email).contactType);
    setEditContactDistributionLists(
      data?.distributionListsByBuyerAndSeller.distributionLists.map((dl) => {
        const selectedValues = [];
        if (dl.offerEmailContacts?.includes(email)) selectedValues.push('Offer Sheets');
        if (dl.awardEmailContacts?.includes(email)) selectedValues.push('Award Sheets');
        return { _id: dl._id, key: dl.name, value: selectedValues };
      })
    );
    setEditContactModalOpen(!editContactModalOpen);
  };

  const renderExternalContacts = () => {
    return data.distributionListsByBuyerAndSeller.contacts.map((contact, index) =>
      contact.contactType.toLowerCase() === 'customer' ? (
        <EmailCard key={index} email={contact.email} borderColor={theme.teal} backgroundColor={theme.teal5} handleEdit={handleEmailCardEdit} />
      ) : null
    );
  };

  const renderInternalContacts = () => {
    return data.distributionListsByBuyerAndSeller.contacts.map((contact, index) =>
      contact.contactType.toLowerCase() === 'internal' ? (
        <EmailCard key={index} email={contact.email} borderColor={theme.green} backgroundColor={theme.green5} handleEdit={handleEmailCardEdit} />
      ) : null
    );
  };

  const handleDlCardClick = (listId) => {
    event('Selected DL from Customer Profile');
    history.push(`${routePaths.orgSettings}/distribution-lists/edit/${listId}`);
  };

  const renderDistributionLists = () => {
    const dls = data.distributionListsByBuyerAndSeller.distributionLists
      .sort((a, b) => {
        const nameA = a?.name?.toUpperCase(); // ignore upper and lowercase
        const nameB = b?.name?.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      })
      .map((distributionList, index) => (
        <DLCard
          key={index}
          name={distributionList.name}
          customerCount={distributionList.customerCount}
          lastUsed={distributionList.lastUsed}
          handleEdit={() => handleDlCardClick(distributionList._id)}
        />
      ));
    return dls;
  };
  const updateDls = (dls, option, id) => {
    const dlToUpdate = dls.find((dl) => dl._id === id);
    const updatedDlValue = dlToUpdate.value.filter((updatedOption) => updatedOption === option);
    if (!updatedDlValue.length) dlToUpdate.value.push(option);
    else dlToUpdate.value = dlToUpdate.value.filter((value) => value !== option);
    return dls;
  };

  const addContactOnChange = (option, id) => {
    const dls = [...addContactDistributionLists];
    const updatedDls = updateDls(dls, option, id);
    setAddContactDistributionLists(updatedDls);
  };

  const editContactOnChange = (option, id) => {
    const dls = [...editContactDistributionLists];
    const updatedDls = updateDls(dls, option, id);
    setEditContactDistributionListsUpdated(true);
    setEditContactDistributionLists(updatedDls);
  };

  const handleAdd = async (email, contactType, dls) => {
    let error;
    const bsrContact = {
      email,
      contactType,
      distributionLists: dls.map((dl) => ({
        distributionListId: dl._id,
        offerSheets: dl.value.includes('Offer Sheets'),
        awardSheets: dl.value.includes('Award Sheets'),
      })),
      buyerSiteName: match.params.buyerSiteName,
    };
    await addBSRContact({
      variables: {
        bsrContact,
      },
    }).then((response) => {
      refetch();
      if (!response.data.addBSRContact.errors.length) {
        AlertService.alert({ type: 'success', message: <span>{email} has been added.</span>, autoDismiss: true, dismissDelay: 3000 });
        setAddContactDistributionLists(
          data?.distributionListsByBuyerAndSeller.distributionLists.map((dl) => ({ _id: dl._id, key: dl.name, value: [] }))
        );
        setAddContactModalOpen(false);
      } else {
        [error] = response.data.addBSRContact.errors;
        if (error.code !== ErrorCodesEnum.DUPLICATE_EMAIL) {
          AlertService.alert({
            type: 'warning',
            message: <span>{response.data.addBSRContact.errors[0].message}</span>,
            autoDismiss: true,
            dismissDelay: 3000,
          });
        }
      }
    });

    return error;
  };

  const handleEdit = async (email, contactType, dls, emailToUpdate) => {
    let error;
    const bsrContact = {
      email: email !== emailToUpdate ? email : null,
      contactType,
      distributionLists: dls.map((dl) => ({
        distributionListId: dl._id,
        offerSheets: dl.value.includes('Offer Sheets'),
        awardSheets: dl.value.includes('Award Sheets'),
      })),
      emailToUpdate,
      buyerSiteName: match.params.buyerSiteName,
    };
    await editBSRContact({
      variables: {
        bsrContact,
      },
    }).then((response) => {
      refetch();
      if (!response.data.editBSRContact.errors.length || response.data.editBSRContact.errors.length === 0) {
        setEditContactDistributionLists(
          data?.distributionListsByBuyerAndSeller.distributionLists.map((dl) => {
            const selectedValues = [];
            if (dl.offerEmailContacts?.includes(email)) selectedValues.push('Offer Sheets');
            if (dl.awardEmailContacts?.includes(email)) selectedValues.push('Award Sheets');
            return { _id: dl._id, key: dl.name, value: selectedValues };
          })
        );
        AlertService.alert({ type: 'success', message: <span>{email} has been updated.</span>, autoDismiss: true, dismissDelay: 3000 });
        setEditContactModalOpen(false);
      } else {
        [error] = response.data.editBSRContact.errors;
        if (error.code !== ErrorCodesEnum.DUPLICATE_EMAIL) {
          AlertService.alert({
            type: 'warning',
            message: <span>{response.data.editBSRContact.errors[0].message}</span>,
            autoDismiss: true,
            dismissDelay: 3000,
          });
        }
      }
    });

    return error;
  };

  const handleDelete = (contactEmail, contactType) => {
    const bsrContact = {
      contactType,
      distributionLists: deleteContactDistributionLists.map((dl) => ({
        distributionListId: dl._id,
      })),
      emailToUpdate: contactEmail,
      buyerSiteName: match.params.buyerSiteName,
    };
    deleteBSRContact({
      variables: {
        bsrContact,
      },
    }).then((response) => {
      refetch();
      if (!response.data.deleteBSRContact.errors.length) {
        AlertService.alert({ type: 'success', message: <span>{contactEmail} has been deleted.</span>, autoDismiss: true, dismissDelay: 3000 });
      } else {
        AlertService.alert({
          type: 'warning',
          message: <span>{response.data.deleteBSRContact.errors[0].message}</span>,
          autoDismiss: true,
          dismissDelay: 3000,
        });
      }
      setDeleteContactModalOpen(false);
      setEditContactModalOpen(false);
    });
  };

  const addContactOnHide = () => {
    setAddContactModalOpen(false);
    setAddContactDistributionLists(data?.distributionListsByBuyerAndSeller.distributionLists.map((dl) => ({ _id: dl._id, key: dl.name, value: [] })));
  };

  const editContactOnHide = () => {
    setEditContactModalOpen(false);
    setEditContactDistributionListsUpdated(false);
  };

  const handleCreateDistributionList = () => {
    history.push(`${routePaths.orgSettings}/distribution-lists/new`);
  };

  const handleExport = async () => {
    setExporting(true);
    await DownloadSheetService.downloadCustomerProfilesSheet([data?.distributionListsByBuyerAndSeller.buyerSiteId], user.site.siteName);
    setExporting(false);
  };

  return (
    <>
      <div className={classes.exportContainer}>
        <Button onClick={handleExport} loading={exporting} disabled={exporting} loadingText="Exporting">
          Export Profile (xlsx)
        </Button>
      </div>
      <div className={classes.container}>
        <div className={classes.infoContainer}>
          <div className={classes.headerContainer}>
            <span className={classes.infoTitleText}>Contacts</span>
            {data?.distributionListsByBuyerAndSeller.contacts.length > 0 && (
              <Button secondary className={classes.headerButton} onClick={() => setAddContactModalOpen(!addContactModalOpen)}>
                Add Contact
              </Button>
            )}
          </div>
          {data?.distributionListsByBuyerAndSeller.contacts.length === 0 && (
            <>
              <AddContactIcon className={classes.zeroStateIcon} />
              <span className={classes.zeroStateText}>
                Add the {match.params.buyerSiteName} contacts who you want to sell inventory to, and internal contacts who need to be cc’d on offer
                and award information. These contacts can then be added to your distribution lists.
              </span>
              <Button className={classes.infoButton} onClick={() => setAddContactModalOpen(!addContactModalOpen)}>
                Add Contact
              </Button>
            </>
          )}
          {(data?.distributionListsByBuyerAndSeller.contacts.filter((contact) => contact.contactType.toLowerCase() === 'customer').length > 0 && (
            <>
              <span className={classes.customerTitle}>Customer</span>
              <div className={classes.contentContainer}>{renderExternalContacts()}</div>
            </>
          )) ||
            (data?.distributionListsByBuyerAndSeller.contacts.length > 0 && (
              <>
                <span className={classes.customerTitle}>Customer</span>
                <span className={classes.noContactsContainer}>
                  <span className={classes.noContacts}>You haven’t added any customer contacts yet.&nbsp;</span>
                  <span
                    className={classes.noContactsLink}
                    onClick={() => {
                      setAddContactModalOpen(!addContactModalOpen);
                      setAddContactType(ContactTypesEnum.customer);
                    }}
                  >
                    Add a customer contact
                  </span>
                  <span className={classes.noContacts}>.</span>
                </span>
              </>
            ))}
          {(data?.distributionListsByBuyerAndSeller.contacts.filter((contact) => contact.contactType.toLowerCase() === 'internal').length > 0 && (
            <>
              <span className={classes.sectionTitle}>Internal</span>
              <div className={classes.contentContainer}>{data && renderInternalContacts()}</div>
            </>
          )) ||
            (data?.distributionListsByBuyerAndSeller.contacts.length > 0 && (
              <>
                <span className={classes.sectionTitle}>Internal</span>
                <span className={classes.noContactsContainer}>
                  <span className={classes.noContacts}>You haven’t added any internal contacts yet.&nbsp;</span>
                  <span
                    className={classes.noContactsLink}
                    onClick={() => {
                      setAddContactModalOpen(!addContactModalOpen);
                      setAddContactType(ContactTypesEnum.internal);
                    }}
                  >
                    Add an internal contact
                  </span>
                  <span className={classes.noContacts}>.</span>
                </span>
              </>
            ))}
        </div>
        <div className={classes.infoContainer}>
          <span className={classes.infoTitleText}>Distribution Lists</span>
          {data?.distributionListsByBuyerAndSeller.distributionLists.length === 0 && (
            <>
              <EmptyStateIcon className={classes.zeroStateIcon} />
              <span className={classes.zeroStateText}>
                {match.params.buyerSiteName} hasn’t been added to any distribution lists yet. You can create distribution lists in settings and they
                will show up here.
              </span>
              <Button className={classes.infoButton} onClick={handleCreateDistributionList}>
                Create List
              </Button>
            </>
          )}
          {data?.distributionListsByBuyerAndSeller.distributionLists.length > 0 && (
            <>
              <div className={classes.contentContainer}>{renderDistributionLists()}</div>
            </>
          )}
        </div>
      </div>
      <AddContactModal
        open={addContactModalOpen}
        onHide={addContactOnHide}
        distributionLists={addContactDistributionLists || []}
        addContactType={addContactType}
        onChange={addContactOnChange}
        handleSubmit={handleAdd}
        loading={addBSRContactLoading}
        match={match}
        setAddContactDistributionLists={setAddContactDistributionLists}
      />
      <EditContactModal
        open={editContactModalOpen}
        onHide={editContactOnHide}
        distributionLists={editContactDistributionLists || []}
        onChange={editContactOnChange}
        email={editContactEmail}
        selectedContactType={editContactType}
        handleSubmit={handleEdit}
        loading={editBSRContactLoading}
        match={match}
        setDeleteContactModalOpen={setDeleteContactModalOpen}
        setEditContactModalOpen={setEditContactModalOpen}
        distributionListsUpdated={editContactDistributionListsUpdated}
      />
      <DeleteContactModal
        open={deleteContactModalOpen}
        onHide={() => setDeleteContactModalOpen(false)}
        distributionLists={deleteContactDistributionLists || []}
        email={editContactEmail}
        handleSubmit={handleDelete}
        loading={deleteBSRContactLoading}
        selectedContactType={editContactType}
        match={match}
        setEditContactModalOpen={setEditContactModalOpen}
      />
    </>
  );
};

CustomerProfileDetail.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default CustomerProfileDetail;
