import React, { Component } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import moment from 'moment';
import { graphql } from '@apollo/client/react/hoc';
import { Card, Button, DatePicker, TextInput } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { VerifyPost } from '../../graphql/legacy-mutations';

const styles = {
  button__container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  input__row: {
    padding: '5px 0',
  },
};

@injectSheet(styles)
@graphql(VerifyPost)
export default class VerifyAction extends Component {
  state = {
    post: this.props.post,
    claimDate: moment.utc(this.props.post.claimDate || this.props.post.createdAt),
    quantity: this.props.post.quantity,
    uom: this.props.post.unitOfMeasure,
    verifying: false,
    error: false,
  };

  checkQuantity = (quantity) => {
    const error = quantity.trim() === '' || isNaN(quantity) || Number(quantity) < 1 || Number(quantity) > this.state.post.quantity;
    this.setState({ quantity: accounting.unformat(quantity), error });
  };

  setDate = (claimDate) => {
    this.setState({ claimDate: claimDate || moment.utc(this.state.post.claimDate) });
  };

  static getDerivedStateFromProps(nextProps) {
    return { post: nextProps.post };
  }

  error = () => {
    this.setState({ verifying: false });
    this.props.onHide(true);
  };

  hide = () => {
    this.setState({ verifying: false });
    this.props.onHide();
  };

  verify = (ev) => {
    ev.preventDefault();
    const { quantity, claimDate, post } = this.state;
    const { refetchQueries, mutate } = this.props;
    if (quantity > 0 && claimDate !== undefined) {
      this.setState({ verifying: true });
      const variables = { id: post._id, completedAt: claimDate.toDate(), quantity };
      mutate({ variables, refetchQueries }).then(this.hide).catch(this.error);
    }
  };

  get disabled() {
    return this.state.quantity <= 0 || this.state.quantity > this.state.post.quantity || this.state.claimDate === undefined;
  }

  render() {
    return (
      <Card>
        <form onSubmit={this.verify}>
          <h3>Verify the order</h3>
          <div className={this.props.classes.input__row}>
            <DatePicker onChange={this.setDate} label="When did you receive the order?" day={this.state.claimDate} />
          </div>
          <div className={this.props.classes.input__row}>
            <TextInput
              onChange={this.checkQuantity}
              error={this.state.error}
              type="number"
              labelText={`Verify the final count of ${this.state.uom}`}
              value={this.state.quantity.toString()}
              min={1}
              max={this.state.post.quantity}
              required
            />
          </div>
          <p>By clicking “Complete”, you are confirming that the product has been received and accepted by {this.props.user.site.siteName}.</p>
          <div className={this.props.classes.button__container}>
            <Button type="button" link onClick={this.hide} disabled={this.state.verifying}>
              Cancel
            </Button>
            <Button type="submit" primary disabled={this.disabled} loading={this.state.verifying} loadingText="Wait...">
              Complete
            </Button>
          </div>
        </form>
      </Card>
    );
  }
}

VerifyAction.propTypes = {
  classes: PropTypes.object,
  post: PropTypes.object,
  user: PropTypes.object,
  onHide: PropTypes.func,
  mutate: PropTypes.func,
  refetchQueries: PropTypes.array,
};

VerifyAction.defaultProps = {
  onHide: () => {},
};
