import React, { useCallback, useEffect, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { CheckboxWithLabel, DaterangeFilter, OverlayService, Select, SelectOption, Theme } from '@spoiler-alert/ui-library';
import { UserFilters } from '../../store';
import PropTypes from 'prop-types';
import moment from 'moment';

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.176px',
    marginBottom: '10px',
  },
  clearFilters: {
    color: Theme.teal,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.084px',
    cursor: 'pointer',
  },
  filterTitle: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.084px',
  },
  filterRow: {
    marginBottom: '16px',
  },
});

const NegotiationDetailFilters = ({ data, filters, setFilters }) => {
  const classes = useStyles();

  const defaultFilterState = {
    siteName: [],
    createdAt: { type: 'date', start: undefined, end: undefined },
    codeDate: { type: 'date', start: undefined, end: undefined },
    foodType: [],
    handling: [],
    truckType: [],
    brand: [],
    negotiationMessageExists: [],
  };

  const filterOptions = useMemo(() => {
    const siteName = new Map();
    const foodType = new Map();
    const handling = new Map();
    const truckType = new Map();
    const brand = new Map();

    data.forEach((negotiation) => {
      if (!siteName.has(negotiation.location))
        siteName.set(negotiation.siteName, {
          id: `location-${negotiation.siteName}`,
          label: negotiation.siteName,
          value: negotiation.siteName,
        });

      if (!foodType.has(negotiation.foodType))
        foodType.set(negotiation.foodType, {
          id: `category-${negotiation.foodType}`,
          label: negotiation.foodType,
          value: negotiation.foodType,
        });

      if (!handling.has(negotiation.handling))
        handling.set(negotiation.handling, {
          id: `handling-${negotiation.handling}`,
          label: negotiation.handling,
          value: negotiation.handling,
        });

      if (!truckType.has(negotiation.truckType))
        truckType.set(negotiation.truckType, {
          id: `truck-type-${negotiation.truckType}`,
          label: negotiation.truckType,
          value: negotiation.truckType,
        });

      if (!brand.has(negotiation.brand))
        brand.set(negotiation.brand, {
          id: `brand-${negotiation.brand}`,
          label: negotiation.brand,
          value: negotiation.brand,
        });
    });

    return {
      siteName: Array.from(siteName.values()),
      createdAt: { type: 'date', start: undefined, end: undefined },
      codeDate: { type: 'date', start: undefined, end: undefined },
      foodType: Array.from(foodType.values()),
      handling: Array.from(handling.values()),
      truckType: Array.from(truckType.values()),
      brand: Array.from(brand.values()),
      negotiationMessageExists: [
        { id: 'negotiation-message-1', label: 'Has a Negotiation Message', value: true },
        { id: 'negotiation-message-2', label: 'Does not have a Negotiation Message', value: false },
      ],
    };
  }, [data]);

  useEffect(() => {
    OverlayService.show();
    return () => OverlayService.hide();
  }, []);

  useEffect(() => {
    UserFilters.updateFilters('negotiationDetails', filters);
  }, [filters]);

  const handleFilterChange = useCallback(
    (prop, value) => {
      setFilters({
        ...filters,
        [prop]: value,
      });
    },
    [filters, setFilters]
  );

  const removeItem = (array, item) => {
    const index = array.indexOf(item);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  };

  const handleCheckFilterChange = useCallback(
    (prop, event) =>
      handleFilterChange(prop, event.checked ? [...filters[prop], event.value] : removeItem(filters[prop], event.value), [handleFilterChange]),
    [filters, handleFilterChange]
  );

  const handleDateFilterChange = useCallback(
    (prop, startDate, endDate) => {
      setFilters({
        ...filters,
        [prop]: { type: 'date', start: moment.utc(startDate), end: moment.utc(endDate) },
      });
    },
    [filters, setFilters]
  );

  const clearFilters = () => {
    setFilters(defaultFilterState);
  };

  return (
    <div>
      <div className={classes.row}>
        <h1 className={classes.header}>Filters</h1>
        <span onClick={clearFilters} className={classes.clearFilters}>
          Clear Filters
        </span>
      </div>
      <div>
        <div className={classes.filterRow}>
          <span className={classes.filterTitle}>Created Date Range</span>
          <DaterangeFilter
            key="createdDateRange"
            onChange={handleDateFilterChange.bind(this, 'createdAt')}
            position="topright"
            label="Created Date Range"
          />
        </div>

        <div className={classes.filterRow}>
          <span className={classes.filterTitle}>Location</span>
          <Select
            key="locationFilter"
            label="Location"
            multiple
            search
            onChange={handleFilterChange.bind(this, 'siteName')}
            selectedItems={filters.siteName}
          >
            {filterOptions.siteName.map((location) => (
              <SelectOption checkbox key={location.id} value={location.value}>
                {location.label}
              </SelectOption>
            ))}
          </Select>
        </div>

        <div className={classes.filterRow}>
          <span className={classes.filterTitle}>Code Date Range</span>
          <DaterangeFilter key="codeDateRange" onChange={handleDateFilterChange.bind(this, 'codeDate')} position="topright" label="Code Date Range" />
        </div>

        <div className={classes.filterRow}>
          <span className={classes.filterTitle}>Category</span>
          <Select
            key="categoryFilter"
            label="Category"
            multiple
            search
            onChange={handleFilterChange.bind(this, 'foodType')}
            selectedItems={filters.foodType}
          >
            {filterOptions.foodType.map((category) => (
              <SelectOption checkbox key={category.id} value={category.value}>
                {category.label}
              </SelectOption>
            ))}
          </Select>
        </div>

        <div className={classes.filterRow}>
          <span className={classes.filterTitle}>Handling</span>
          {filterOptions.handling.map((handle) => (
            <CheckboxWithLabel
              id={handle.id}
              label={handle.label}
              key={handle.id}
              value={handle.value}
              checked={filters.handling.includes(handle.value)}
              onChecked={handleCheckFilterChange.bind(this, 'handling')}
            />
          ))}
        </div>

        <div className={classes.filterRow}>
          <span className={classes.filterTitle}>Truck Type</span>
          {filterOptions.truckType.map((truckType) => (
            <CheckboxWithLabel
              id={truckType.id}
              label={truckType.label}
              key={truckType.id}
              value={truckType.value}
              checked={filters.truckType.includes(truckType.value)}
              onChecked={handleCheckFilterChange.bind(this, 'truckType')}
            />
          ))}
        </div>

        <div className={classes.filterRow}>
          <span className={classes.filterTitle}>Brand</span>
          {filterOptions.brand.map((brand) => (
            <CheckboxWithLabel
              id={brand.id}
              label={brand.label}
              key={brand.id}
              value={brand.value}
              checked={filters.brand.includes(brand.value)}
              onChecked={handleCheckFilterChange.bind(this, 'brand')}
            />
          ))}
        </div>

        <div className={classes.filterRow}>
          <span className={classes.filterTitle}>Negotiation Message</span>
          {filterOptions.negotiationMessageExists.map((negotiationMessage) => (
            <CheckboxWithLabel
              id={negotiationMessage.id}
              label={negotiationMessage.label}
              key={negotiationMessage.id}
              value={negotiationMessage.value}
              checked={filters.negotiationMessageExists.includes(negotiationMessage.value)}
              onChecked={handleCheckFilterChange.bind(this, 'negotiationMessageExists')}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

NegotiationDetailFilters.propTypes = {
  data: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export { NegotiationDetailFilters };
