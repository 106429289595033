import React, { useState } from 'react';
import { SuggestionsAwardedIcon, Button } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import { NegotiationSettingsModal } from '../../components/modals';
import { useLazyQuery } from '@apollo/client';
import { SuggestedNegotiationsQuery } from '../../graphql/queries';
import PropTypes from 'prop-types';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  title: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.176px',
    marginBottom: '12px',
  },
  list: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.084px',
    marginLeft: '8px',
    '& ul': {
      listStyleType: 'disc',
      textAlign: 'left',
      marginLeft: '20px',
    },
  },
  settings_button: {
    marginTop: '24px',
    margin: 'auto',
  },
});

const SuggestedNegotiationsEmptyState = ({ history }) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [fetchNegotiations] = useLazyQuery(SuggestedNegotiationsQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return (
    <div className={classes.container}>
      <SuggestionsAwardedIcon />
      <div>
        <div className={classes.title}>There are no negotiation suggestions. Check back soon!</div>
        <div className={classes.list}>
          There are 3 possible reasons there are no suggestions:
          <ul>
            <li>You already staged all suggestions, or</li>
            <li>None of your customers have placed bids yet, or</li>
            <li>No offers meet our criteria for suggestion</li>
          </ul>
        </div>
        <div>
          <Button className={classes.settings_button} onClick={() => setModalOpen(true)}>
            Negotiation Settings
          </Button>
        </div>
      </div>
      <NegotiationSettingsModal
        history={history}
        onCancel={() => setModalOpen(false)}
        modalOpen={modalOpen}
        onSave={() => {
          setModalOpen(false);
          fetchNegotiations();
        }}
      />
    </div>
  );
};

SuggestedNegotiationsEmptyState.propTypes = {
  history: PropTypes.object,
};

export default SuggestedNegotiationsEmptyState;
