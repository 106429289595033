import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Navigation, NavigationItem, NavigationSeparator, Sidebar, Logo, Theme as theme } from '@spoiler-alert/ui-library';
import { inject, observer } from 'mobx-react';
import VerificationsItem from './verifications-item';
import routePaths from '../../route-paths';
import Actions from './actions';
import featureFlags from '../../enums/feature-flags';
import AppSettings from '../../app-settings';

const useStyles = createUseStyles(
  {
    saLogo: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: 152,
      height: 33,
      margin: '29px 0 24px 24px',
      padding: 0,
    },
    saImg: {
      width: 152,
      height: 33,
    },
    '@media (max-width: 840px)': {
      saLogo: {
        display: 'none',
      },
    },
  },
  { name: 'SidebarWrapper' }
);

const SidebarWrapper = ({ user, onClose, onOpen, rootStore, children }) => {
  const classes = useStyles();
  const privileges = user.privileges;

  const checkFeature = (featureName) => {
    const { featureMap } = rootStore;
    return !!featureMap.get(featureName) === true;
  };

  const hasLegacyDashboardFeature = checkFeature(featureFlags.legacyDashboard);
  const hasCustomerProfilesFeature = checkFeature(featureFlags.customerProfiles);
  const hasSuggestedNegotiationsFeature = checkFeature(featureFlags.suggestedNegotiations);
  const isSeller = user.site.siteRole.includes('SELLER');
  const envSidebarEnabled = checkFeature(featureFlags.showNonProdSidebar);

  const navItems = useMemo(() => {
    const items = [];
    if (privileges.canViewEnterpriseDashboard && hasLegacyDashboardFeature) {
      items.push(
        <NavigationItem defaultText="Dashboard" id="dashboard" i18nKey="dashboard" route={routePaths.enterpriseDashboard} icon="dashboard" />
      );
    }
    if (!hasLegacyDashboardFeature && isSeller) {
      items.push(
        <NavigationItem
          defaultText="Dashboard"
          id="platformDashboard"
          i18nKey="platformDashboard"
          route={routePaths.platformDashboard}
          icon="dashboard"
          key="platformDashboard"
        />
      );
    }
    if (privileges.canViewItems)
      items.push(<NavigationItem defaultText="Items" id="item-catalog" i18nKey="items" route={routePaths.items} icon="items" key="item-catalog" />);
    if (privileges.canViewInventory) {
      items.push(
        <NavigationSeparator key={'list-separator'}>List</NavigationSeparator>,
        <NavigationItem defaultText="Inventory" id="inventory" i18nKey="box" route={routePaths.inventory} icon="box" key="inventory" />,
        <NavigationItem defaultText="Staging" id="staging" i18nKey="content" route={routePaths.staging} icon="content" key="staging" />
      );
    }
    if (privileges.canReceiveOffers) {
      items.push(<NavigationSeparator key={'negotiation-separator'}>Negotiate</NavigationSeparator>);

      if (hasSuggestedNegotiationsFeature) {
        items.push(
          <NavigationItem
            defaultText="Suggestions"
            id="suggested-negotiations"
            i18nKey="suggested-negotiations"
            route={routePaths.suggestedNegotiations}
            icon="suggestednegotiations"
            key="suggested-negotiations"
          />
        );
      }

      items.push(
        <NavigationItem
          defaultText="Publish Negotiations"
          id="negotiations"
          i18nKey="negotiations"
          route={routePaths.negotiations}
          icon="negotiate"
          key="negotiations"
        />,
        <NavigationSeparator key={'award-separator'}>Award</NavigationSeparator>,
        <NavigationItem
          defaultText="Offer Comparison"
          id="offer-comp"
          i18nKey="offer-comp"
          route={routePaths.offerComparison}
          parentRoute={routePaths.offerComparison}
          icon="historydetail"
          key="offer-comp"
        />,
        <NavigationItem defaultText="Offer Review" id="offers" i18nKey="offers" route={routePaths.review} icon="marketplace" key="offers" />,
        <NavigationSeparator key={'misc-separator'} />
      );
    }
    if (privileges.canViewInventory) {
      items.push(<NavigationItem defaultText="History" id="history" i18nKey="history" route={routePaths.history} icon="receipt" key="history" />);
    }
    if (privileges.canReconcile) {
      items.push(
        <NavigationItem
          defaultText="Transactions"
          id="transactions"
          i18nKey="transactions"
          route={routePaths.transactions}
          icon="adjustsuggestedprice"
          key="transactions"
        />
      );
    }
    if (hasCustomerProfilesFeature)
      items.push(
        <NavigationItem
          defaultText="Customer Profiles"
          id="customerProfiles"
          i18nKey="customerProfiles"
          route={routePaths.customers}
          icon="customerProfile"
          iconStyle={{
            stroke: theme.menuIconFillColor,
          }}
          activeIconStyle={{
            stroke: theme.green,
          }}
          key="customerProfiles"
        />
      );
    if (privileges.canViewPosts) {
      items.push(<NavigationItem defaultText="Posts" id="my-posts" i18nKey="posts" route={routePaths.myPosts} icon="activity" key="my-posts" />);
    }
    if (privileges.canViewClaims) {
      items.push(
        <NavigationItem defaultText="Claims" id="my-claims" i18nKey="claims" route={routePaths.myClaims} icon="boxhandle" key="my-claims" />,
        <VerificationsItem
          defaultText="Verifications"
          id="my-verifications"
          i18nKey="verifications"
          route={routePaths.myVerifications}
          icon="circlecheck"
          key={'my-verifications'}
        />
      );
    }
    if (privileges.canViewTaxDashboard) {
      items.push(
        <NavigationItem defaultText="Tax" id="tax-dashboard" i18nKey="tax" route={routePaths.taxDashboard} icon="tax" key={'tax-dashboard'} />
      );
    }
    if (!hasLegacyDashboardFeature && isSeller) {
      items.push(
        <NavigationItem
          defaultText="Reports"
          id="reportsDashboard"
          i18nKey="reportsDashboard"
          route={routePaths.reportsDashboard}
          icon="reporting"
          iconStyle={{ margin: '0 -3px 0 3px' }}
          key={'reportsDashboard'}
        />
      );
    }
    return items;
  }, [privileges, hasLegacyDashboardFeature, hasCustomerProfilesFeature, isSeller, hasSuggestedNegotiationsFeature]);

  return (
    <Sidebar
      collapsed={rootStore.sidebarStatus.collapsed}
      onClose={onClose}
      onOpen={onOpen}
      backgroundColor={
        (envSidebarEnabled && AppSettings.ENVIRONMENT_NAME === 'staging' && theme.teal30) ||
        undefined ||
        (envSidebarEnabled && AppSettings.ENVIRONMENT_NAME === 'uat' && theme.yellow30) ||
        undefined
      }
    >
      <Logo route="/" className={classes.saLogo} imageClassName={classes.saImg} />
      <Navigation>{navItems}</Navigation>
      <Actions>{children}</Actions>
    </Sidebar>
  );
};

SidebarWrapper.propTypes = {
  user: PropTypes.object,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  rootStore: PropTypes.object,
  children: PropTypes.node,
};

const ConnectedComponent = inject('rootStore')(observer(SidebarWrapper));

export default ConnectedComponent;
