import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Modal, EmptyStateIcon, usePolling, AlertService, OverlayService, Flyout, Theme, Button, MetricCard } from '@spoiler-alert/ui-library';
import { useMutation, useQuery } from '@apollo/client';
import { TitleService } from '../../services';
import { Breadcrumbs, Store } from '../../store';
import SmartAwardSettings from '../org-settings/smart-award-settings';
import BuyersFlyup from '../offer-comparison/buyers-flyup';
import { listedAndAcceptedInventoryQuery, trucklaneInitializePollingQuery, trucklanePollingQuery, trucklaneViewQuery } from '../../graphql/queries';
import { initialMetrics, generateMetrics } from './trucklane-utility';
import RecalculationBanner from './recalculation-banner';
import StaticLoading from '../static-loading';
import { offerlistingStatuses } from '../../enums';
import routePaths from '../../route-paths';
import TrucklaneDrilldown from './trucklane-drilldown';
import TrucklaneFilter from './trucklane-filter';
import client from '../../apollo/client';
import { ForceRecalculation } from '../../graphql/mutations';

const styles = {
  container: {
    minHeight: 'calc(100vh - 114px)',
  },
  spacing: {
    paddingBottom: 36,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  marginRow: {
    extend: 'row',
    marginBottom: 24,
  },
  stickyRow: {
    extend: 'row',
    position: 'sticky',
    zIndex: 9,
    top: '45px',
    padding: '24px 0px',
    backgroundColor: Theme.white,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: '24px',
  },
  headerTitle: {
    fontSize: '16px',
    fontWeight: '500',
  },
  settingsWrapper: {
    width: '100%',
  },
  footer: {
    top: '1vh',
  },
  search: {
    width: 500,
    marginRight: 10,
  },
  filterFlyout: {
    extend: 'marginRow',
    '& h4': {
      margin: 0,
      marginRight: 'auto',
      fontSize: '1rem',
      fontWeight: 500,
    },
    '& a': {
      color: Theme.linkTextColor,
      cursor: 'pointer',
      fontSize: '0.875rem',
    },
  },
  filterHeader: {
    marginBottom: 24,
    '& h2': {
      fontSize: '14px',
      fontWeight: 500,
      marginBottom: 0,
    },
  },
  loader: {
    margin: 'calc(50vh - 118px) auto',
  },
  drilldownContainer: {
    height: '100%',
  },
  drilldown: {
    width: '90%',
  },
  drilldownContents: {
    padding: '0px',
  },
};
const useStyles = createUseStyles(styles);
let trucklaneId = null;

const Trucklane = ({ user, history }) => {
  let channels = [];
  const classes = useStyles();
  const [recalculation, setRecalculation] = useState(false);
  const [displaySettingsModal, setDisplaySettingsModal] = useState(false);
  const [smartAwardSettingUpdate, setSmartAwardSettingUpdate] = useState(false);
  const [drilldownOffers, setDrilldownOffers] = useState([]);
  const [originalTrucklanes, setOriginalTrucklanes] = useState([]);
  const [trucklaneGroups, setTrucklaneGroups] = useState([]);
  const [channelError, setChannelError] = useState(false);
  const [showDrilldown, setShowDrilldown] = useState(false);
  const [pollingData, setPollingData] = useState({ poll: false, interval: 0, initialLoad: true });
  const [forceRecalculation, { loading: forcedRecalcLoading }] = useMutation(ForceRecalculation);
  const { data: initPollingData, loading: initPollingLoad } = useQuery(trucklaneInitializePollingQuery);
  const {
    data: trucklaneData,
    loading: trucklaneDataLoad,
    fetchMore: getTrucklaneData,
  } = useQuery(trucklaneViewQuery, {
    skip: pollingData.initialLoad,
    notifyOnNetworkStatusChange: true,
    variables: {
      getOutOfSyncChannels: false,
    },
  });
  const { data: trucklaneInventoryData, loading: trucklaneInventoryDataLoad } = useQuery(listedAndAcceptedInventoryQuery, {
    skip: pollingData.initialLoad,
  });
  const pollResponse = usePolling({
    idleInterval: pollingData.interval,
    poll: pollingData.poll,
    query: trucklanePollingQuery,
    responseKey: 'trucklanePollingQuery',
    parameters: { pollingDetails: trucklaneGroups },
    skip: !pollingData.poll,
    fetchPolicy: 'network-only',
  });
  const loading =
    initPollingLoad || trucklaneDataLoad || trucklaneInventoryDataLoad || pollingData.initialLoad || smartAwardSettingUpdate || forcedRecalcLoading;

  const clearCache = () => {
    client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'trucklanePollingQuery' });
    client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'trucklaneInitializePollingQuery' });
    client.cache.gc();
    Store.clearValidTrucklaneChannels();
  };

  const refreshTrucklaneData = () => {
    clearCache();
    setRecalculation(false);
    setPollingData({ poll: true, interval: 500, initialLoad: true });
    Store.clearTrucklaneChannels();
    getTrucklaneData({ variables: { getOutOfSyncChannels: true } });
  };

  const enableRecalculation = () => {
    let recalcFlag = false;
    channels.forEach((channel) => {
      if (channel.status !== 'Valid') {
        Store.invalidateTrucklaneChannel(channel);
        recalcFlag = true;
      }
    });
    if (recalcFlag) {
      if (!smartAwardSettingUpdate) setRecalculation(true);
      [...Store.invalidTrucklaneChannels.values()].forEach((trucklane) => {
        if (Store.invalidTrucklaneChannels.get(`${trucklane.truckType}-${trucklane.originDCId}`)) {
          originalTrucklanes
            .filter((lane) => `${lane.truckType}-${lane.origin}` === `${trucklane.truckType}-${trucklane.originDCId}`)
            .forEach((foundLanes) => {
              client.cache.modify({
                id: client.cache.identify({ id: foundLanes.id, __typename: 'Trucklane' }),
                fields: {
                  status() {
                    return 'Invalid';
                  },
                },
              });
            });
        }
      });
    }
  };

  const metrics = useMemo(() => {
    if (originalTrucklanes)
      return generateMetrics(originalTrucklanes, metrics || initialMetrics, Store.invalidTrucklaneChannels.size || recalculation);
    return initialMetrics;
  }, [recalculation, originalTrucklanes]);

  useEffect(() => {
    TitleService.setTitles('Truck Lanes');
    Breadcrumbs.set([
      {
        url: '',
        title: 'Offer Comparison',
      },
    ]);
    if (Store.invalidTrucklaneChannels.size) setRecalculation(true);
    return clearCache();
  }, []);

  useEffect(() => {
    trucklaneId = null;
    let trucklanePath = location.pathname.split('/');
    if (trucklanePath[trucklanePath.length - 1] !== 'trucklane') {
      trucklaneId = trucklanePath[trucklanePath.length - 1];
    }

    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        trucklanePath = location.pathname.split('/');
        if (trucklanePath[trucklanePath.length - 1] !== 'trucklane') {
          trucklaneId = trucklanePath[trucklanePath.length - 1];
          OverlayService.show();
          setShowDrilldown(true);
        }
      }

      if ((action === 'POP' || action === 'PUSH') && location.pathname === `${routePaths.offerComparison}/trucklane`) {
        OverlayService.hide();
        setShowDrilldown(false);
        // required since flyout has a timeout before closing...
        window.setTimeout(() => {
          trucklaneId = null;
        }, 500);
      }
    });

    return () => {
      if (history.action === 'POP' && location.pathname !== `${routePaths.offerComparison}/trucklane/${trucklaneId}`) OverlayService.hide();
      unlisten();
    };
  }, []);

  useEffect(() => {
    if (initPollingData?.trucklaneInitializePollingQuery?.errors.length) {
      AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{initPollingData?.trucklaneInitializePollingQuery?.errors[0]}</span> });
    }
    if (!initPollingData?.trucklaneInitializePollingQuery?.trucklaneChannels.length) {
      setPollingData({ poll: false, interval: 5000, initialLoad: false });
    }
    if (initPollingData?.trucklaneInitializePollingQuery?.trucklaneChannels.length && !channels.length) {
      channels = initPollingData?.trucklaneInitializePollingQuery?.trucklaneChannels;
      setTrucklaneGroups(channels.map((channel) => ({ origin: channel.originDCId, truckType: channel.truckType })));
    }
    if (pollResponse?.trucklaneChannels) {
      channels = JSON.parse(JSON.stringify(pollResponse?.trucklaneChannels));
      channels.forEach((channel) => {
        if (Store.trucklaneChannels.has(`${channel.truckType}-${channel.originDCId}`)) {
          const foundChannel = Store.trucklaneChannels.get(`${channel.truckType}-${channel.originDCId}`);
          if (foundChannel.updatedAt !== channel.updatedAt && channel.status === 'Valid') channel.status = 'Invalid';
        }
      });
    }
    if (!smartAwardSettingUpdate && channels.length && channels.every((channel) => channel.status === 'Valid')) {
      setChannelError(false);
      setPollingData({ poll: true, interval: 5000, initialLoad: false });
      if (!Store.trucklaneChannels.size) Store.setTrucklaneChannels(channels);
    } else if (channels.length && channels.some((channel) => channel.status === 'Errored')) {
      setChannelError(true);
      history.push(`${routePaths.offerComparison}/trucklane`);
      OverlayService.hide();
      setShowDrilldown(false);
      if (!channelError)
        AlertService.alert({
          type: 'warning',
          message: <span>There is an error with the Smart Awarding algorithm. Please reach out to support.</span>,
          autoDismiss: true,
        });
    } else if (loading && !smartAwardSettingUpdate && channels.length) {
      setPollingData({ poll: true, interval: 500, initialLoad: true });
    } else if (smartAwardSettingUpdate && pollResponse?.trucklaneChannels.every((channel) => channel.status === 'Valid')) {
      if (!Store.invalidTrucklaneChannels.size) return;
      setSmartAwardSettingUpdate(false);
      refreshTrucklaneData();
    } else {
      enableRecalculation();
    }
  }, [initPollingData, pollResponse]);

  useEffect(() => {
    if (trucklaneData?.trucklaneViewQuery?.errors?.length && !trucklaneDataLoad && !trucklaneInventoryDataLoad) {
      AlertService.alert({ type: 'warning', message: <span>{trucklaneData?.trucklaneViewQuery?.errors[0].message}</span>, autoDismiss: true });
      if (trucklaneData?.trucklaneViewQuery?.errors[0]?.code === 'NO_OUT_OF_SYNC_CHANNELS_FOUND') {
        setRecalculation(true);
        Store.invalidTrucklaneChannels.set(Store.trucklaneChannels);
      }
    }
    let trucklanes = [];
    const inventories = trucklaneInventoryData?.listedAndAcceptedInventoryQuery;
    if (trucklaneData?.trucklaneViewQuery?.trucklanes?.length && trucklaneInventoryData?.listedAndAcceptedInventoryQuery) {
      trucklanes = JSON.parse(JSON.stringify(trucklaneData?.trucklaneViewQuery?.trucklanes));
      const invMap = inventories.reduce((acc, i) => {
        acc.set(i._id, i);
        return acc;
      }, new Map());
      trucklanes.forEach((trucklane) => {
        let activeFlag = false;
        trucklane.offers.forEach((offer) => {
          if (invMap.has(offer.inventoryId)) offer.inventory = invMap.get(offer.inventoryId);
          if ([offerlistingStatuses.ACTIVE, offerlistingStatuses.IGNORED].includes(offer.status) && offer.suggestions.award.suggested)
            activeFlag = true;
        });
        if (trucklane.hasSuggestions && !activeFlag) trucklane.hasSuggestions = false;
      });
    }
    setOriginalTrucklanes(trucklanes);
    if (trucklaneId && trucklaneData?.trucklaneViewQuery?.trucklanes?.length && trucklaneInventoryData?.listedAndAcceptedInventoryQuery) {
      setDrilldownOffers(trucklanes.find((lane) => lane.id === trucklaneId)?.offers);
      if (!showDrilldown) {
        OverlayService.show();
        setShowDrilldown(true);
      }
    }
  }, [trucklaneData, trucklaneInventoryData]);

  useEffect(() => {
    if (Store.invalidTrucklaneChannels.size && !smartAwardSettingUpdate) setRecalculation(true);
  }, [originalTrucklanes]);

  const recoverErrorState = () => {
    forceRecalculation().then(() => {
      clearCache();
      Store.clearTrucklaneChannels();
      setChannelError(false);
      setRecalculation(false);
      setPollingData({ poll: true, interval: 500, initialLoad: true });
    });
  };

  const openDrilldown = (drilldownTrucklaneId) => {
    if (!showDrilldown) {
      setDrilldownOffers(originalTrucklanes.find((lane) => lane.id === drilldownTrucklaneId)?.offers);
      history.push(`${routePaths.offerComparison}/trucklane/${drilldownTrucklaneId}`);
      OverlayService.show();
      setShowDrilldown(true);
      trucklaneId = drilldownTrucklaneId;
    }
  };

  const closeDrilldown = () => {
    if (showDrilldown) {
      history.push(`${routePaths.offerComparison}/trucklane`);
      OverlayService.hide();
      setShowDrilldown(false);
      // required since flyout has a timeout before closing...
      window.setTimeout(() => {
        trucklaneId = null;
      }, 500);
    }
  };

  const needsRecalculation = () => {
    const foundLane = originalTrucklanes.find((lane) => lane.id === trucklaneId);
    if (foundLane) {
      return !!Store.invalidTrucklaneChannels.get(`${foundLane.truckType}-${foundLane.origin}`);
    }
    return false;
  };

  const noData =
    (trucklaneData?.trucklaneViewQuery?.errors?.length && !trucklaneDataLoad && !trucklaneInventoryDataLoad) || originalTrucklanes.length === 0;

  return (
    <div>
      {loading && !channelError && <StaticLoading classes={{ container: classes.loader }} />}
      {noData && !channelError && (
        <div className={`${classes.column} ${classes.container}`}>
          <EmptyStateIcon />
          <h2 className={classes.headerTitle}>No offers have been received yet, check back later</h2>
        </div>
      )}
      {channelError && (
        <div className={`${classes.column} ${classes.container}`}>
          <EmptyStateIcon />
          <h2 className={classes.headerTitle}>
            There is an error with the Smart Awarding algorithm, please recalculate to view truck lanes. If the error persists please reach out to
            support.
          </h2>
          <Button loading={forcedRecalcLoading} loadingText="Recalculating" onClick={recoverErrorState}>
            Recalculate
          </Button>
        </div>
      )}
      {!loading && !noData && !channelError && (
        <div className={classes.container}>
          <div>
            <h2 className={classes.headerTitle}>Current Cycle Performance</h2>
            <div className={classes.row}>
              {metrics.map((metric, index) => (
                <MetricCard key={index} title={metric.title} current={metric.current} projected={metric.projected} />
              ))}
            </div>
          </div>
          <TrucklaneFilter
            user={user}
            originalTrucklanes={originalTrucklanes}
            history={history}
            setDisplaySettingsModal={setDisplaySettingsModal}
            openDrilldown={openDrilldown}
          />
        </div>
      )}
      {recalculation && (
        <RecalculationBanner
          recalculateAction={() => {
            setPollingData({ poll: true, interval: 500, initialLoad: false });
            setSmartAwardSettingUpdate(true);
          }}
        />
      )}
      <BuyersFlyup className={classes.footer} user={user} history={history} />
      <Modal
        onHide={() => {
          setDisplaySettingsModal(false);
        }}
        open={displaySettingsModal}
        closeOnEsc
        closeOnOutsideClick
      >
        <SmartAwardSettings
          user={user}
          cancellable={true}
          onClose={() => {
            setDisplaySettingsModal(false);
          }}
          onSave={() => {
            setDisplaySettingsModal(false);
            setSmartAwardSettingUpdate(true);
          }}
          saveText={'Save and Recalculate'}
          style={{ title: classes.title, wrapper: classes.settingsWrapper }}
          refetchQueries={[
            {
              query: trucklanePollingQuery,
              variables: {
                pollingDetails: [...Store.trucklaneChannels.values()].map((channel) => ({
                  origin: channel.originDCId,
                  truckType: channel.truckType,
                })),
              },
            },
          ]}
        />
      </Modal>
      <Flyout
        position="right"
        classes={{ flyout__container: classes.drilldownContainer, flyout__wrapper: classes.drilldown, flyout__contents: classes.drilldownContents }}
        open={showDrilldown}
        onHide={closeDrilldown}
      >
        <TrucklaneDrilldown
          trucklaneId={trucklaneId}
          offers={drilldownOffers}
          recalculation={needsRecalculation()}
          user={user}
          history={history}
          handleDrilldownRecalculation={refreshTrucklaneData}
          loading={loading}
        />
      </Flyout>
      {showDrilldown ? <div id="hideScroll" style={{ display: 'none' }}></div> : <div id="showScroll" style={{ display: 'none' }}></div>}
    </div>
  );
};

Trucklane.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default Trucklane;
