import React from 'react';
import { ColorBadge, Theme, TruckMovingIcon } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import SuggestionsBanner from './suggestion-banner';

const useStyles = createUseStyles({
  block: {
    display: 'flex',
  },
  header: {
    alignSelf: 'stretch',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 1.44,
    letterSpacing: ' -0.2px',

    marginBottom: 0,
  },
  subHeader: {
    alignSelf: 'stretch',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: 1.5,
    letterSpacing: '-0.18px',

    marginBottom: 12,
  },
  infoText: {
    flexGrow: 1,
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
  },
});

const RecommendedOfferShifts = () => {
  const classes = useStyles();
  const kustomerLink = 'https://spoileralert.kustomer.help/en_us/offer-shifting-faq-rkFCbScsA';
  const bannerText = (
    <div>
      <div>
        <span className={classes.header}>We found opportunities to sell through more inventory!</span>
        <ColorBadge theme={{ color: Theme.tealDark, backgroundColor: Theme.teal10, marginLeft: 12 }}>Trial Access</ColorBadge>
      </div>
      <div className={classes.subHeader}>Sell through more inventory by shifting unawarded offers to similar leftover inventory.</div>
      <div className={classes.block}>
        <TruckMovingIcon style={{ marginRight: '8px' }} />
        <span className={classes.infoText}>All offer shifting opportunities are on active truck lanes.</span>
      </div>
    </div>
  );
  return <SuggestionsBanner learnMoreLink={kustomerLink}>{bannerText}</SuggestionsBanner>;
};

RecommendedOfferShifts.propTypes = {};

export default RecommendedOfferShifts;
