import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { GraphQLDataTable, Filter, ImportIcon, HistoryIcon, Button, MoneySignIcon } from '@spoiler-alert/ui-library';
import { UserItemsQuery, UserQuery } from '../../graphql/queries';
import { TitleService } from '../../services';
import ReservePriceModal from './reserve-price-modal';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import { Breadcrumbs } from '../../store';
import subscriptionTier from '../../enums/subscription-tier';

class ItemCatalog extends Component {
  constructor(props) {
    super(props);
    this.filters = this.createInitialFilters(this.props.user);

    TitleService.setTitles('Items');
    Breadcrumbs.set([
      {
        url: '/items',
        title: 'Items',
      },
    ]);

    this.state = {
      showReservePriceModal: false,
      variables: {
        paginate: {
          pageNumber: 1,
          limit: 30,
          filter: {
            sortBy: { sortProperty: 'unitCost', asc: false },
          },
        },
      },
    };

    const hasItemsModule = props.user.privileges.canUserSiteUseItems;
    if (!hasItemsModule) this.props.history.push('/items/about');
  }

  createInitialFilters(user) {
    const locationFilter = { displayName: 'Location', field: 'locations', queryField: 'sites', selectAll: true };
    const defaultFilters = user.privileges.canUserManageMultipleSites ? [locationFilter] : [];

    const storeFilters = this.props.userFiltersStore.filters.catalog || [];
    return defaultFilters.map((df) => new Filter({ ...df, ...storeFilters.find((sf) => sf?.displayName === df.displayName) }));
  }

  handleHistoryClick = () => {
    this.props.history.push('/items/history');
  };

  handleImportClick = () => {
    this.props.history.push('/items/import/file');
  };

  openReservePriceModal = () => {
    this.setState({ showReservePriceModal: true });
  };

  hideModal = () => {
    this.setState({
      showReservePriceModal: false,
    });
  };

  get refetchQueries() {
    return [
      {
        query: UserItemsQuery,
        variables: this.state.variables,
      },
      { query: UserQuery },
    ];
  }

  get pageActionButtons() {
    const { user } = this.props;
    const saasLiteCustomer = user.site.subscriptionTier === subscriptionTier.saasLite;
    const buttons = [
      <Button key="reservePrice" onClick={this.openReservePriceModal} secondary icon={MoneySignIcon}>
        Set Reserve Price
      </Button>,
      <Button key="history" onClick={this.handleHistoryClick} secondary icon={HistoryIcon}>
        History
      </Button>,
    ];
    if (!saasLiteCustomer) {
      buttons.push(
        <Button key="import" onClick={this.handleImportClick} secondary icon={ImportIcon}>
          Import
        </Button>
      );
    }
    return buttons;
  }

  render() {
    const { user } = this.props;
    const { showReservePriceModal } = this.state;
    return (
      <div id="catalog">
        <GraphQLDataTable
          query={UserItemsQuery}
          queryName="currentUserQuery"
          queryField="items"
          userId={user._id}
          search
          transition
          filterParameterField="itemFilterParameters"
          filterable={this.filters.length ? 'internal' : null}
          columns={getColumnsFromDataTableProfile('Items', user.site.dataTableProfiles)}
          filters={this.filters}
          updateStoredFilters={(tableFilters) => this.props.userFiltersStore.updateFilters('catalog', tableFilters)}
          pagination
          pageActionButtons={this.pageActionButtons}
          perPage={15}
          perPageIncrements={[15, 30, 50, 100]}
        />
        <ReservePriceModal
          open={showReservePriceModal}
          onHide={this.hideModal}
          refetchQueries={this.refetchQueries}
          reserveAnchor={user.site.reserveAnchor}
          reserveNumber={user.site.reserveNumber}
          user={user}
        />
      </div>
    );
  }
}

ItemCatalog.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  classes: PropTypes.object,
  userFiltersStore: PropTypes.object,
};

const ConnectedComponent = inject((store) => store)(observer(ItemCatalog));
export default ConnectedComponent;
