import React from 'react';
import { Theme, MarketingUpwardGraphIcon } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    border: `1px solid ${Theme.borderColor}`,
    borderRadius: '4px',
    justifyContent: 'space-between',
    padding: 16,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    marginBottom: 24,
  },
  block: {
    display: 'flex',
  },
  linkText: {
    width: '202px',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
    '& a': {
      color: Theme.infoColor,
    },
  },
});

const SuggestionsBanner = ({ learnMoreLink, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.block}>
        <MarketingUpwardGraphIcon style={{ marginRight: '24px', height: 'auto' }} />
        {children}
      </div>
      {learnMoreLink && (
        <div>
          <p className={classes.linkText}>
            How does this work?{' '}
            <a href={learnMoreLink} target="_blank" rel="noreferrer">
              Learn more
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

SuggestionsBanner.propTypes = {
  learnMoreLink: PropTypes.string,
  children: PropTypes.node,
};

export default SuggestionsBanner;
