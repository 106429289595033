import React from 'react';
import { createUseStyles } from 'react-jss';
import { Theme as theme, AlertWarningYellowIcon } from '@spoiler-alert/ui-library';
import { PropTypes } from 'mobx-react';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    background: theme.yellow10,
    paddingTop: '8px',
    padding: '8px 16px',
  },
  link: {
    color: theme.infoColor,
    cursor: 'pointer',
  },
  alertWarningYellowIcon: {
    width: 20,
    height: 20,
    margin: '0px 5px',
  },
  bannerInnerText: {
    paddingLeft: '8px',
    fontSize: '14px',
  },
  bannerOuterText: {
    display: 'flex',
  },
});

const UpdatedAwardedOffers = ({ onClick }) => {
  const classes = useStyles();

  const handleClick = () => {
    onClick();
  };

  return (
    <div className={classes.container}>
      <div className={classes.bannerOuterText}>
        <AlertWarningYellowIcon className={classes.alertWarningYellowIcon} />
        <div className={classes.bannerInnerText}>
          Some customer’s updated their offers post-award. These need to be approved or rejected before acceptance.{' '}
          <a className={classes.link} onClick={handleClick}>
            View updated offers
          </a>
        </div>
      </div>
    </div>
  );
};

UpdatedAwardedOffers.propTypes = {
  onClick: PropTypes.func,
};

export default UpdatedAwardedOffers;
