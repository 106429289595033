import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { ModalContent, ModalFooter, Button, MinimalTextTable, Theme, LoadingLine, AlertService } from '@spoiler-alert/ui-library';
import { LossEmailsQuery } from '../../graphql/queries';
import { SendLossEmails } from '../../graphql/mutations';

const useStyles = createUseStyles({
  form__container: {
    width: '100%',
    '@media (max-width: 450px)': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  modalInfo: {
    fontSize: 16,
    '@media (max-width: 450px)': {
      fontSize: 14,
    },
    marginBottom: 24,
  },
  modalHeader: {
    fontSize: 24,
    '@media (max-width: 450px)': {
      fontSize: 18,
    },
  },
  modalInput: {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    marginBottom: 24,
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    gap: 24,
    marginBottom: 24,
  },
  noBuyers: {
    color: Theme.errorColor,
  },
  emailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    maxHeight: 600,
    overflowY: 'auto',
    position: 'relative',
  },
});

const SendLossEmailsEnterpriseModal = ({ onHide, user }) => {
  const classes = useStyles();

  const { data: lossEmailsData, loading } = useQuery(LossEmailsQuery, {
    variables: { storefrontName: user.site.siteName },
    fetchPolicy: 'no-cache',
  });
  const [sendLossEmails, { loading: sending }] = useMutation(SendLossEmails);

  const [checkedRows, setCheckedRows] = useState([]);

  const onSendLossEmailsSubmit = async () => {
    const { data } = await sendLossEmails({
      variables: {
        buyerSiteIds: checkedRows,
      },
    });

    if (data.sendLossEmails.errors.length) {
      AlertService.alert({
        type: 'warning',
        message: (
          <span>
            {'Sorry there was an error sending loss emails. If this problem persists, please contact a Spoiler Alert Administrator to help you.'}
          </span>
        ),
      });
    } else {
      AlertService.alert({
        type: 'success',
        message: <span>{'Loss emails sent successfully'}</span>,
      });
    }
  };

  const submitModal = async (event) => {
    event.preventDefault();
    await onSendLossEmailsSubmit();
    onHide();
  };

  const onCancelClick = () => {
    onHide();
  };

  return (
    <form onSubmit={submitModal} className={classes.form__container}>
      <ModalContent>
        <h2 className={classes.modalHeader}>Send loss emails</h2>
        <div>
          <div className={classes.modalInfo}>
            <span>
              The customers below submitted offers but have not been awarded inventory. Select the customers you want to send a loss email to. The
              notification will be sent to the email that submitted the offer.
            </span>
          </div>
          <div className={classes.emailsContainer}>
            <span>Send emails to</span>
            <MinimalTextTable
              keyHeader="Customer"
              possibleValues={['Email']}
              checkboxes
              checkedRows={checkedRows}
              onChange={(values) => setCheckedRows([...values])}
              data={
                lossEmailsData?.lossEmailsQuery.buyersWithEmails.map((lossEmail) => {
                  return { _id: lossEmail._id, key: lossEmail.buyerName, value: lossEmail.emails, disabled: lossEmail.hasReceivedLossEmail };
                }) || []
              }
            />
            <LoadingLine loading={loading} position="bottom" />
            {lossEmailsData && !lossEmailsData.lossEmailsQuery.buyersWithEmails.length && (
              <span className={classes.noBuyers}>No customers have submitted offers yet.</span>
            )}
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button type="button" onClick={onCancelClick} secondary>
          Cancel
        </Button>
        <Button
          type="submit"
          className={classes.submit}
          primary
          disabled={!lossEmailsData?.lossEmailsQuery?.buyersWithEmails?.length || checkedRows.length === 0}
          loading={sending}
          loadingText="Sending Emails..."
        >
          Send Emails
        </Button>
      </ModalFooter>
    </form>
  );
};

SendLossEmailsEnterpriseModal.propTypes = {
  user: PropTypes.object,
  onHide: PropTypes.func,
  sellerSiteName: PropTypes.string,
};

export default SendLossEmailsEnterpriseModal;
