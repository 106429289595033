import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  RowAction,
  ChangeQuantityIcon,
  ClearListIcon,
  AlertService,
  PublishListIcon,
  AdjustSuggestedPriceIcon,
  ButtonWithDisclosure,
  Column,
  DataTableNaked,
  SelectBox,
  Search,
  Timing,
  Flyout,
  FilterIcon,
  Checkbox,
} from '@spoiler-alert/ui-library';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { inject, observer } from 'mobx-react';
import pluralize from 'pluralize';
import NegotiationTextbox from './negotiation-textbox';
import NegotiationQuantityModal from './negotiation-quantity-modal';
import NegotiationSuggestedPriceModal from './negotiation-suggested-price-modal';
import { NegotiationStagedListingsQuery, StagedNegotiationSummaryQuery, NegotiationListingsQuery } from '../../graphql/queries';
import { clearNegotiationStagedListings, publishNegotiationListings, resetNegotiationStagedListingsByIds } from '../../graphql/mutations';
import deleteCachedFieldsOnUserQuery from '../../apollo/cache-helpers/delete-cached-fields-on-user-query';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import { TitleService } from '../../services';
import routePaths from '../../route-paths';
import { Breadcrumbs, UserFilters } from '../../store';
import styles from './negotiations-detail-styles';
import NegotiationMessage from './negotiation-message';
import featureFlags from '../../enums/feature-flags';
import checkFeature from '../../helpers/check-feature-flag';
import { NegotiationDetailFilters } from './negotiation-detail-filters';
import filterCounter from '../../utilities/filter-counter';
import moment from 'moment';

const NegotiationsDetail = ({ user, match, history }) => {
  const defaultFilterState = {
    siteName: [],
    createdAt: { type: 'date', start: undefined, end: undefined },
    codeDate: { type: 'date', start: undefined, end: undefined },
    foodType: [],
    handling: [],
    truckType: [],
    brand: [],
    negotiationMessageExists: [],
  };
  const variables = { buyerSiteId: match.params.buyerSiteId };
  const useStyles = createUseStyles(styles);
  const classes = useStyles();
  const negotiationContextEnabled = checkFeature(featureFlags.negotiationContext);
  const tableRef = useRef();
  const dataTableRef = useRef();

  const [showNegotiationModal, setShowNegotiationModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [showQuantityChangeModal, setShowQuantityChangeModal] = useState(false);
  const [negotiationDetail, setNegotiationDetail] = useState({});
  const [resetting, setResetting] = useState(false);
  const [actionOnRows, setActionOnRows] = useState([]);
  const [tableDeletedList, setTableDeletedList] = useState([]);
  const [closeOnPromise, setCloseOnPromise] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({ ...defaultFilterState, ...UserFilters.filters.negotiationDetails });
  const [isOverFlowing, setIsOverFlowing] = useState(false);

  const {
    data,
    loading: negotiationStagedLoading,
    error: negotiationStagedError,
  } = useQuery(NegotiationStagedListingsQuery, { variables: variables, fetchPolicy: 'network-only' });
  const {
    data: negotiatedData,
    loading: negotiationLoading,
    error: negotiationError,
  } = useQuery(NegotiationListingsQuery, { variables: variables, fetchPolicy: 'network-only' });
  const [ClearNegotiationStagedListings] = useMutation(clearNegotiationStagedListings);
  const [PublishNegotiationListings, { loading }] = useMutation(publishNegotiationListings);
  const [ResetNegotiationStagedListingsByIds] = useMutation(resetNegotiationStagedListingsByIds);
  const refetchQueries = [
    { query: StagedNegotiationSummaryQuery },
    { query: NegotiationStagedListingsQuery, variables: variables },
    { query: NegotiationListingsQuery, variables: variables },
  ];

  useEffect(() => {
    TitleService.setTitles('Negotiations Detail');
    const negotiations = data && negotiatedData && [...data.NegotiationStagedListingsQuery, ...negotiatedData.NegotiatedListingsQuery];
    const isVendorOfRecord = !!negotiations?.filter((negotiation) => negotiation.offerListing.isVendorOfRecord).length;
    Breadcrumbs.set([
      {
        url: routePaths.negotiations,
        title: 'Negotiations',
      },
      {
        url: match.url,
        title: `${match.params.buyerSiteName}${(isVendorOfRecord && ' (Spoiler Alert Buyer)') || ''}`,
      },
    ]);
  }, [match.url, match.params.buyerSiteName, data, negotiatedData]);

  const addToActionOnRowIds = (rowId, action) => {
    const newRows = [...actionOnRows];
    newRows.push({ rowId, action });
    return newRows;
  };

  const removeFromActionOnRowIds = useCallback(
    (rowId) => {
      return actionOnRows.filter((o) => o.rowId !== rowId);
    },
    [actionOnRows]
  );

  const showSuccess = (message, rowId) => {
    let updatedActionOnRows;
    let updatedTableDeletedList;
    setResetting(false);
    if (rowId) {
      updatedActionOnRows = removeFromActionOnRowIds(rowId);
      updatedTableDeletedList = [...tableDeletedList, rowId];
    }
    if (updatedActionOnRows) setActionOnRows(updatedActionOnRows);
    if (updatedTableDeletedList) setTableDeletedList(updatedTableDeletedList);
    AlertService.alert({
      type: 'success',
      message: <span>{message}</span>,
      autoDismiss: true,
      dismissDelay: 3000,
    });
  };

  const showFailure = useCallback(
    (message, rowId) => {
      let updatedActionOnRows;
      if (rowId) {
        updatedActionOnRows = removeFromActionOnRowIds(rowId);
      }

      setResetting(false);
      setActionOnRows(updatedActionOnRows);
      AlertService.alert({ type: 'warning', message: <span>{message}</span> });
    },
    [removeFromActionOnRowIds]
  );

  const handleClearOne = (row, e) => {
    e.stopPropagation();
    const updatedActionOnRows = addToActionOnRowIds(row._id, 'reset');
    setActionOnRows(updatedActionOnRows);
    ResetNegotiationStagedListingsByIds({
      variables: { negotiationStagedListingIds: [row.negotiationListing._id] },
      refetchQueries,
    })
      .then((response) => {
        const result = response.data.resetNegotiationStagedListingsByIds;
        if (result.negotiationStagedListingsCount === 0 || result.errors.length > 0) throw new Error(result.errors.join(', '));
        showSuccess(
          `You have cleared ${result.negotiationStagedListingsCount} ${pluralize('negotiation', result.negotiationStagedListingsCount)}.`,
          row._id
        );
      })
      .catch(() => showFailure('A problem occurred while clearing negotiations.', row._id));
  };

  const handleChangeQuantity = (row, e) => {
    e.stopPropagation();
    setNegotiationDetail(row);
    setShowQuantityChangeModal(true);
  };

  const rowActions = () => {
    const resettingRows = [];
    const changingRows = [];
    const activeRows = [];
    if (actionOnRows?.length > 0) {
      actionOnRows.forEach((r) => {
        if (r.action === 'reset') {
          resettingRows.push(r.rowId);
        } else {
          changingRows.push(r.rowId);
        }
        activeRows.push(r.rowId);
      });
    }
    return [
      <RowAction
        key={1}
        tooltipText="Clear Negotiation"
        loadingTooltipText="Clearing Negotiation"
        tooltipPosition="top"
        icon={ClearListIcon}
        onClick={(row) => handleClearOne.bind(this, row)}
        warning
        loadingRows={resettingRows}
        disabledRows={activeRows}
      />,
      <RowAction
        key={2}
        tooltipText="Change Quantity"
        loadingTooltipText="Changing Quantity"
        tooltipPosition="top"
        icon={ChangeQuantityIcon}
        onClick={(row) => handleChangeQuantity.bind(this, row)}
        loadingRows={changingRows}
        disabledRows={activeRows}
      />,
    ];
  };

  const hideQuantityModal = () => {
    if (showQuantityChangeModal) {
      setShowQuantityChangeModal(false);
      setNegotiationDetail({
        ...negotiationDetail,
        negotiationListing: {
          suggestedQuantity: null,
          offerDetails: {
            quantity: null,
          },
        },
        onHandQuantity: null,
        unitNetWeight: null,
        unitGrossWeight: null,
      });
    }
  };

  const handleClearAll = (e) => {
    e.stopPropagation();
    setResetting(true);
    ClearNegotiationStagedListings({
      variables: { buyerSiteIds: [match.params.buyerSiteId] },
      update: (cache) => deleteCachedFieldsOnUserQuery(cache, ['negotiationStagedListings', 'negotiationStagedInventoryFilterParameters']),
      refetchQueries,
    })
      .then((response) => {
        const result = response.data.clearNegotiationStagedListings;
        if (result.negotiationStagedListingsCount === 0 || result.errors.length > 0) throw new Error(result.errors.join(', '));
        showSuccess(
          `You have cleared ${result.negotiationStagedListingsCount} ${pluralize('negotiation', result.negotiationStagedListingsCount)} from ${
            match.params.buyerSiteName
          }.`
        );
        history.push(routePaths.negotiations);
      })
      .catch(() => showFailure('A problem occurred while clearing negotiations.'));
  };

  const openNegotiationModal = () => {
    setShowNegotiationModal(true);
  };

  const createMessage = (createdCount) => {
    const message = createdCount > 1 ? `${createdCount} changes were successfully made.` : `${createdCount} change was successfully made.`;
    AlertService.alert({ type: 'success', message: <span>{message}</span>, autoDismiss: true, dismissDelay: 3000 });
  };

  const error = (errorCount, loggedCount) => {
    const message = `Sorry ${errorCount} of ${
      loggedCount + errorCount
    } changes failed. If this problem persists, please contact a Spoiler Alert Administrator to help you.`;
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  };

  const hideModal = (errorCount, loggedCount) => {
    if (errorCount > 0) error(errorCount, loggedCount);
    if (loggedCount > 0 && errorCount === 0) createMessage(loggedCount);
    setShowNegotiationModal(false);
  };

  const onSubmit = (event, customMessage) => {
    event.stopPropagation();
    const promise = PublishNegotiationListings({
      variables: { buyerSiteIds: [match.params.buyerSiteId], customMessage },
      refetchQueries,
    })
      .then((response) => {
        if (response.data?.publishNegotiationListings?.errors?.length > 0) throw new Error(response.errors.join(', '));
        AlertService.alert({ type: 'success', message: <span>Negotiation list successfully published.</span>, autoDismiss: true });
        history.push(routePaths.negotiations);
      })
      .catch(() => {
        AlertService.alert({
          type: 'warning',
          message: (
            <span>
              Sorry there was an error publishing your negotiation. If this problem persists, please contact a Spoiler Alert Administrator to help
              you.
            </span>
          ),
        });
      });
    setCloseOnPromise(promise);
  };

  const allSelected = useMemo(() => {
    return data?.NegotiationStagedListingsQuery?.length > 0 && selected.length === data.NegotiationStagedListingsQuery.length;
  }, [selected, data]);

  const partialSelected = useMemo(() => {
    return data?.NegotiationStagedListingsQuery?.length > 0 && selected.length > 0 && selected.length < data.NegotiationStagedListingsQuery.length;
  }, [selected, data]);

  const selectAll = (event) => {
    if (event.checked) {
      setSelected(data.NegotiationStagedListingsQuery);
    } else {
      setSelected([]);
    }
  };

  const checkRowItem = useCallback(
    (event, row) => {
      if (event.checked) {
        setSelected([...selected, row]);
      } else {
        setSelected(selected.filter((filterRow) => filterRow._id !== row._id));
      }
    },
    [selected, setSelected]
  );

  const checkboxColumn = useMemo(
    () =>
      new Column({
        displayName: '',
        field: '_id',
        formatter: (_, currentRow) => (
          <Checkbox
            id={`${currentRow._id}-select-box`}
            onChecked={(event) => checkRowItem(event, currentRow)}
            checked={selected.some((row) => row._id === currentRow._id)}
          />
        ),
        visible: true,
        sortable: false,
        style: { width: '76px' },
      }),
    [selected, checkRowItem]
  );

  const columns = useMemo(() => {
    const columnOverrides = {
      negotiationListing_suggestedUnitPrice: {
        formatter: (_, row) => <NegotiationTextbox key={row._id} row={row} onFailure={showFailure} refetchQueries={refetchQueries} />,
      },
    };
    if (!negotiationContextEnabled) return [...getColumnsFromDataTableProfile('Negotiations Detail', user.site.dataTableProfiles, columnOverrides)];
    const cols = [...getColumnsFromDataTableProfile('Negotiations Detail', user.site.dataTableProfiles, columnOverrides)];
    if (cols.find((col) => col.field === 'negotiationListing.reason')) return cols;

    const messageCol = new Column({
      field: 'negotiationListing.reason',
      displayName: '',
      visible: true,
      sortable: false,
      formatter: (_, row) => <NegotiationMessage row={row} key={row._id} parentRef={tableRef} />,
    });

    cols.splice(cols.length, 0, messageCol);
    return cols;
  }, [negotiationContextEnabled, showFailure, user.site.dataTableProfiles]);

  const publishedColumns = () => {
    const publishedColumnOverrides = {
      negotiationListing_suggestedUnitPrice: {
        formatter: (_, row) => row.negotiationListing.suggestedUnitPrice,
      },
    };
    const publishedCols = [...getColumnsFromDataTableProfile('Negotiations Detail', user.site.dataTableProfiles, publishedColumnOverrides, true)];
    return publishedCols;
  };

  const filterCount = useMemo(() => filterCounter(filters), [filters]);

  const handleSearch = Timing.debounce((text) => setSearchText(text), 300);

  const toggleFilters = () => setShowFilters(!showFilters);

  const filteredNegotiationData = useMemo(() => {
    const searchRegex = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
    if (data?.NegotiationStagedListingsQuery === undefined) return [];
    return data?.NegotiationStagedListingsQuery.map((d) => {
      const matchesSearch = searchText.trim() !== '' ? searchRegex.test([d.siteName, d.itemName, d.description]) : true;
      const matchesFilters = Object.entries(filters).every(([key, value]) => {
        if (key === 'negotiationMessageExists') {
          return value.length === 0 || value.some((v) => v === !!d.negotiationListing.reason);
        }
        if (Array.isArray(value)) {
          return value.length === 0 || value.some((v) => (v.value || v) === d[key]);
        }
        if (value.type === 'date') {
          return (!value.start && !value.end) || (moment.utc(value.start).isSameOrBefore(d[key]) && moment.utc(value.end).isSameOrAfter(d[key]));
        }
      });
      if (matchesSearch && matchesFilters) return d;
    }).filter((d) => d);
  }, [data, filters, searchText]);

  const checkOverflow = useCallback(() => {
    if (tableRef.current && dataTableRef.current) {
      const windowWidth = tableRef.current.offsetWidth;
      const tableWidth = dataTableRef.current.offsetWidth;

      setIsOverFlowing(tableWidth > windowWidth);
    }
  }, [dataTableRef]);

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [checkOverflow]);

  if (negotiationStagedError) {
    AlertService.alert({ type: 'warning', message: <span>{negotiationStagedError.message}</span> });
  }

  if (negotiationError) {
    AlertService.alert({ type: 'warning', message: <span>{negotiationError.message}</span> });
  }

  const headerRow = (
    <div className={classes.header_row}>
      <div className="customActions">
        <SelectBox id={'select-all-negotiations-checkbox'} className="select_box" partial={partialSelected} all={allSelected} onSelect={selectAll} />
        <Button key="adjust" primary icon={AdjustSuggestedPriceIcon} onClick={openNegotiationModal} disabled={selected.length === 0}>
          Adjust Counter Offer
        </Button>
        <Search className="search_bar" onChange={handleSearch} />
        <Button onClick={toggleFilters} secondary icon={FilterIcon}>
          {filterCount > 0 ? `${filterCount} ${pluralize('Filter', filterCount)}` : 'Filter'}
        </Button>
      </div>
      <div className="bulkActions">
        <Button
          className="clear_all"
          warning
          key="clear"
          loading={resetting}
          icon={ClearListIcon}
          onClick={handleClearAll}
          loadingText="Clearing List"
        >
          Clear All
        </Button>
        <ButtonWithDisclosure
          key="publish"
          buttonText="Publish List"
          loadingText="Publishing"
          icon={PublishListIcon}
          onSubmit={onSubmit}
          closeOnPromise={closeOnPromise}
          loading={loading}
          disabled={loading}
          placeholder="Use this space if you would like to communicate to your customer why you are negotiating."
        />
      </div>
    </div>
  );

  return (
    <div className={classes.negotiations__wrap}>
      <div className={classes.table_header}>Unpublished</div>
      {headerRow}
      <div ref={tableRef} className={classes.staged_table}>
        <DataTableNaked
          data={filteredNegotiationData}
          loading={negotiationStagedLoading}
          columns={[checkboxColumn, ...columns]}
          horizontalScroll={isOverFlowing}
          noDataMessage={'There are no unpublished negotiations'}
          rowActions={rowActions()}
          checkedRows={selected}
          ref={dataTableRef}
        />
      </div>
      <div className={classes.table_header}>Previously Published</div>
      <div className={classes.staged_table}>
        <DataTableNaked
          data={negotiatedData?.NegotiatedListingsQuery || []}
          loading={negotiationLoading}
          columns={publishedColumns()}
          horizontalScroll={true}
          noDataMessage={'There are no published negotiations'}
        />
      </div>
      <NegotiationQuantityModal
        open={showQuantityChangeModal}
        onHide={hideQuantityModal}
        negotiationDetail={negotiationDetail}
        refetchQueries={refetchQueries}
        user={user}
      />
      <NegotiationSuggestedPriceModal
        open={showNegotiationModal}
        onHide={hideModal}
        negotiationStagedListingIds={selected.map((nsl) => nsl.negotiationListing._id)}
        allSelected={allSelected}
        filters={{ buyerSiteIds: [match.params.buyerSiteId] }}
        refetchQueries={refetchQueries}
        user={user}
      />
      <Flyout position="right" open={showFilters} onHide={toggleFilters}>
        <NegotiationDetailFilters data={data?.NegotiationStagedListingsQuery || []} filters={filters} setFilters={setFilters} />
      </Flyout>
    </div>
  );
};

NegotiationsDetail.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
  match: PropTypes.object,
  clearStagedListings: PropTypes.func,
  publishStagedListings: PropTypes.func,
  createStagedListingsFromInventory: PropTypes.func,
  history: PropTypes.object,
};

const ConnectedComponent = inject((store) => store)(observer(NegotiationsDetail));

export default ConnectedComponent;
